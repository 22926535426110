<div
    *ngIf="areas && areas()"
    [ngStyle]="{ width: cameraScale() + '%' }"
    class="flex flex-col gap-2 h-full mx-auto">
    <div class="min-h-3 max-h-5" [ngClass]="areas().id === 'placeholder' ? 'placeholder' : ''">
        <!-- Show SVG -->
        <lib-svjg-area
            (onDoubleClicked)="onDoubleClick($event)"
            [type]="type()"
            [area]="areas()"></lib-svjg-area>
    </div>
    <!-- Recursion -->
    <div
        *ngIf="areas().children && areas().children.length"
        class="w-full h-full flex-1 flex flex-row justify-center"
        [ngClass]="type() === 'system' ? '' : ''">
        <svg-area
            [ngClass]="getClass(area)"
            *ngFor="let area of areas().children"
            (onDoubleClicked)="onDoubleClick($event)"
            [type]="type()"
            [areas]="area"></svg-area>
    </div>
    <!-- Processes -->
    <div class="flex flex-col gap-2" *ngIf="areas().processes?.length">
        <svg-area
            *ngFor="let process of areas().processes"
            (onDoubleClicked)="onDoubleClick($event)"
            class="h-fit"
            [type]="'process'"
            [areas]="process"></svg-area>
    </div>
</div>
