<div class="flex flex-col mx-auto">
    <div *ngIf="flowchartType === 'flowchart'; then flowchart; else flowchart2"></div>
    <ng-template #flowchart>
        <bp-flowchart-horizontal
            *ngIf="viewType() === viewTypeValue().Horizontal"
            #babylon
            [processes]="tasks()"
            [cameraScale]="cameraScale"
            type="flowchart"
            (meshClicked)="onMeshClick($event)"
            (meshDoubleClicked)="onMeshDoubleClick($event)"
            [selectedId]="selectedTaskId"
            [movingType]="movingType"
            [sensitivityType]="sensitivityType"
            [coloredTaskIds]="coloredTaskIds"
            [config]="config"
            [menuItems]="menuItems"></bp-flowchart-horizontal>
        <bp-flowchart-vertical
            *ngIf="viewType() === viewTypeValue().Vertical"
            #babylon
            [processes]="tasks()"
            [cameraScale]="cameraScale"
            type="flowchart"
            (meshClicked)="onMeshClick($event)"
            (meshDoubleClicked)="onMeshDoubleClick($event)"
            [selectedId]="selectedTaskId"
            [movingType]="movingType"
            [sensitivityType]="sensitivityType"
            [coloredTaskIds]="coloredTaskIds"
            [config]="config"
            [menuItems]="menuItems"></bp-flowchart-vertical>
        <lib-svg-flowchart
            *ngIf="viewType() === viewTypeValue().SVG"
            [processes]="tasks"
            [cameraScale]="cameraScale"
            type="flowchart2"
            (meshClicked)="onMeshClick($event)"
            (meshDoubleClicked)="onMeshDoubleClick($event)"
            [selectedId]="selectedTaskId"
            [movingType]="movingType"
            [sensitivityType]="sensitivityType"
            [coloredTaskIds]="coloredTaskIds"
            [config]="config"
            [menuItems]="menuItems"></lib-svg-flowchart>
    </ng-template>
    <ng-template #flowchart2>
        <bp-flowchart-horizontal
            *ngIf="viewType() === viewTypeValue().Horizontal"
            #babylon
            [processes]="tasks()"
            [cameraScale]="cameraScale"
            type="flowchart2"
            (meshClicked)="onMeshClick($event)"
            (meshDoubleClicked)="onMeshDoubleClick($event)"
            [selectedId]="selectedTaskId"
            [movingType]="movingType"
            [sensitivityType]="sensitivityType"
            [coloredTaskIds]="coloredTaskIds"
            [config]="config"
            [menuItems]="menuItems"></bp-flowchart-horizontal>
        <bp-flowchart-vertical
            *ngIf="viewType() === viewTypeValue().Vertical"
            #babylon
            [processes]="tasks()"
            [cameraScale]="cameraScale"
            type="flowchart2"
            (meshClicked)="onMeshClick($event)"
            (meshDoubleClicked)="onMeshDoubleClick($event)"
            [selectedId]="selectedTaskId"
            [movingType]="movingType"
            [sensitivityType]="sensitivityType"
            [coloredTaskIds]="coloredTaskIds"
            [config]="config"
            [menuItems]="menuItems"></bp-flowchart-vertical>
        <lib-svg-flowchart
            *ngIf="viewType() === viewTypeValue().SVG"
            [processes]="tasks"
            [cameraScale]="cameraScale"
            type="flowchart2"
            (meshClicked)="onMeshClick($event)"
            (meshDoubleClicked)="onMeshDoubleClick($event)"
            [selectedId]="selectedTaskId"
            [movingType]="movingType"
            [sensitivityType]="sensitivityType"
            [coloredTaskIds]="coloredTaskIds"
            [config]="config"
            [menuItems]="menuItems"></lib-svg-flowchart>
    </ng-template>
</div>

<div class="resizer">
    <lib-flowchart-resizer
        [minScale]="viewType() === viewTypeValue().SVG ? 50 : 0"
        (updateCameraScale)="setCameraScale($event)"></lib-flowchart-resizer>
</div>
