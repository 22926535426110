import {
    Component,
    ElementRef,
    Injector,
    Input,
    NgZone,
    Signal,
    SimpleChanges,
    ViewChild,
    WritableSignal,
    computed,
    effect,
    signal,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FuseConfigService } from '@fuse/services/config';
import {
    BusinessProcessCategory,
    BusinessProcessTask,
    BusinessProcessTaskDataInterface,
} from 'app/api';
import {
    AbstractMesh,
    ActionManager,
    Curve3,
    Engine,
    ExecuteCodeAction,
    FreeCamera,
    Material,
    Mesh,
    MeshBuilder,
    Scene,
    Tools,
    Vector3,
} from 'babylonjs';
import 'babylonjs-loaders';
import { takeUntil } from 'rxjs';
import { BabylonContextMenuItem } from '../../models/babylon-context-menu-item';
import { BabylonFlowchart } from './babylon-flowchart';
import { MeshDetails } from './babylon-mesh-details.model';
import { PrintService } from '../../print.service';
import { BlenderMesh, BlenderMeshesByType } from './blender-mesh';
import createDeepCopy from './createDeepCopy';
import { MeshWithTexts } from './mesh-with-texts';
import { Position } from './position.model';
import { FlowchartLoadingSpinnerService } from '../../reusable-components/flowchart/flowchart-container/flowchart-loading-spinner.service';

@Component({
    template: '',
})
export abstract class BabylonBusinessProcessFlowchartComponent extends BabylonFlowchart {
    svgPath?: string;
    printCamera: FreeCamera;
    protected printWidth: number = 2160;
    protected printHeight: number = 3840;

    // Labels
    protected labelOfStarting = 'S';
    protected labelOfEnding = 'E';

    // Properties
    protected rotationNinentyDegrees = Math.PI * this.half;
    protected contextMenuOffsetY = 20;
    protected contextMenuOffsetX = 20;
    protected borderBackgroundPosZ = 0.005;
    protected zero = 0;
    protected typeFlowchartX = 0.25;
    protected quarter = 0.25;
    protected backgroundPositionZ = 1;
    protected backgroundWidthMultiplier = 4;
    protected backgroundHeightMultiplier = 4;
    protected startingPointPos = { x: 0, y: 1.35, z: 0 };
    protected processResolution = 20; //49;
    protected processRoundnessY = 0.1;
    protected processRoundnessX = 0;
    protected borderThickness = 0.02;
    protected textPosZ = 0.04;
    protected decisionOutputWidth = 0.855;
    protected decisionOutputHeight = 0.38;

    // Menu
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
    @Input() menuItems: Signal<BabylonContextMenuItem[]>;
    @Input() coloredTaskIds: Signal<string[]> | undefined;
    lastColoredTaskIds: string[] = [];

    protected selectedItemId: WritableSignal<string> = signal(null);
    availableMenuItems = computed(() => {
        return this.menuItems().filter(
            (item: BabylonContextMenuItem) =>
                item.applyToIds === undefined || item.applyToIds().includes(this.selectedItemId())
        );
    });

    constructor(
        el: ElementRef,
        ngZone: NgZone,
        injector: Injector,
        fuseConfigService: FuseConfigService,
        protected printService: PrintService,
        spinnerService: FlowchartLoadingSpinnerService
    ) {
        super(el, ngZone, injector, fuseConfigService, spinnerService);
        this.subscribeToPrint();
        this.printService.reSubscribe.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.subscribeToPrint();
        });

        effect(() => {
            if (this.coloredTaskIds) {
                const ids = this.coloredTaskIds();
                if (this.isAfterViewInit) {
                    let force = false;
                    if (JSON.stringify(ids) !== JSON.stringify(this.lastColoredTaskIds)) {
                        this.lastColoredTaskIds = ids;
                        force = true;
                    }
                    this.checkAndSetUpScene(force);
                }
            }
        });
    }

    override ngOnChanges(changes: SimpleChanges): void {
        if (!this.coloredTaskIds) {
            super.ngOnChanges(changes);
        } else {
            if (changes['selectedId']?.currentValue && this.scene) {
                this.selectedId = changes['selectedId'].currentValue;

                this.showSelection();
            }
        }
    }

    /** Subscribing for print event */
    private subscribeToPrint(): void {
        this.printService.printScene.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.createScreenshot();
        });
    }

    protected override clean(): void {
        this.originalDecision?.mesh.dispose();
        this.originalDecision = undefined;

        this.originalDecisionOutput?.mesh.dispose();
        this.originalDecisionOutput = undefined;

        this.originalPrimaryProcess?.mesh.dispose();
        this.originalPrimaryProcess = undefined;
        this.originalPrimaryProcess2?.mesh.dispose();
        this.originalPrimaryProcess2 = undefined;
        this.originalPrimaryProcess3?.mesh.dispose();
        this.originalPrimaryProcess3 = undefined;
        this.originalWarnProcess?.dispose();
        this.originalWarnProcess = undefined;

        // Entries
        this.cleanBlenderMeshes(this.originalDocument);
        this.cleanBlenderMeshes(this.originalMDocument);

        this.cleanBlenderMeshes(this.originalFile);
        this.cleanBlenderMeshes(this.originalMFile);

        this.cleanBlenderMeshes(this.originalTable);
        this.cleanBlenderMeshes(this.originalMTable);

        this.selectionBox?.dispose();
        this.selectionBox = undefined;
    }

    private cleanBlenderMeshes(variable: BlenderMeshesByType): void {
        Object.keys(variable).forEach(key => {
            variable[key]?.mesh.dispose();
            variable[key] = undefined;
        });
    }

    // Context Menu

    /**
     * Open the context menu with the available options.
     * @param event
     * @param trigger
     */
    showContextMenu(event: { clientX: number; clientY: number }, trigger?: MatMenuTrigger) {
        let overlayPane = document.getElementById('myMenu');

        // Set the position of the overlayPane to the cursor
        if (overlayPane) {
            overlayPane.style.position = 'absolute';
            overlayPane.style.top = event.clientY + this.contextMenuOffsetY + 'px';
            overlayPane.style.left = event.clientX + this.contextMenuOffsetX + 'px';
        }

        // Open the menu
        trigger.openMenu();
    }

    /**
     * Handle contextMenuItem click by calling the action callback.
     * @param item BabylonContextMenuItem
     */
    handleOption(item: BabylonContextMenuItem): void {
        item.action(item.code, this.selectedItemId());
    }

    // Animations

    protected override setUpAnimation(): void {
        Object.values(this.allMeshes)
            .filter(meshDetails => meshDetails.id !== 'endpoint')
            .forEach((meshDetails: MeshDetails) => {
                const mesh = meshDetails.centralMesh;

                // Add a pointer enter event to set the flag when the cursor is over the mesh
                mesh.actionManager = new ActionManager(this.scene);
                mesh.actionManager.registerAction(
                    new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
                        // this.isCursorOverMesh[mesh.name] = true;
                        meshDetails.isCursorOverMesh = true;
                        mesh.unfreezeWorldMatrix();
                    })
                );

                // Add a pointer out event to reset the flag when the cursor leaves the mesh
                mesh.actionManager.registerAction(
                    new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
                        meshDetails.isCursorOverMesh = false;
                        // TODO timeout
                        // new Promise(resolve => setTimeout(resolve, 100)).then(() => {
                        //     this.resetMeshRotation(mesh);
                        // });
                    })
                );

                if (this.menuItems().length) {
                    mesh.actionManager.registerAction(
                        new ExecuteCodeAction(ActionManager.OnRightPickTrigger, evt => {
                            this.selectedItemId.set(mesh.name);
                            this.showContextMenu(
                                { clientX: evt.pointerX, clientY: evt.pointerY },
                                this.menuTrigger
                            );
                        })
                    );
                }
            });

        // TODO fix animation
        // Add a pointer move event listener to handle continuous rotation
        // this.canvas.nativeElement.addEventListener('mousemove', event => {
        //     Object.values(this.allMeshes)
        //         .filter(meshDetails => meshDetails.id !== 'endpoint')
        //         .forEach((meshDetails: MeshDetails) => {
        //             let mesh = meshDetails.centralMesh;

        //             if (meshDetails.isCursorOverMesh) {
        //                 this.rotateMeshBasedOnCursorPosition(mesh, event);
        //             }
        //         });
        // });
    }

    /** Function to calculate and apply the rotation based on cursor position */
    protected rotateMeshBasedOnCursorPosition(mesh: AbstractMesh, event: MouseEvent) {
        const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
        const cursorX = event.clientX - canvasRect.left;
        const cursorY = event.clientY - canvasRect.top;

        const centerX = canvasRect.width * this.half;
        const centerY = canvasRect.height * this.half;

        // Calculate the desired rotation based on cursor position
        const deltaX = cursorX - centerX;
        const deltaY = cursorY - centerY;

        const rotationX = -(this.maxRotation * (deltaY / centerY));
        const rotationY = -(this.maxRotation * (deltaX / centerX));

        // Apply the rotation to the mesh
        mesh.rotation.x = rotationX;
        mesh.rotation.y = rotationY;
        mesh.position.z = this.animationOnZ;

        this.allMeshes[mesh.name].entries.forEach((entryMesh: MeshWithTexts) => {
            entryMesh.mesh.rotation.x = rotationX;
            entryMesh.mesh.rotation.y = rotationY;
            entryMesh.mesh.position.z = this.animationOnZ;

            entryMesh.texts.rotation.x = rotationX;
            entryMesh.texts.rotation.y = rotationY;
            entryMesh.texts.position.z = this.animationOnZ;
        });
        this.allMeshes[mesh.name].arrowsForEntries.forEach((entryMesh: Mesh) => {
            if (entryMesh.position.z > this.animationOnZ) {
                entryMesh.position.z = this.animationOnZ;
            }
        });
    }

    /** Function to reset the mesh rotation to the default state */
    protected resetMeshRotation(mesh: AbstractMesh) {
        mesh.rotation.x = 0;
        mesh.rotation.y = 0;
        mesh.position.z = this.boxZ;

        this.allMeshes[mesh.name].entries?.forEach((entryMesh: MeshWithTexts) => {
            entryMesh.mesh.rotation.x = 0;
            entryMesh.mesh.rotation.y = 0;
            entryMesh.mesh.position.z = this.boxZ;

            entryMesh.texts.rotation.x = 0;
            entryMesh.texts.rotation.y = 0;
            entryMesh.texts.position.z = this.boxZ;
        });

        this.allMeshes[mesh.name].arrowsForEntries?.forEach((entryMesh: Mesh) => {
            entryMesh.position.z = 0;
        });
    }

    // Print

    /** Capture the screenshot */
    public createScreenshot(): any {
        if (this.checkMark) {
            this.checkMark.visibility = 0;
        }
        let theme = this.theme;
        this.theme = 'light';
        this.setColors();

        const pos = this.calculateCameraPositionForZero();
        const x = this.getCameraX();

        this.printCamera.position.x = x;
        this.printCamera.position.z = pos.zPos;
        this.printCamera.position.y = pos.yPos;
        this.printCamera.setTarget(new Vector3(x, pos.yPos, 0));

        Tools.CreateScreenshotUsingRenderTarget(
            this.scene.getEngine(),
            this.printCamera,
            { width: this.printWidth, height: this.printHeight },
            (data: any) => {
                var img = document.createElement('img');
                img.src = data;
                this.printService.image = img;
                this.printService.printScene.complete();
                if (this.checkMark) {
                    this.checkMark.visibility = 1;
                }
                this.theme = theme;
                this.setColors();
            },
            'image/png',
            8,
            false
        );
    }

    /*********************************************
     * Create Scene
     *********************************************/

    /** Returns the x position of the camera by the type of flowchart */
    protected override getCameraX(): number {
        return this.type === 'flowchart2' ? this.zero : this.zero;
    }

    /** Creates the camera for the user and the printCamera for printing */
    protected override createCamera(scene: Scene): void {
        super.createCamera(scene);
        const cameraX = this.getCameraX();

        // Create camera for print
        const printCameraPos = this.calculateCameraPositionForZero();
        this.printCamera = new FreeCamera(
            'camera',
            new Vector3(cameraX, printCameraPos.yPos, printCameraPos.zPos),
            scene
        );
        this.printCamera.setTarget(new Vector3(0, printCameraPos.yPos, 0));
        this.printCamera.fov = Math.PI * this.quarter;

        // Set scaling
        if (this.cameraScale()) {
            this.setCameraScale();
        }
    }

    /**  Create the background plane */
    protected createBackground(scene: Scene): void {
        const size = this.calculateTotalWidth() * this.backgroundWidthMultiplier;
        let height = this.calculateTotalHeight() * this.backgroundHeightMultiplier;
        if (height < 10) {
            height = 10 * this.backgroundHeightMultiplier;
        }

        this.createBackgroundPlane(size, height, scene);
    }

    private async generateItem(bpTask, scene, y, listOfProcesses, index): Promise<void> {
        if (bpTask.category === BusinessProcessCategory.Process) {
            listOfProcesses[index] = await this.createProcess(
                bpTask.id,
                { x: 0, y, z: 0 },
                bpTask,
                scene
            );
        } else if (bpTask.category === BusinessProcessCategory.IntegrationTask) {
            // TODO IntegrationTask
            listOfProcesses[index] = await this.createProcess(
                bpTask.id,
                { x: 0, y, z: 0 },
                bpTask,
                scene
            );
        } else {
            listOfProcesses[index] = this.createDecision(
                bpTask.id,
                { x: 0, y, z: 0 }, //: y + this.decisionHeight * this.half
                bpTask,
                scene
            );
        }
    }

    /** Generates the processes and decisions with entries */
    protected async generateProcesses(scene: Scene): Promise<void> {
        const listOfProcesses: Mesh[] = this.processes.map(bp => undefined);
        // starting
        listOfProcesses.push(undefined);

        // Starting point
        const entry = this.createEndpoint(this.startingPointPos, this.labelOfStarting, scene);
        listOfProcesses[0] = entry;

        let y = 0;
        let taskStartTime = performance.now();
        const promises = this.processes.map((bpTask, index) =>
            this.generateItem(bpTask, scene, y, listOfProcesses, index + 1)
        );
        await Promise.all(promises);

        // Set alignment and Y position
        for (let index = 0; index < this.processes.length; index++) {
            const bpTask: BusinessProcessTask = this.processes[index];

            // listOfProcesses[index + 1].position.y = y;
            this.allMeshes[bpTask.id].centralMesh.position.y = y;
            if (this.allMeshes[bpTask.id].meshTexts) {
                this.allMeshes[bpTask.id].meshTexts.position.y = y;
            }

            // if (bpTask.category === 'decission') {
            //     y += -0.4;
            // }

            this.moveMeshInDetails(this.allMeshes[this.processes[index].id], 0, -y, 0, true);
            // if (bpTask.category === 'decission') {
            //     y += 0.4;
            // }

            if (listOfProcesses.length > 1) {
                let yOffsetOfA: number;
                if (listOfProcesses.length === 2 || index === 0) {
                    yOffsetOfA = this.entryDiameter - 0.1;
                } else {
                    yOffsetOfA =
                        this.processes[index - 1].category === BusinessProcessCategory.Process ||
                        this.processes[index - 1].category ===
                            BusinessProcessCategory.IntegrationTask
                            ? this.processHeight
                            : this.decisionHeight * this.half;
                }

                let yOffsetOfB: number =
                    bpTask.category === BusinessProcessCategory.Process ||
                    bpTask.category === BusinessProcessCategory.IntegrationTask
                        ? this.processHeight
                        : this.decisionHeight * this.half;

                const connectionArrow = this.createArrow(
                    scene,
                    listOfProcesses[index],
                    listOfProcesses[index + 1],
                    yOffsetOfA,
                    yOffsetOfB
                );

                // const
                if (index > 0) {
                    this.finishMeshDetails(
                        this.processes[index - 1].id,
                        connectionArrow,
                        index,
                        listOfProcesses[index],
                        listOfProcesses[index + 1],
                        yOffsetOfA,
                        yOffsetOfB,
                        this.processes[index - 1].category
                    );
                }
            }

            y += this.calculateNextY(bpTask, index);
        }
        let endTime = performance.now();
        let totalTime = endTime - taskStartTime;
        console.log(`processes generated in ${totalTime} milliseconds`);

        // Ending point
        const endpoint = this.createEndpoint({ x: 0, y, z: 0 }, this.labelOfEnding, scene);

        const yOffsetOfA =
            this.processes[this.processes.length - 1].category ===
                BusinessProcessCategory.Process ||
            this.processes[this.processes.length - 1].category ===
                BusinessProcessCategory.IntegrationTask
                ? this.processHeight
                : this.decisionHeight * this.half;

        const connectionArrow = this.createArrow(
            scene,
            listOfProcesses[listOfProcesses.length - 1],
            endpoint,
            yOffsetOfA,
            this.entryDiameter * this.half
        );

        this.finishMeshDetails(
            this.processes[this.processes.length - 1].id,
            connectionArrow,
            this.processes.length - 1,
            listOfProcesses[listOfProcesses.length - 1],
            endpoint,
            yOffsetOfA,
            this.entryDiameter * this.half,
            this.processes[this.processes.length - 1].category
        );

        // this.allMeshes[this.processes[this.processes.length - 1].id].arrowToNext = connectionArrow;
        // this.allMeshes[this.processes[this.processes.length - 1].id].index =
        //     this.processes.length - 1;
        // TODO
        this.allMeshes['endpoint'] = {
            centralMesh: endpoint,
            arrowsForEntries: [],
            entries: [],
            id: 'endpoint',
            index: Object.keys(this.allMeshes).length,
            isCursorOverMesh: false,
            position: { x: 0, y: y - 0.6, z: 0 },
            arrowToNext: undefined,
            bpTask: {},
            centerPosition: new Vector3(0, y - 0.6, 0),
            totalHeight: 1,
        };
    }

    protected finishMeshDetails(
        id: string,
        connectionArrow: Mesh,
        index: number,
        a: Mesh,
        b: Mesh,
        yOffsetOfA: number,
        yOffsetOfB: number,
        categoryA: BusinessProcessCategory
    ): void {
        // Create Vector3s with the offset
        if (categoryA === BusinessProcessCategory.Decission) {
            yOffsetOfA = yOffsetOfA * 2;
            yOffsetOfB = yOffsetOfB;
        }
        const modifiedA = new Vector3(a.position.x, a.position.y - yOffsetOfA, a.position.z);
        const modifiedB = new Vector3(b.position.x, b.position.y + yOffsetOfB, b.position.z);

        // Calculate the direction and length of the arrow
        const direction = modifiedB.subtract(modifiedA);
        const length = direction.length();

        const position = this.allMeshes[id].position;

        const currentHeight = this.allMeshes[id].totalHeight;
        let y = position.y + (yOffsetOfA * 4) / 3;
        y -= (length + currentHeight) / 2;

        this.allMeshes[id].arrowToNext = connectionArrow;
        this.allMeshes[id].index = index - 1;
        this.allMeshes[id].totalHeight += length;
        this.allMeshes[id].centerPosition = new Vector3(position.x, y, position.z);
    }

    /**
     * Calculate the position on the Y axis of the next item
     * @param bpTask Current BusinessProcessTask
     * @param listOfProcesses List of created Meshes
     * @returns Y position
     */
    protected calculateNextY(bpTask: BusinessProcessTask, index: number): number {
        let y = 0;
        // Calculate the length of the entries
        let entriesLength;
        if (bpTask.data.input?.length || bpTask.data.output?.length) {
            if (bpTask.data.input?.length && bpTask.data.output?.length) {
                entriesLength =
                    bpTask.data.input.length > bpTask.data.output.length
                        ? bpTask.data.input.length
                        : bpTask.data.output.length;
            } else if (bpTask.data.input?.length) {
                entriesLength = bpTask.data.input.length;
            } else {
                entriesLength = bpTask.data.output.length;
            }
        } else {
            entriesLength = 0;
        }

        // Calculate the position of the next item on the Y axis
        // End entry Y point
        if (this.processes.filter(bpTask => bpTask !== undefined).length - 1 <= index) {
            if (
                bpTask.category === BusinessProcessCategory.Process ||
                bpTask.category === BusinessProcessCategory.IntegrationTask
            ) {
                y -= 1.4 + (entriesLength > 1 ? 1 * (entriesLength - 1) : 0);
            } else {
                y -= 1.4 + (entriesLength > 1 ? 1 * (entriesLength - 1) : 0);
            }
        }
        // Everything else
        else {
            if (
                bpTask.category === BusinessProcessCategory.Process ||
                bpTask.category === BusinessProcessCategory.IntegrationTask
            ) {
                y -= (entriesLength > 1 ? 1.2 * (entriesLength - 1) : this.processHeight) + 1.25;
            } else {
                // y -= 0 + (entriesLength > 1 ? 1.5 * entriesLength - 1 : 1.5);
                y -=
                    (entriesLength > 1 ? 1.2 * (entriesLength - 1) : this.decisionHeight) +
                    1.25 +
                    this.decisionHeight / 2;
            }
        }
        return y;
    }

    override async createScene(
        engine: Engine,
        canvas: HTMLCanvasElement,
        reset?: boolean
    ): Promise<Scene> {
        this.startTime = performance.now();
        this.checkMark = undefined;
        // This creates a basic Babylon Scene object (non-mesh)
        var scene = new Scene(engine, {
            useMaterialMeshMap: true,
            useGeometryUniqueIdsMap: true,
        });

        this.createCamera(scene);

        await this.loadFont();

        this.createMaterials();

        this.setColors();

        this.createBackground(scene);

        await this.loadMeshes(scene);

        await this.generateProcesses(scene);

        this.clean();

        return scene;
    }

    protected hasTableConnection(bpTask: BusinessProcessTask): boolean {
        return !!(this.type === 'flowchart' && bpTask.tab);
    }

    protected getColorMaterial(id: string): Material {
        if (this.coloredTaskIds) {
            return this.type === 'flowchart' && this.coloredTaskIds().includes(id)
                ? this.warnBoxMaterial
                : this.primaryBoxMaterial;
        }
        return this.primaryBoxMaterial;
    }

    protected async loadMeshes(scene: Scene): Promise<void> {
        // Endpoints
        const startMesh = await this.loadMesh('sCircle', 'sCircle', scene);
        const endMesh = await this.loadMesh('eCircle', 'sCircle', scene);

        startMesh.mesh.visibility = 0;
        endMesh.mesh.visibility = 0;

        this.startPoint = startMesh;
        this.endPoint = endMesh;

        // Process
        const blenderProcessMesh = await this.loadMesh('process', 'Process', scene);

        // Process.category === 2
        const blenderProcess2Mesh = await this.loadMesh('process_2', 'Process', scene);

        // Process.category === 3
        const blenderProcess3Mesh = await this.loadMesh('process_3', 'Process', scene);

        blenderProcessMesh.mesh.visibility = 0;
        blenderProcess2Mesh.mesh.visibility = 0;
        blenderProcess3Mesh.mesh.visibility = 0;

        this.originalPrimaryProcess = blenderProcessMesh;
        this.originalPrimaryProcess2 = blenderProcess2Mesh;
        this.originalPrimaryProcess3 = blenderProcess3Mesh;

        // Decision
        const blenderDecisionMesh = await this.loadMesh('decision', '', scene);

        blenderDecisionMesh.mesh.visibility = 0;
        this.originalDecision = blenderDecisionMesh;

        // Entries
        const types = ['standard', 'Brief', 'eMail', 'Excel', 'File', 'pdf', 'Word'];

        // File & mFile
        this.originalFile = {};
        await this.loadMeshesForCategory('file', types, this.originalFile, scene);

        this.originalMFile = {};
        await this.loadMeshesForCategory('mFile', types, this.originalMFile, scene);

        // Document & mDocument
        this.originalDocument = {};
        await this.loadMeshesForCategory('document', types, this.originalDocument, scene);

        this.originalMDocument = {};
        await this.loadMeshesForCategory('mDocument', types, this.originalMDocument, scene);

        // Table & mTable
        this.originalTable = {};
        await this.loadMeshesForCategory('table', types, this.originalTable, scene);

        this.originalMTable = {};
        await this.loadMeshesForCategory('mTable', types, this.originalMTable, scene);

        // Decision output
        const blenderDecisionOutputMesh = await this.loadMesh('decisionOutput', '', scene);

        blenderDecisionOutputMesh.mesh.visibility = 0;

        this.originalDecisionOutput = blenderDecisionOutputMesh;
    }

    private async loadMeshesForCategory(
        meshName: string,
        types: string[],
        variable: BlenderMeshesByType,
        scene: Scene
    ): Promise<void> {
        await Promise.all(
            types.map(async type => {
                return await this.loadMeshByType(meshName, type, variable, scene);
            })
        );
    }

    private async loadMeshByType(
        meshName: string,
        type: string,
        variable: BlenderMeshesByType,
        scene: Scene
    ): Promise<void> {
        const name = `${meshName}${type === 'standard' ? '' : '_' + type}`;
        const blenderDocumentMesh = await this.loadMesh(name, '', scene);
        blenderDocumentMesh.mesh.visibility = 0;

        variable[type] = blenderDocumentMesh;
    }

    // Flowchart specific functions

    /*********************************************
     * Process
     *********************************************/

    protected borderMeshes: Mesh[] = [];

    private originalPrimaryProcess: BlenderMesh;
    private originalPrimaryProcess2: BlenderMesh;
    private originalPrimaryProcess3: BlenderMesh;
    private originalWarnProcess: Mesh;

    /**
     * Creates the Mesh for the Process.
     *
     * @param id Identifier of the Process
     * @param pos Position of the Process
     * @param bpTask BusinessProcessTask
     * @param scene Scene
     * @returns Mesh
     */
    protected async createProcess(
        id: string,
        pos: Position,
        bpTask: BusinessProcessTask,
        scene: Scene
    ): Promise<Mesh> {
        // Sets the proper material based on tabFlag and type
        const colorMaterial = this.getColorMaterial(id);

        // Meshes that should be merged
        const meshes: Mesh[] = [];
        let baseMesh: Mesh;
        let height: number;
        if (
            this.originalPrimaryProcess &&
            this.originalPrimaryProcess2 &&
            this.originalPrimaryProcess3
        ) {
            switch (bpTask.data.action.category) {
                case 'PROCESS':
                    baseMesh = this.originalPrimaryProcess.mesh.clone(id);
                    height = this.originalPrimaryProcess.height;
                    break;
                case 'CONTROL':
                    baseMesh = this.originalPrimaryProcess2.mesh.clone(id);
                    height = this.originalPrimaryProcess2.height;
                    break;
                case 'M_PROCESS':
                    baseMesh = this.originalPrimaryProcess3.mesh.clone(id);
                    height = this.originalPrimaryProcess3.height;
                    break;
                default:
                    baseMesh = this.originalPrimaryProcess.mesh.clone(id);
                    height = this.originalPrimaryProcess.height;
                    break;
            }

            baseMesh.position = new Vector3(pos.x, pos.y, this.boxZ);
            baseMesh.visibility = 1;
        }

        // Has table connection
        if (this.hasTableConnection(bpTask)) {
            const tableMeshes = this.createTableShape(0.15, scene);
            const mergedTable = this.createMergedMesh(
                tableMeshes,
                { x: 0.95, y: 0.3, z: -0.01 },
                'hasTable'
            );
            meshes.push(mergedTable);
        }

        // Create texts
        const texts = bpTask.data.action;

        const textPos_01 = this.originalPrimaryProcess.textPositions.get('Text_01');
        const responsible = this.createText(
            { x: textPos_01.x, y: textPos_01.y, z: 0.02 },
            `${texts.no}_responsible`,
            texts.responsible,
            scene
        );
        if (responsible) meshes.push(responsible);

        const textPos_02 = this.originalPrimaryProcess.textPositions.get('Text_02');
        const title1 = this.createText(
            { x: textPos_02.x, y: textPos_02.y, z: 0.02 },
            `${texts.no}_title1`,
            texts.title1,
            scene
        );
        if (title1) meshes.push(title1);

        const textPos_03 = this.originalPrimaryProcess.textPositions.get('Text_03');
        const title2 = this.createText(
            { x: textPos_03.x, y: textPos_03.y, z: 0.02 },
            `${texts.no}_title2`,
            texts.title2,
            scene
        );
        if (title2) meshes.push(title2);

        const textPos_04 = this.originalPrimaryProcess.textPositions.get('Text_04');
        const system = this.createText(
            { x: textPos_04.x, y: textPos_04.y, z: 0.02 },
            `${texts.no}_no`,
            texts.no,
            scene
        );
        if (system) meshes.push(system);

        const textPos_05 = this.originalPrimaryProcess.textPositions.get('Text_05');
        const no = this.createText(
            { x: textPos_05.x, y: textPos_05.y, z: 0.02 },
            `${texts.no}_system`,
            texts.system,
            scene
        );
        // Process
        if (bpTask.data.action.category === 'CONTROL') {
            no.material = this.whiteTextMaterial;
        }
        if (no) meshes.push(no);

        // Merge Meshesó
        const mergedMesh: Mesh | null = this.createMergedMesh(
            meshes,
            { x: pos.x, y: pos.y, z: this.boxZ },
            id
        );

        let entryMeshes: MeshWithTexts[] = [];
        let arrows: Mesh[] = [];
        let inputResponse = this.createEntries(
            bpTask.data.input,
            mergedMesh,
            'input',
            scene,
            'process',
            pos.x
        );
        entryMeshes.push(...inputResponse.entries);
        arrows.push(...inputResponse.arrows);

        let outputResponse = this.createEntries(
            bpTask.data.output,
            mergedMesh,
            'output',
            scene,
            'process',
            pos.x
        );
        entryMeshes.push(...outputResponse.entries);
        arrows.push(...outputResponse.arrows);

        this.allMeshes[bpTask.id] = {
            id: bpTask.id,
            position: pos,
            isCursorOverMesh: false,
            meshTexts: mergedMesh,
            centralMesh: baseMesh,
            arrowsForEntries: arrows,
            entries: entryMeshes,
            bpTask: createDeepCopy(bpTask),
            centerPosition: new Vector3(pos.x, pos.y, pos.z),
            totalHeight: height,
        };

        return baseMesh;
    }

    // Arrows for entries

    /**
     * Creates the arrow from the entry.
     *
     * @param entryMesh The Mesh of the entry
     * @param scene Scene
     * @param arrowMaterial Material
     * @param type 'input' | 'output'
     * @returns The position of the endpoint as Vector3
     */
    protected createEntryArrow(
        entryMesh: Mesh,
        scene: Scene,
        arrowMaterial: Material,
        type: 'input' | 'output',
        entryType: 'process' | 'decision'
    ): { vector: Vector3; mesh: Mesh } {
        const xOffset = type === 'input' ? 1.5 : -0.75;
        const yOffset = entryType === 'process' ? -0.375 : -0;
        const modifiedA = new Vector3(
            entryMesh.position.x + xOffset,
            entryMesh.position.y + yOffset,
            entryMesh.position.z
        );
        const modifiedB = new Vector3(modifiedA.x + 0.6, modifiedA.y, modifiedA.z);

        // Calculate the direction and length of the arrow
        const direction = modifiedB.subtract(modifiedA);
        const length = direction.length();

        const meshes = [];
        if (type === 'input') {
            // Sphere
            const sphere = this.createSphere(scene);
            sphere.position = modifiedA;
            sphere.material = arrowMaterial;
            meshes.push(sphere);
        }

        // Shaft
        const shaftLength = length - this.horizontalArrowLengthCorrection;
        const shaft = this.createShaft(shaftLength, scene);

        // Set the positions
        shaft.position = modifiedA.add(direction.normalize().scale(shaftLength * this.half));
        shaft.rotation = new Vector3(0, 0, this.rotationNinentyDegrees);
        shaft.material = arrowMaterial;
        meshes.push(shaft);

        if (type === 'output') {
            // Tip
            const tip = this.createTip(scene);
            tip.position = shaft.position.add(
                direction.normalize().scale(shaftLength * this.half + this.tipLength * this.half)
            );
            tip.rotation = new Vector3(0, 0, this.rotationNinentyDegrees);
            tip.material = arrowMaterial;
            meshes.push(tip);
        }

        const mergedMesh = this.createMergedMesh(
            meshes,
            { x: 0, y: 0, z: 0 },
            'not_clickable_arrow'
        );

        return type === 'input'
            ? { vector: modifiedB, mesh: mergedMesh }
            : { vector: modifiedA, mesh: mergedMesh };
    }

    /**
     * Creates the arrow that goes into the process.
     *
     * @param parent The Mesh to that all the entries are connected
     * @param scene Scene
     * @param arrowMaterial Material
     * @param type 'input' | 'output'
     */
    protected createArrowForProcess(
        parent: Mesh,
        scene: Scene,
        arrowMaterial: Material,
        type: 'input' | 'output',
        entryType: 'process' | 'decision'
    ): Mesh {
        const xOffset = type === 'input' ? -0.9 : 1.7;
        const modifiedA = new Vector3(
            parent.position.x + xOffset,
            parent.position.y + (entryType === 'decision' ? 0.05 : 0),
            parent.position.z
        );
        const modifiedB = new Vector3(modifiedA.x - 0.7, modifiedA.y, modifiedA.z);
        // Calculate the direction and length of the arrow
        const direction = modifiedA.subtract(modifiedB);
        const length = direction.length();

        const meshes = [];

        if (type === 'output') {
            // Sphere
            const sphere = this.createSphere(scene);
            sphere.position = modifiedB;
            sphere.material = arrowMaterial;
            meshes.push(sphere);
        }

        // Shaft
        const shaftLength = length - this.horizontalArrowLengthCorrection;
        const shaft = this.createShaft(shaftLength, scene);

        // Set the positions
        shaft.position = modifiedB.add(direction.normalize().scale(shaftLength * this.half));
        shaft.rotation = new Vector3(0, 0, this.rotationNinentyDegrees);
        shaft.material = arrowMaterial;
        meshes.push(shaft);

        if (type === 'input') {
            // Tip
            const tipLength = 0.1;
            const tip = this.createTip(scene);

            tip.position = shaft.position.add(
                direction.normalize().scale(shaftLength * this.half + tipLength * this.half)
            );
            tip.rotation = new Vector3(0, 0, this.rotationNinentyDegrees);
            tip.material = arrowMaterial;
            meshes.push(tip);
        }

        const mergedMesh = this.createMergedMesh(
            meshes,
            { x: 0, y: 0, z: 0 },
            'not_clickable_arrow'
        );
        return mergedMesh;
    }

    /**
     * Creates a vertical line from a point in the direction to another.
     *
     * @param firstPoint Vector3
     * @param lastPoint Vector3
     * @param scene Scene
     * @param xOffset Offset on the X axis
     */
    protected createVerticalLine(
        firstPoint: Vector3,
        lastPoint: Vector3,
        scene: Scene,
        xOffset: number
    ): Mesh {
        const startingPoint = new Vector3(lastPoint.x + xOffset, lastPoint.y, lastPoint.z);
        const endingPoint = new Vector3(
            firstPoint.x + xOffset,
            firstPoint.y + this.verticalArrowLengthCorrection,
            firstPoint.z
        );

        // Calculate the direction and length of the arrow
        const direction = endingPoint.subtract(startingPoint);
        const length = direction.length();

        // Shaft
        const shaftLength = length - this.verticalArrowLengthCorrection;
        const shaft = this.createShaft(shaftLength, scene);
        shaft.position = startingPoint.add(direction.normalize().scale(shaftLength * this.half));
        shaft.material = this.arrowMaterial;
        return shaft;
    }

    /**
     * Creates the arrows for the entries.
     *
     * @param parent The Mesh to that all the entries are connected
     * @param entries Entries
     * @param type 'input' | 'output'
     * @param scene Scene
     */
    protected createArrowsForEntries(
        parent: Mesh,
        entries: Mesh[],
        type: 'input' | 'output',
        scene: Scene,
        entryType: 'process' | 'decision'
    ): Mesh[] {
        const arrows: Mesh[] = [];
        if (entries.length && type === 'input') {
            let firstPoint: Vector3;
            let lastPoint: Vector3;
            entries.forEach((entryMesh: Mesh, index: number) => {
                let response = this.createEntryArrow(
                    entryMesh,
                    scene,
                    this.arrowMaterial,
                    type,
                    entryType
                );
                const point = response.vector;
                arrows.push(response.mesh);

                if (index === 0) {
                    firstPoint = point;
                } else if (index === entries.length - 1) {
                    lastPoint = point;
                }
            });

            arrows.push(
                this.createArrowForProcess(parent, scene, this.arrowMaterial, type, entryType)
            );

            if (entries.length > 1) {
                arrows.push(
                    this.createVerticalLine(
                        firstPoint,
                        lastPoint,
                        scene,
                        -this.horizontalArrowLengthCorrection
                    )
                );
            }
        } else if (entries.length && type === 'output') {
            let firstPoint: Vector3;
            let lastPoint: Vector3;
            entries.forEach((entryMesh: Mesh, index: number) => {
                let response = this.createEntryArrow(
                    entryMesh,
                    scene,
                    this.arrowMaterial,
                    type,
                    entryType
                );
                const point = response.vector;
                arrows.push(response.mesh);

                if (index === 0) {
                    firstPoint = point;
                } else if (index === entries.length - 1) {
                    lastPoint = point;
                }
            });

            arrows.push(
                this.createArrowForProcess(parent, scene, this.arrowMaterial, type, entryType)
            );

            if (entries.length > 1) {
                arrows.push(this.createVerticalLine(firstPoint, lastPoint, scene, 0));
            }
        }

        return arrows;
    }

    // Inputs and Outputs

    /**
     * Creates the entries (either in or outputs) for a Process/Decision.
     *
     * @param entries BusinessProcessTaskDataInterface[]
     * @param parent Mesh
     * @param type 'input' | 'output'
     */
    protected createEntries(
        entries: BusinessProcessTaskDataInterface[],
        parent: Mesh,
        type: 'input' | 'output',
        scene: Scene,
        entryType: 'process' | 'decision',
        x: number
    ): { entries: MeshWithTexts[]; arrows: Mesh[] } {
        // Decide the position on the X axis
        let pos;
        if (type === 'input') {
            pos = { x: x + -3.5, y: parent.position.y + 0.75, z: 0.06 };
        } else {
            pos = { x: x + 2.25, y: parent.position.y + 0.75, z: 0.06 };
        }

        // Create the Meshes
        const inputMeshes: MeshWithTexts[] = [];
        entries.forEach((bpTaskData: BusinessProcessTaskDataInterface, index: number) => {
            let y;
            if (entries.length === 1) {
                y = pos.y + -0.375;
            } else {
                y = pos.y + -1.2 * index;
            }
            switch (bpTaskData.category) {
                case 1:
                    // Document
                    const document: MeshWithTexts = this.createDocument(bpTaskData, scene);
                    document.mesh.position = new Vector3(pos.x, y, pos.z);
                    if (document.texts) {
                        document.texts.position = new Vector3(
                            pos.x - document.texts.position.x,
                            document.texts.position.y + y,
                            pos.z
                        );
                    }
                    inputMeshes.push(document);
                    break;
                case 2:
                    // mDocument
                    const mDocument: MeshWithTexts = this.createMDocument(bpTaskData, scene);
                    mDocument.mesh.position = new Vector3(pos.x, y, pos.z);
                    if (mDocument.texts) {
                        mDocument.texts.position = new Vector3(
                            pos.x - mDocument.texts.position.x,
                            mDocument.texts.position.y + y,
                            pos.z
                        );
                    }
                    inputMeshes.push(mDocument);
                    break;
                case 3:
                case 7:
                    // Table
                    const table: MeshWithTexts = this.createTable(bpTaskData, scene);
                    table.mesh.position = new Vector3(pos.x, y, pos.z);
                    if (table.texts) {
                        table.texts.position = new Vector3(
                            pos.x - table.texts.position.x,
                            table.texts.position.y + y,
                            pos.z
                        );
                    }
                    inputMeshes.push(table);
                    break;
                case 4:
                    // mTable
                    const mTable: MeshWithTexts = this.createMTable(bpTaskData, scene);
                    mTable.mesh.position = new Vector3(pos.x, y, pos.z);
                    if (mTable.texts) {
                        mTable.texts.position = new Vector3(
                            pos.x - mTable.texts.position.x,
                            mTable.texts.position.y + y,
                            pos.z
                        );
                    }
                    inputMeshes.push(mTable);
                    break;
                case 5:
                    // File
                    const file: MeshWithTexts = this.createFile(bpTaskData, scene);
                    file.mesh.position = new Vector3(pos.x, y, pos.z);
                    if (file.texts) {
                        file.texts.position = new Vector3(
                            pos.x - file.texts.position.x,
                            file.texts.position.y + y,
                            pos.z
                        );
                    }
                    inputMeshes.push(file);
                    break;
                case 6:
                    // mFile
                    const mFile: MeshWithTexts = this.createMFile(bpTaskData, scene);
                    mFile.mesh.position = new Vector3(pos.x, y, pos.z);
                    if (mFile.texts) {
                        mFile.texts.position = new Vector3(
                            pos.x - mFile.texts.position.x,
                            mFile.texts.position.y + y,
                            pos.z
                        );
                    }
                    inputMeshes.push(mFile);
                    break;
            }
        });
        const arrows = this.createArrowsForEntries(
            parent,
            inputMeshes.map(m => m.mesh),
            type,
            scene,
            entryType
        );
        return { entries: inputMeshes, arrows };
    }

    private getBaseMesh(original: BlenderMesh, id: string): Mesh {
        let baseMesh: Mesh = original.mesh.clone(id);
        baseMesh.position = new Vector3(0, 0, 0);
        baseMesh.visibility = 1;

        return baseMesh;
    }

    private getOriginalBlenderMesh(
        variable: BlenderMeshesByType,
        bpTaskData: BusinessProcessTaskDataInterface
    ): BlenderMesh {
        let type: string = 'standard';
        if (bpTaskData.metadata && bpTaskData.metadata['_sourceType']) {
            type = bpTaskData.metadata['_sourceType'];
        }

        return variable[type];
    }

    private originalDocument: BlenderMeshesByType;
    /**
     * Creates the Mesh for the type Document.
     *
     * @param bpTaskData BusinessProcessTaskDataInterface
     * @returns Mesh
     */
    protected createDocument(
        bpTaskData: BusinessProcessTaskDataInterface,
        scene: Scene
    ): MeshWithTexts {
        const originalDocumentByType: BlenderMesh = this.getOriginalBlenderMesh(
            this.originalDocument,
            bpTaskData
        );

        let baseMesh: Mesh = this.getBaseMesh(originalDocumentByType, bpTaskData.id);

        const textPos_01 = originalDocumentByType.textPositions.get('Text_01');
        const text = this.createText(
            { x: textPos_01.x, y: textPos_01.y, z: 0 },
            bpTaskData.description,
            bpTaskData.description,
            scene
        );

        return {
            mesh: baseMesh,
            texts: text,
        };
    }

    private originalMDocument: BlenderMeshesByType;
    /**
     * Creates the Mesh for the type mDocument.
     *
     * @param bpTaskData BusinessProcessTaskDataInterface
     * @returns Mesh
     */
    protected createMDocument(
        bpTaskData: BusinessProcessTaskDataInterface,
        scene: Scene
    ): MeshWithTexts {
        const originalMDocumentByType: BlenderMesh = this.getOriginalBlenderMesh(
            this.originalMDocument,
            bpTaskData
        );

        let baseMesh: Mesh = this.getBaseMesh(originalMDocumentByType, bpTaskData.id);

        const textPos_01 = originalMDocumentByType.textPositions.get('Text_01');
        const text = this.createText(
            { x: textPos_01.x, y: textPos_01.y, z: 0 },
            bpTaskData.description,
            bpTaskData.description,
            scene
        );

        return {
            mesh: baseMesh,
            texts: text,
        };
    }

    private originalFile: BlenderMeshesByType;
    /**
     * Creates the Mesh for the type File.
     *
     * @param bpTaskData BusinessProcessTaskDataInterface
     * @returns Mesh
     */
    protected createFile(
        bpTaskData: BusinessProcessTaskDataInterface,
        scene: Scene
    ): MeshWithTexts {
        const originalFileByType: BlenderMesh = this.getOriginalBlenderMesh(
            this.originalFile,
            bpTaskData
        );

        let baseMesh: Mesh = this.getBaseMesh(originalFileByType, bpTaskData.id);

        const textPos_01 = originalFileByType.textPositions.get('Text_01');
        const text = this.createText(
            { x: textPos_01.x, y: textPos_01.y, z: 0 },
            bpTaskData.description,
            bpTaskData.description,
            scene
        );

        return {
            mesh: baseMesh,
            texts: text,
        };
    }

    private originalMFile: BlenderMeshesByType;
    /**
     * Creates the Mesh for the type mFile.
     *
     * @param bpTaskData BusinessProcessTaskDataInterface
     * @returns Mesh
     */
    protected createMFile(
        bpTaskData: BusinessProcessTaskDataInterface,
        scene: Scene
    ): MeshWithTexts {
        const originalMFileByType: BlenderMesh = this.getOriginalBlenderMesh(
            this.originalMFile,
            bpTaskData
        );

        let baseMesh: Mesh = this.getBaseMesh(originalMFileByType, bpTaskData.id);

        const textPos_01 = originalMFileByType.textPositions.get('Text_01');
        const text = this.createText(
            { x: textPos_01.x, y: textPos_01.y, z: 0 },
            bpTaskData.description,
            bpTaskData.description,
            scene
        );

        return {
            mesh: baseMesh,
            texts: text,
        };
    }

    private createTableShape(size: number, scene: Scene): Mesh[] {
        const meshes: Mesh[] = [];

        const radius = 0.875 * size;
        const height = 0.5 * size;
        const leftX = -0.25;
        const y = -(height / 2);
        const rightX = leftX + 2 * radius;
        const semicircleX = leftX + radius;

        // Top
        meshes.push(
            this.createSemicircle(
                { x: semicircleX, y: 0, z: 0 },
                this.primaryBoxMaterial,
                {
                    yOffset: 1,
                    radius,
                    yDivider: 5,
                },
                scene
            )
        );
        // Bottom
        meshes.push(
            this.createSemicircle(
                { x: semicircleX, y: 0, z: 0 },
                this.primaryBoxMaterial,
                {
                    yOffset: -1,
                    radius,
                    yDivider: 5,
                },
                scene
            )
        );

        // Left
        const left = MeshBuilder.CreateBox(
            'left',
            {
                size: height,
                width: 0.01,
                depth: 0.01,
            },
            scene
        );
        this.setPosAndMaterial(left, this.primaryBoxMaterial, {
            x: leftX,
            y,
            z: 0,
        });
        meshes.push(left);

        // Right
        const right = MeshBuilder.CreateBox(
            'right',
            {
                size: height,
                width: 0.01,
                depth: 0.01,
            },
            scene
        );
        this.setPosAndMaterial(right, this.primaryBoxMaterial, {
            x: rightX,
            y,
            z: 0,
        });
        meshes.push(right);

        // Bottom
        meshes.push(
            this.createSemicircle(
                { x: semicircleX, y: -height, z: 0 },
                this.primaryBoxMaterial,
                {
                    yOffset: -1,
                    radius,
                    yDivider: 5,
                },
                scene
            )
        );

        return meshes;
    }

    private originalTable: BlenderMeshesByType;
    /**
     * Creates the Mesh for the type Table.
     *
     * @param bpTaskData BusinessProcessTaskDataInterface
     * @returns Mesh
     */
    protected createTable(
        bpTaskData: BusinessProcessTaskDataInterface,
        scene: Scene
    ): MeshWithTexts {
        const originalTableByType: BlenderMesh = this.getOriginalBlenderMesh(
            this.originalTable,
            bpTaskData
        );

        let baseMesh: Mesh = this.getBaseMesh(originalTableByType, bpTaskData.id);

        const textPos_01 = originalTableByType.textPositions.get('Text_01');
        const text = this.createText(
            { x: textPos_01.x, y: textPos_01.y, z: 0 },
            bpTaskData.description,
            bpTaskData.description,
            scene
        );

        return {
            mesh: baseMesh,
            texts: text,
        };
    }

    private originalMTable: BlenderMeshesByType;
    /**
     * Creates the Mesh for the type mTable.
     *
     * @param bpTaskData BusinessProcessTaskDataInterface
     * @returns Mesh
     */
    protected createMTable(
        bpTaskData: BusinessProcessTaskDataInterface,
        scene: Scene
    ): MeshWithTexts {
        const originalMTableByType: BlenderMesh = this.getOriginalBlenderMesh(
            this.originalMTable,
            bpTaskData
        );

        let baseMesh: Mesh = this.getBaseMesh(originalMTableByType, bpTaskData.id);

        const textPos_01 = originalMTableByType.textPositions.get('Text_01');
        const text = this.createText(
            { x: textPos_01.x, y: textPos_01.y, z: 0 },
            bpTaskData.description,
            bpTaskData.description,
            scene
        );

        return {
            mesh: baseMesh,
            texts: text,
        };
    }

    /*********************************************
     * Decision
     *********************************************/

    private originalDecision: BlenderMesh;
    /**
     * Creates the Mesh for the Decision
     *
     * @param id Identifier of the Decision
     * @param pos Position of the Decision
     * @param bpTask BusinessProcessTask
     * @param scene Scene
     * @returns Mesh
     */
    protected createDecision(
        id: string,
        pos: Position,
        bpTask: BusinessProcessTask,
        scene: Scene
    ): Mesh {
        let meshes: Mesh[] = [];
        let baseMesh: Mesh = this.originalDecision.mesh.clone(id);
        baseMesh.position = new Vector3(pos.x, pos.y, this.boxZ);
        baseMesh.visibility = 1;

        // Create text for Decision
        const texts = bpTask.data.action;

        const textPos_1 = this.originalDecision.textPositions.get('Text_01');
        const decisionText = this.createText(
            { x: textPos_1.x, y: textPos_1.y, z: this.textPosZ },
            `${texts.title1}_no`,
            texts.title1,
            scene
        );
        meshes.push(decisionText);

        const textPos_2 = this.originalDecision.textPositions.get('Text_02');
        const yesText = this.createText(
            { x: textPos_2.x, y: textPos_2.y, z: this.textPosZ },
            `yesText`,
            'yes',
            scene
        );
        meshes.push(yesText);

        if (bpTask.data.output?.length) {
            const textPos_3 = this.originalDecision.textPositions.get('Text_03');
            const noText = this.createText(
                { x: textPos_3.x, y: textPos_3.y, z: this.textPosZ },
                `${texts.title1}_no`,
                'no',
                scene
            );
            meshes.push(noText);
        }

        // Merge meshes
        const mergedMesh: Mesh | null = this.createMergedMesh(
            meshes,
            { x: pos.x, y: pos.y, z: this.boxZ },
            id
        );

        let response = this.createOutputs(bpTask.data.output, mergedMesh, scene, pos.x);

        this.allMeshes[bpTask.id] = {
            id: bpTask.id,
            position: { ...pos, y: pos.y - 0.4 },
            isCursorOverMesh: false,
            meshTexts: mergedMesh,
            centralMesh: baseMesh,
            arrowsForEntries: response.arrows ?? [],
            entries: response.entries ?? [],
            bpTask: createDeepCopy(bpTask),
            centerPosition: new Vector3(pos.x, pos.y - 0.4, pos.z),
            totalHeight: this.originalDecision.height,
        };

        return baseMesh;
    }

    /**
     * Creates the outputs of the Decision.
     *
     * @param outputs BusinessProcessTaskDataInterface[]
     * @param parent Mesh of the Decision
     * @param scene Scene
     * @param x Position the the X axis
     * @returns entries: Mesh[]; arrows: Mesh[]
     */
    protected createOutputs(
        outputs: BusinessProcessTaskDataInterface[],
        parent: Mesh,
        scene: Scene,
        x: number
    ): { entries: MeshWithTexts[]; arrows: Mesh[] } {
        // Decide the position on the X axis
        let pos = { x: x + 2.25, y: parent.position.y + 0.05, z: 0.06 };

        // Create the Meshes
        const inputMeshes: MeshWithTexts[] = [];
        outputs.forEach((bpTaskData: BusinessProcessTaskDataInterface, index: number) => {
            let y;
            if (outputs.length === 1) {
                y = pos.y;
            } else {
                y = pos.y + -1.2 * index;
            }
            // Document
            const document: MeshWithTexts = this.createDecisionOutput(bpTaskData, scene);
            document.mesh.position = new Vector3(pos.x, y, pos.z);
            document.texts.position = new Vector3(
                pos.x - document.texts.position.x,
                document.texts.position.y + y,
                pos.z
            );
            inputMeshes.push(document);
        });
        const arrows = this.createArrowsForEntries(
            parent,
            inputMeshes.map(m => m.mesh),
            'output',
            scene,
            'decision'
        );

        return { entries: inputMeshes, arrows };
    }

    private originalDecisionOutput: BlenderMesh;
    /**
     * Create the output of the Decision.
     *
     * @param bpTaskData BusinessProcessTaskDataInterface
     * @param scene Scene
     * @returns Mesh
     */
    protected createDecisionOutput(
        bpTaskData: BusinessProcessTaskDataInterface,
        scene: Scene
    ): MeshWithTexts {
        let baseMesh: Mesh = this.getBaseMesh(this.originalDecisionOutput, bpTaskData.id);

        const textPos_01 = this.originalDecisionOutput.textPositions.get('Text_01');
        const text = this.createText(
            { x: textPos_01.x, y: textPos_01.y, z: 0 },
            bpTaskData.description,
            bpTaskData.description,
            scene
        );

        return {
            mesh: baseMesh,
            texts: text,
        };
    }

    // Utilities

    override createCheckMark(pos: Position, scene: Scene): void {
        if (this.checkMark) {
            this.checkMark.position = new Vector3(pos.x, pos.y, this.boxZ);
        } else {
            const bezierT = 50; // Number of points
            const bezierPoints = Curve3.CreateQuadraticBezier(
                new Vector3(-0.1, 0.1, 0), // start point
                new Vector3(0, -0.1, 0), // control point
                new Vector3(0.05, -0.075, 0), // end point of first curve
                bezierT
            ).getPoints();

            const additionalPoints = Curve3.CreateQuadraticBezier(
                new Vector3(0.05, -0.075, 0), // start point
                new Vector3(0.1, 0, 0), // control point
                new Vector3(0.2, 0.2, 0), // end point of second curve
                bezierT
            ).getPoints();

            const checkMarkPath = bezierPoints.concat(additionalPoints);

            const checkMarkShape = [];
            const circleResolution = 32; // Increase this for a higher resolution

            function calculatePoints(i: number): void {
                const angle = (i * Math.PI * 2) / circleResolution;
                const x = 0.04 * Math.cos(angle);
                const y = 0.04 * Math.sin(angle);
                checkMarkShape.push(new Vector3(x, y, 0));
            }

            for (let i = 0; i < circleResolution; i++) {
                calculatePoints(i);
            }
            calculatePoints(0);

            this.checkMark = MeshBuilder.ExtrudeShape(
                'checkmark',
                {
                    shape: checkMarkShape,
                    path: checkMarkPath,
                    cap: Mesh.CAP_ALL,
                },
                scene
            );
            this.checkMark.material = this.textMaterial;

            this.checkMark.position = new Vector3(pos.x, pos.y, this.boxZ);
        }
    }

    /**
     * Creates a rectangle that has a wavy curve on the bottom.
     *
     * @param bpTaskData BusinessProcessTaskDataInterface
     * @param offset An offset that is added to each point of the Mesh
     * @param material Material
     * @param z The position of the Outline on the Z axis
     * @param pos Position of the merged Mesh
     * @returns Mesh
     */
    protected createCurvedRectanle(
        bpTaskData: BusinessProcessTaskDataInterface,
        offset: number,
        material: Material,
        z: number,
        pos: Position,
        scene: Scene
    ): Mesh {
        const meshes: Mesh[] = [];
        // Create a Bézier curve
        var path = [];
        path.push(new Vector3(0.25 + offset, -0.75 - offset, 0)); // Starting point
        path.push(new Vector3(-0.25, -1 - offset, 0)); // Control point 1 (highest point)
        path.push(new Vector3(-0.75 - offset, -0.75 - offset, 0)); // Ending point

        var curve = Curve3.CreateCubicBezier(path[0], path[1], path[2], path[2], 100);

        var path2 = [];
        path2.push(new Vector3(-0.75 - offset, -0.75 - offset, 0)); // Starting point
        path2.push(new Vector3(-1.25 - offset, -0.5 - offset, 0)); // Control point 1 (highest point)
        path2.push(new Vector3(-1.5 - offset, -0.7 - offset, 0)); // Ending point

        var curve2 = Curve3.CreateCubicBezier(path2[0], path2[1], path2[2], path2[2], 100);

        let documentOutlineShape1 = [
            new Vector3(0.25 + offset, 0 + offset, 0),
            ...curve.getPoints(),
            new Vector3(-0.75 - offset, 0 + offset, 0),
        ];

        let documentOutlinePath1 = [new Vector3(0, 0, 0), new Vector3(0, 0, z)];

        const documentOutline1 = MeshBuilder.ExtrudeShape(
            'documentOutline1',
            {
                shape: documentOutlineShape1,
                closeShape: true,
                path: documentOutlinePath1,
                cap: Mesh.CAP_ALL,
                sideOrientation: Mesh.DOUBLESIDE,
            },
            scene
        );
        documentOutline1.position = new Vector3(0, 0, 0);
        documentOutline1.material = material;

        meshes.push(documentOutline1);

        curve2.getPoints().forEach((bottomVector: Vector3, index: number) => {
            let previousVector;
            if (index > 0) {
                previousVector = curve2.getPoints()[index - 1];
            } else {
                previousVector = new Vector3(-0.75 - offset, 0 + offset, 0);
            }
            let documentOutlineShape2 = [
                previousVector,
                bottomVector,
                new Vector3(bottomVector.x, 0 + offset, 0),
                new Vector3(previousVector.x, 0 + offset, 0),
            ];

            const documentOutline2 = MeshBuilder.ExtrudeShape(
                'documentOutline2',
                {
                    shape: documentOutlineShape2,
                    closeShape: true,
                    path: documentOutlinePath1,
                    cap: Mesh.CAP_ALL,
                    sideOrientation: Mesh.DOUBLESIDE,
                },
                scene
            );
            documentOutline2.position = new Vector3(0, 0, 0);
            documentOutline2.material = material;
            meshes.push(documentOutline2);
        });

        const mergedMesh = this.createMergedMesh(
            meshes,
            { x: pos.x, y: pos.y, z: pos.z },
            bpTaskData.id
        );

        return mergedMesh;
    }

    /**
     * Creates a semicircle with the given parameters.
     *
     * @param pos Position of the semicircle
     * @param material Material
     * @param props yOffset: 1 | -1, radius, yDivider
     * @returns
     */
    protected createSemicircle(
        pos: Position,
        material: Material,
        props: { yOffset: 1 | -1; radius: number; yDivider: number },
        scene: Scene
    ): Mesh {
        // TOP
        // Define an array of Vector3 points to create a semi-circular path
        let path = [];

        // Generate points along a semi-circle
        for (let angle = 0; angle <= Math.PI + 0.1; angle += 0.1) {
            const x = Math.cos(angle) * props.radius;
            const y = ((Math.sin(angle) * props.radius) / props.yDivider) * props.yOffset;
            const z = 0; // You can adjust the z-coordinate as needed

            path.push(new Vector3(x, y, z));
        }

        // Create the curved line using CreateTube
        let curvedLine = MeshBuilder.CreateTube(
            'curvedLine',
            {
                path: path,
                radius: 0.01, // Adjust the radius as needed
                tessellation: 64, // Adjust the number of segments for a smoother curve
                updatable: true, // Set to true if you want to update the geometry dynamically
            },
            scene
        );
        curvedLine.material = material;
        curvedLine.position = new Vector3(pos.x, pos.y, pos.z);

        return curvedLine;
    }
}
