export enum BpTaskDataType {
    process = 'process',
    decision = 'decision',
    decisionOutput = 'decisionOutput',
    document = 'document',
    mDocument = 'mDocument',
    fiDocument = 'fiDocument',
    table = 'table',
    mTable = 'mTable',
    file = 'file',
    mFile = 'mFile',
    arrowStart = 'arrowStart',
    arrowEnd = 'arrowEnd',
    arrowVerticalStart = 'arrowVerticalStart',
    arrowVerticalEnd = 'arrowVerticalEnd',
    arrowVertical = 'arrowVertical',
    arrowVerticalStartEnd = 'arrowVerticalStartEnd',
    blank = 'blank',
    start = 'start',
    end = 'end',
    actor = 'actor',
}
