import { Mesh, Scene, Vector3, VertexData } from 'babylonjs';

export class SuperEllipsoid {
    static CreateSuperEllipsoid(
        name: string,
        sampling: number,
        yRoundness: number,
        xRoundness: number,
        scaleX: number,
        scaleY: number,
        scaleZ: number,
        scene: Scene,
        updatable?: boolean,
        sideOrientation?: number
    ): Mesh {
        // Create an instance of the SuperEllipsoid class
        const superEllipsoid = new SuperEllipsoid(
            name,
            sampling,
            yRoundness,
            xRoundness,
            scaleX,
            scaleY,
            scaleZ,
            scene
        );

        // Return the mesh
        return superEllipsoid.mesh;
    }

    mesh: Mesh;
    name: string;

    constructor(
        name: string,
        sampling: number,
        yRoundness: number,
        xRoundness: number,
        scaleX: number,
        scaleY: number,
        scaleZ: number,
        scene: Scene
    ) {
        this.mesh = this.createSuperEllipsoid(
            sampling,
            yRoundness,
            xRoundness,
            scaleX,
            scaleY,
            scaleZ,
            scene
        );
        this.name = name;
        this.mesh.name = name;
    }

    sampleSuperEllipsoid(
        phi: number,
        beta: number,
        n1: number,
        n2: number,
        scaleX: number,
        scaleY: number,
        scale: number
    ): Vector3 {
        var cosPhi = Math.cos(phi);
        var cosBeta = Math.cos(beta);
        var sinPhi = Math.sin(phi);
        var sinBeta = Math.sin(beta);
        var vertex = new Vector3();
        vertex.x =
            scaleX *
            Math.sign(cosPhi) *
            Math.pow(Math.abs(cosPhi), n1) *
            Math.sign(cosBeta) *
            Math.pow(Math.abs(cosBeta), n2);
        vertex.z =
            scaleY *
            Math.sign(cosPhi) *
            Math.pow(Math.abs(cosPhi), n1) *
            Math.sign(sinBeta) *
            Math.pow(Math.abs(sinBeta), n2);
        vertex.y = scale * Math.sign(sinPhi) * Math.pow(Math.abs(sinPhi), n1);
        return vertex;
    }

    calculateNormal(
        phi: number,
        beta: number,
        n1: number,
        n2: number,
        scaleX: number,
        scaleY: number,
        scale: number
    ): Vector3 {
        var normal = new Vector3();
        var cosPhi = Math.cos(phi);
        var cosBeta = Math.cos(beta);
        var sinPhi = Math.sin(phi);
        var sinBeta = Math.sin(beta);
        normal.x =
            (Math.sign(cosPhi) *
                Math.pow(Math.abs(cosPhi), 2 - n1) *
                Math.sign(cosBeta) *
                Math.pow(Math.abs(cosBeta), 2 - n2)) /
            scaleX;
        normal.z =
            (Math.sign(cosPhi) *
                Math.pow(Math.abs(cosPhi), 2 - n1) *
                Math.sign(sinBeta) *
                Math.pow(Math.abs(sinBeta), 2 - n2)) /
            scaleY;
        normal.y = (Math.sign(sinPhi) * Math.pow(Math.abs(sinPhi), 2 - n1)) / scale;
        normal = normal.normalize();
        return normal;
    }

    //-----------------------------------------------------
    createSuperEllipsoid(
        samples: number,
        n1: number,
        n2: number,
        scaleX: number,
        scaleY: number,
        scaleZ: number,
        scene: Scene,
        uvScale?: number
    ): Mesh {
        uvScale = uvScale || 1;
        var mesh = new Mesh('mesh', scene);

        var phi = 0.0,
            phi2 = 0.0,
            beta = 0.0;
        var dB = (Math.PI * 2.0) / samples;
        var dP = (Math.PI * 2.0) / samples;
        phi = -Math.PI / 2;
        var vertices = [];
        var normals = [];
        var uvs = [];

        const addUV = (beta, phi) => {
            const v = phi / (2 * Math.PI);
            const u = (beta + Math.PI) / (2 * Math.PI);
            uvs.push(u * uvScale, v * uvScale);
        };

        for (var j = 0; j <= samples / 2; j++) {
            beta = -Math.PI;

            for (var i = 0; i <= samples; i++) {
                vertices.push(this.sampleSuperEllipsoid(phi, beta, n1, n2, scaleX, scaleY, scaleZ));
                normals.push(this.calculateNormal(phi, beta, n1, n2, scaleX, scaleY, scaleZ));
                addUV(beta, phi);
                vertices.push(
                    this.sampleSuperEllipsoid(phi + dP, beta, n1, n2, scaleX, scaleY, scaleZ)
                );
                normals.push(this.calculateNormal(phi + dP, beta, n1, n2, scaleX, scaleY, scaleZ));
                addUV(beta, phi + dP);
                vertices.push(
                    this.sampleSuperEllipsoid(phi + dP, beta + dB, n1, n2, scaleX, scaleY, scaleZ)
                );
                normals.push(
                    this.calculateNormal(phi + dP, beta + dB, n1, n2, scaleX, scaleY, scaleZ)
                );
                addUV(beta + dB, phi + dP);

                vertices.push(this.sampleSuperEllipsoid(phi, beta, n1, n2, scaleX, scaleY, scaleZ));
                normals.push(this.calculateNormal(phi, beta, n1, n2, scaleX, scaleY, scaleZ));
                addUV(beta, phi);
                vertices.push(
                    this.sampleSuperEllipsoid(phi + dP, beta + dB, n1, n2, scaleX, scaleY, scaleZ)
                );
                normals.push(
                    this.calculateNormal(phi + dP, beta + dB, n1, n2, scaleX, scaleY, scaleZ)
                );
                addUV(beta + dB, phi + dP);
                vertices.push(
                    this.sampleSuperEllipsoid(phi, beta + dB, n1, n2, scaleX, scaleY, scaleZ)
                );
                normals.push(this.calculateNormal(phi, beta + dB, n1, n2, scaleX, scaleY, scaleZ));
                addUV(beta + dB, phi);

                beta += dB;
            }
            phi += dP;
        }

        var shapeReturned = new VertexData();
        shapeReturned.positions = [];
        shapeReturned.normals = [];
        shapeReturned.indices = [];
        shapeReturned.uvs = uvs;
        var indice = 0;

        let c = 0;
        for (var i = 0; i < vertices.length; i++) {
            if (c === 1) {
                shapeReturned.indices.push(indice + 1);
            } else if (c === 2) {
                shapeReturned.indices.push(indice - 1);
            } else {
                shapeReturned.indices.push(indice);
            }
            indice++;
            c++;
            if (c === 3) {
                c = 0;
            }

            shapeReturned.positions.push(vertices[i].x);
            shapeReturned.positions.push(vertices[i].y);
            shapeReturned.positions.push(vertices[i].z);
            shapeReturned.normals.push(normals[i].x);
            shapeReturned.normals.push(normals[i].y);
            shapeReturned.normals.push(normals[i].z);
        }

        shapeReturned.applyToMesh(mesh);
        return mesh;
    }
}
