<div *transloco="let t; read: 'contentLayoutWrapper'" class="header-container px-4">
    <!-- Title -->
    @if (config().heading) {
        <h3 class="heading h6">{{ config().heading }}</h3>
    }

    <!-- Extra content -->
    <div class="extra-content">
        <ng-content select="[headerExtraContent]"></ng-content>
    </div>

    <!-- Toggle button -->
    @if (config().showToggleButton) {
        <button
            mat-icon-button
            (click)="toggleDrawer()"
            [matTooltip]="t(isFullScreen() ? 'close' : 'fullscreen')"
            class="ml-auto">
            @if (isFullScreen()) {
                <mat-icon svgIcon="mat_outline:close_fullscreen"></mat-icon>
            } @else {
                <mat-icon svgIcon="mat_outline:open_in_full"></mat-icon>
            }
        </button>
    }
</div>
