import { Component, ElementRef, Injector, InputSignal, NgZone, effect, input } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { Engine, PickingInfo, Scene, Vector3 } from 'babylonjs';
import { FlowchartLoadingSpinnerService } from '../../reusable-components/flowchart/flowchart-container/flowchart-loading-spinner.service';
import { BabylonAncestral } from '../core/babylon-ancestral';

@Component({
    selector: 'file-observer',
    templateUrl: './file-observer.component.html',
    styleUrl: './file-observer.component.css',
})
export class FileObserverComponent extends BabylonAncestral {
    fileName: InputSignal<string> = input();

    protected cameraY: number = 0;
    protected cameraZ: number = -2;

    protected disableWheel: boolean = true;

    constructor(
        protected el: ElementRef,
        protected ngZone: NgZone,
        protected injector: Injector,
        protected fuseConfigService: FuseConfigService,
        spinnerService: FlowchartLoadingSpinnerService
    ) {
        super(el, ngZone, injector, fuseConfigService, spinnerService);

        effect(
            () => {
                if (this.fileName() !== null && typeof this.fileName() === 'string') {
                    this.startEngine(true);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    /**  Create the background plane */
    protected createBackground(scene: Scene): void {
        const size = 100;
        let height = 100;

        this.createBackgroundPlane(size, height, scene);
    }

    override getCameraX(): number {
        return 0;
    }

    protected override calculateTotalHeight(): number {
        return 1;
    }

    /**
     * Returns the size of the scene (width and height and which is bigger).
     *
     * @returns totalHeight, totalWidth, biggerSide
     */
    protected override getSizeOfScene(): { height: number; width: number; biggerSide: number } {
        const height = this.calculateTotalHeight();
        const width = 1;

        const biggerSide = height > width ? height : width;

        return { height, width, biggerSide };
    }

    protected handleSpecialEventOnCameraMovement(intersection: PickingInfo): void {}
    override handleSpecificSceneActions(): void {}

    protected override async createScene(
        engine: Engine,
        canvas: HTMLCanvasElement,
        reset?: boolean
    ): Promise<Scene> {
        // This creates a basic Babylon Scene object (non-mesh)
        var scene = new Scene(engine);

        this.createCamera(scene, 'arcRotate', true);

        await this.loadFont();

        this.createMaterials();

        this.setColors();

        this.createBackground(scene);

        const mesh = await this.loadMesh(this.fileName(), '', scene);

        mesh.mesh.position = new Vector3(0, 0, 0);

        mesh.textPositions.forEach((value: Vector3, key: string) => {
            this.createText(value, key, key, scene);
        });

        return scene;
    }

    loadFile(): void {}
}
