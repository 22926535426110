import { PickingInfo, Vector3 } from 'babylonjs';

export enum BabylonDirection {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right',
}

export interface BabylonDirections {
    top: Vector3 | null;
    bottom: Vector3 | null;
    left: Vector3 | null;
    right: Vector3 | null;
}

export interface BabylonPickingInfo {
    top: PickingInfo | null;
    bottom: PickingInfo | null;
    left: PickingInfo | null;
    right: PickingInfo | null;
}
