import { Component } from '@angular/core';
import { FlowchartLoadingSpinnerService } from '../flowchart/flowchart-container/flowchart-loading-spinner.service';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    styleUrl: './spinner.component.scss',
})
export class SpinnerComponent {
    constructor(public spinnerService: FlowchartLoadingSpinnerService) {}
}
