import { ChangeDetectionStrategy, Component, ElementRef, Injector, NgZone } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { BabylonBusinessProcessFlowchartComponent } from '../core/babylon-business-process-flowchart.component';
import { PrintService } from '../../print.service';
import { FlowchartLoadingSpinnerService } from '../../reusable-components/flowchart/flowchart-container/flowchart-loading-spinner.service';

@Component({
    selector: 'bp-flowchart-vertical',
    templateUrl: 'bp-flowchart.component.html',
    styleUrls: ['./bp-flowchart.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class BpFlowchartVerticalComponent extends BabylonBusinessProcessFlowchartComponent {
    protected override leftMostPoint: number = -5;
    protected override rightMostPoint: number = 5;

    constructor(
        el: ElementRef,
        ngZone: NgZone,
        injector: Injector,
        fuseConfigService: FuseConfigService,
        babylonService: PrintService,
        spinnerService: FlowchartLoadingSpinnerService
    ) {
        super(el, ngZone, injector, fuseConfigService, babylonService, spinnerService);
        this.enableCursorMovement.set(true);
    }
}
