<canvas [ngClass]="enableCursorMovement() ? 'enableCursorMovement' : ''" #renderCanvas></canvas>
<button
    id="myMenu"
    mat-button
    [matMenuTriggerFor]="contextMenu"
    #menuTrigger="matMenuTrigger"
    #menu
    class="invisible"></button>
<div>
    <mat-menu #contextMenu="matMenu">
        <button
            *ngFor="let item of availableMenuItems()"
            mat-menu-item
            (click)="handleOption(item)">
            {{ item.label }}
        </button>
    </mat-menu>
</div>
