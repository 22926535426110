/**
 * Defines the types how the user can navigate in the screen with its cursor.
 */
export enum MovingType {
    Natural = 'Natural',
    Inverted = 'Inverted',
}

export enum ViewType {
    Horizontal = 'Horizontal',
    Vertical = 'Vertical',
    SVG = 'SVG',
}

export enum SensitivityType {
    Varied = 'Varied',
    Fixed = 'Fixed',
}
