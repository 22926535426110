import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    InputSignal,
    OnInit,
    Signal,
    WritableSignal,
    computed,
    effect,
    input,
    signal,
} from '@angular/core';
import { ContentLayoutWrapperComponent } from 'app/core/ui/content-layout-wrapper/content-layout-wrapper.component';
import { TypeOfProcess } from '@em4cloud/process-tree';
import { FlowchartHeaderConfig } from '../../models/header.config';
import { MovingType, SensitivityType, ViewType } from '../../models/moving-type';

@Component({
    template: '',
})
export class BabylonHeaderComponent implements OnInit {
    config: InputSignal<FlowchartHeaderConfig> = input();
    _config: FlowchartHeaderConfig;

    TypeOfProcess: TypeOfProcess = TypeOfProcess.Task;

    movingType: string;
    isFullscreen: boolean = false;

    type: 'flowchart' | 'organisation';

    showCursorAndSensitivityOptions: Signal<boolean> = signal(true);
    sensitivityType: string;

    constructor(
        protected _contentLayoutWrapperComponent: ContentLayoutWrapperComponent,
        private cRef: ChangeDetectorRef,
        protected injector: Injector
    ) {}
    ngOnInit(): void {
        effect(
            () => {
                const conf = this.config();
                this._config = { ...conf };
                this.cRef.detectChanges();
            },
            {
                injector: this.injector,
            }
        );
        this.movingType = localStorage.getItem('movingType');
        this.sensitivityType = localStorage.getItem('sensitivityType');
    }

    changeMovingType(): void {
        const newOne =
            this.movingType === MovingType.Inverted ? MovingType.Natural : MovingType.Inverted;
        this.config().executeActionSignal.set(`movingType_${newOne}`);
        this.movingType = newOne;
    }
    movingTypeValues(): typeof MovingType {
        return MovingType;
    }

    changeSensitivityType(): void {
        const newOne =
            this.sensitivityType === SensitivityType.Fixed
                ? SensitivityType.Varied
                : SensitivityType.Fixed;
        this.config().executeActionSignal.set(`sensitivityType_${newOne}`);
        this.sensitivityType = newOne;
    }
    sensitivityTypeValues(): typeof SensitivityType {
        return SensitivityType;
    }

    toggleFullscreen(): void {
        this._contentLayoutWrapperComponent.toggleChildMatDrawerFullScreen();
        this.isFullscreen = this._contentLayoutWrapperComponent.isChildDrawerFullscreen();
    }
}

@Component({
    selector: 'flowchart-header',
    templateUrl: './babylon-header.component.html',
    styleUrls: ['./babylon-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowchartHeaderComponent extends BabylonHeaderComponent {
    viewType: WritableSignal<ViewType> = signal(ViewType.SVG);
    flowchartType: WritableSignal<FlowchartType> = signal(FlowchartType.all);

    override showCursorAndSensitivityOptions: Signal<boolean> = computed(() => {
        return this.viewType() === ViewType.Horizontal;
    });

    constructor(
        _contentLayoutWrapperComponent: ContentLayoutWrapperComponent,
        cRef: ChangeDetectorRef,
        injector: Injector
    ) {
        super(_contentLayoutWrapperComponent, cRef, injector);
        this.type = 'flowchart';
    }

    override ngOnInit(): void {
        super.ngOnInit();
        effect(
            () => {
                if (this.config().executeActionSignal) {
                    this.config().executeActionSignal.set(`viewType_${this.viewType()}`);
                }
            },
            {
                allowSignalWrites: true,
                injector: this.injector,
            }
        );
    }

    changeViewType(newViewType: ViewType): void {
        this.viewType.set(newViewType);
        this.config().executeActionSignal.set(`viewType_${newViewType}`);
    }
    viewTypeValues(): typeof ViewType {
        return ViewType;
    }

    changeFlowchartType(newFlowchartType: FlowchartType): void {
        this.flowchartType.set(newFlowchartType);
        this.config().executeActionSignal.set(`flowchartType_${newFlowchartType}`);
    }
    flowchartTypeValues(): typeof FlowchartType {
        return FlowchartType;
    }
}
@Component({
    selector: 'organisation-header',
    templateUrl: './babylon-header.component.html',
    styleUrls: ['./babylon-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationHeaderComponent extends BabylonHeaderComponent {
    constructor(
        _contentLayoutWrapperComponent: ContentLayoutWrapperComponent,
        cRef: ChangeDetectorRef,
        injector: Injector
    ) {
        super(_contentLayoutWrapperComponent, cRef, injector);
        this.type = 'organisation';
    }
}

export enum FlowchartType {
    all = 'all',
    technical = 'technical',
    financial = 'financial',
}
