import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewEncapsulation,
    computed,
    forwardRef,
    input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { merge } from 'lodash';
import { ContentLayoutWrapperComponent } from '../content-layout-wrapper/content-layout-wrapper.component';

export interface ContentLayoutHeaderConfig {
    heading?: string;
    showToggleButton?: boolean;
}

@Component({
    selector: 'content-layout-header',
    templateUrl: './content-layout-header.component.html',
    styleUrls: ['./content-layout-header.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        MatIconModule,
        TranslocoModule,
        MatTooltipModule,
        forwardRef(() => ContentLayoutWrapperComponent),
    ],
})
export class ContentLayoutHeaderComponent implements OnInit {
    // default configuration for header content
    private _defaultConfig: ContentLayoutHeaderConfig = {
        showToggleButton: true,
    };

    // passsed configuration from parent for header content
    config = input<ContentLayoutHeaderConfig, ContentLayoutHeaderConfig>(this._defaultConfig, {
        transform: value => merge({}, this._defaultConfig, value),
    });

    isFullScreen = computed(() =>
        this._contentLayoutWrapperComponent.isChildDrawerContentFullScreen()
    );

    constructor(private _contentLayoutWrapperComponent: ContentLayoutWrapperComponent) {}

    /**
     * ngOnInit
     */
    ngOnInit() {}

    /**
     * Toggle the drawer
     */
    toggleDrawer(): void {
        this._contentLayoutWrapperComponent.toggleChildMatDrawer();
    }
}
