import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Injector,
    Input,
    NgZone,
    WritableSignal,
    effect,
    signal,
} from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { ObjectExplorerItem, OrganisationStructure, OrganisationSubType } from 'app/api';
import { IconsService } from 'app/core/icons/icons.service';
import { ObjectExplorerService } from 'app/modules/object-explorer/object-explorer.service';
import {
    Color3,
    Engine,
    FreeCamera,
    Material,
    Mesh,
    MeshBuilder,
    PickingInfo,
    Scene,
    StandardMaterial,
    Texture,
    Tools,
    Vector3,
} from 'babylonjs';
import { takeUntil } from 'rxjs';
import { PrintService } from '../../../print.service';
import { FlowchartLoadingSpinnerService } from '../../../reusable-components/flowchart/flowchart-container/flowchart-loading-spinner.service';
import { BabylonAncestral } from '../../core/babylon-ancestral';
import { Position } from '../../core/position.model';
import { SuperEllipsoid } from '../../meshes/super-ellipsoid';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'bp-organisation',
    templateUrl: './bp-organisation.component.html',
    styleUrl: './bp-organisation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpOrganisationComponent extends BabylonAncestral {
    @Input() organisation: WritableSignal<OrganisationStructure>;
    @Input() showId: boolean;

    protected organisationWithCoordinates: OrganisationWithCoordinates;
    private _stringifiedOrganisation: string;

    protected override topMostPoint = 2;

    /** Z position of the Meshes */
    protected boxZ = 0.06;

    /** Position of the camera on the Y axis */
    protected override cameraY: number = -3;
    protected cameraZ: number = -10;

    /** A flag that indicates if the mouse is over the canvas or not */
    protected isMouseOverCanvas: boolean = false;

    /** A flag that indicates if the Scene is scrollable or not */
    protected canScroll: boolean = true;

    // Dimensions for Process
    protected organisationDivider = 1.5;
    protected organisationWidth = 1;
    protected subOrganisationWidth = this.organisationWidth / this.organisationDivider;
    protected organisationHeight = 0.5;
    protected subOrganisationHeight = 0.5 / this.organisationDivider;
    protected organisationDepth = 0.01;
    protected organisationResolution = 49;
    protected organisationRoundnessY = 0.1;
    protected organisationRoundnessX = 0;
    protected borderThickness = 0.02;
    protected borderBackgroundPosZ = 0.005;

    // Properties
    protected verticalArrowLengthCorrection = 0.2;
    protected horizontalArrowLengthCorrection = 0.2;
    protected cameraFOV = Math.PI / 4;
    protected textPosZ = 0.04;
    protected xDistance = 0.5;
    protected yDistance = 0.5;

    protected totalHeight = undefined;

    protected organisationMeshes: Mesh[] = [];

    override enableCursorMovement: WritableSignal<boolean> = signal(true);

    printCamera: FreeCamera;
    protected printWidth: number = 3840;
    protected printHeight: number = 2160;

    constructor(
        el: ElementRef,
        ngZone: NgZone,
        injector: Injector,
        fuseConfigService: FuseConfigService,
        protected babylonService: PrintService,
        spinnerService: FlowchartLoadingSpinnerService,
        private iconsService: IconsService,
        private objExplorerService: ObjectExplorerService
    ) {
        super(el, ngZone, injector, fuseConfigService, spinnerService);

        effect(
            () => {
                if (this.organisation && this.organisation().id) {
                    setTimeout(() => {
                        this.organisationWithCoordinates = {
                            ...(this.organisation() as OrganisationWithCoordinates),
                        };
                        this.startEngine(true);
                        this._stringifiedOrganisation = this.organisation().id; //JSON.stringify(this.organisation());
                    }, 500);
                }
            },
            {
                allowSignalWrites: true,
            }
        );

        this.subscribeToPrint();
        this.babylonService.reSubscribe.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.subscribeToPrint();
        });
    }

    /** Subscribing for print event */
    private subscribeToPrint(): void {
        this.babylonService.printScene.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.createScreenshot();
        });
    }

    // Print

    /** Capture the screenshot */
    public createScreenshot(): any {
        let theme = this.theme;
        this.theme = 'light';
        this.setColors();

        const pos = this.calculateCameraPositionForZero();
        const x = this.getCameraX();

        this.printCamera.position.x = x;
        this.printCamera.position.z = pos.zPos;
        this.printCamera.position.y = pos.yPos;
        this.printCamera.setTarget(new Vector3(x, pos.yPos, 0));

        Tools.CreateScreenshotUsingRenderTarget(
            this.scene.getEngine(),
            this.printCamera,
            { width: this.printWidth, height: this.printHeight },
            (data: any) => {
                var img = document.createElement('img');
                img.src = data;
                this.babylonService.image = img;
                this.babylonService.printScene.complete();
                this.theme = theme;
                this.setColors();
            },
            'image/png',
            8,
            false
        );
    }

    /** Creates the camera for the user and the printCamera for printing */
    protected override createCamera(scene: Scene): void {
        super.createCamera(scene);
        const cameraX = this.getCameraX();

        // Create camera for print
        const printCameraPos = this.calculateCameraPositionForZero();
        this.printCamera = new FreeCamera(
            'camera',
            new Vector3(cameraX, printCameraPos.yPos, printCameraPos.zPos),
            scene
        );
        this.printCamera.setTarget(new Vector3(0, printCameraPos.yPos, 0));
        this.printCamera.fov = Math.PI * this.quarter;

        // Set scaling
        if (this.cameraScale()) {
            this.setCameraScale();
        }
    }

    /**
     * Calculate the distance from the camera to make all elements visible
     *
     * @returns yPos, zPos
     */
    protected override calculateCameraPositionForZero(): { yPos: number; zPos: number } {
        const dimensions = this.getSizeOfScene();

        let zPos;

        zPos = -(dimensions.width / (3 * Math.tan(this.cameraFOV * this.half)));

        let yPos = -(dimensions.height / 2);

        return { yPos, zPos };
    }

    protected override calculateTotalHeight(): number {
        return this.totalHeight;
    }

    /**
     * Returns the size of the scene (width and height and which is bigger).
     *
     * @returns totalHeight, totalWidth, biggerSide
     */
    protected override getSizeOfScene(): { height: number; width: number; biggerSide: number } {
        const height = this.calculateTotalHeight();
        const width = this.calculateSubtreeWidth(this.organisationWithCoordinates);

        const biggerSide = height > width ? height : width;

        return { height, width, biggerSide };
    }

    override handleSpecificSceneActions(): void {
        this.observePointer();
    }

    override getCameraX(): number {
        return 0;
    }

    /**  Create the background plane */
    protected createBackground(scene: Scene): void {
        const size = 100;
        let height = 100;

        this.createBackgroundPlane(size, height, scene);
    }

    protected handleSpecialEventOnCameraMovement(intersection: PickingInfo): void {}

    protected override async createScene(
        engine: Engine,
        canvas: HTMLCanvasElement,
        reset?: boolean
    ): Promise<Scene> {
        // This creates a basic Babylon Scene object (non-mesh)
        var scene = new Scene(engine, {
            useMaterialMeshMap: true,
            useGeometryUniqueIdsMap: true,
        });

        this.createCamera(scene);

        await this.loadFont();

        //Creation of a repeated textured material

        this.createIconMaterials();

        this.createMaterials();

        this.setColors();

        this.createBackground(scene);

        this.generateOrganisations(scene);

        return scene;
    }

    private organisationMaterial: StandardMaterial;
    private departmentMaterial: StandardMaterial;
    private accountMaterial: StandardMaterial;
    private agentMaterial: StandardMaterial;
    private aiAgentMaterial: StandardMaterial;

    private createIconMaterials(): void {
        this.organisationMaterial = this.createIconMaterial('building-office-2', this.scene);
        this.organisationMaterial.freeze();

        this.departmentMaterial = this.createIconMaterial('building-office', this.scene);
        this.departmentMaterial.freeze();

        this.accountMaterial = this.createIconMaterial('user-group', this.scene);
        this.accountMaterial.freeze();

        this.agentMaterial = this.createIconMaterial('user', this.scene);
        this.agentMaterial.freeze();

        this.aiAgentMaterial = this.createIconMaterial('computer-desktop', this.scene);
        this.aiAgentMaterial.freeze();
    }

    private createIconMaterial(iconName: string, scene: Scene): StandardMaterial {
        let material = new StandardMaterial(iconName, scene);
        material.diffuseTexture = new Texture(`assets/icons/svgs/${iconName}.svg`, scene); // new Texture(url, scene);

        // material.diffuseTexture.hasAlpha = false;
        (material.diffuseTexture as any).uScale = 1.0; //Repeat 5 times on the Vertical Axes
        (material.diffuseTexture as any).vScale = 1.0; //Repeat 5 times on the Horizontal Axes

        material.diffuseTexture.hasAlpha = true;
        material.useAlphaFromDiffuseTexture = true;
        material.backFaceCulling = false; // Optional: Ensures that both sides of the plane are rendered
        material.alphaMode = Engine.ALPHA_COMBINE;
        material.transparencyMode = Material.MATERIAL_ALPHABLEND;
        material.needDepthPrePass = false; // Helps with transparency issues

        material.emissiveColor = new Color3(255, 255, 255);

        return material;
    }

    private createImageMaterial(imageName: string, scene: Scene): StandardMaterial {
        let material = new StandardMaterial(imageName, scene);
        material.diffuseTexture = new Texture(imageName, scene); // new Texture(url, scene);

        // material.diffuseTexture.hasAlpha = false;
        (material.diffuseTexture as any).uScale = 1.0; //Repeat 5 times on the Vertical Axes
        (material.diffuseTexture as any).vScale = 1.0; //Repeat 5 times on the Horizontal Axes

        material.diffuseTexture.hasAlpha = true;
        material.useAlphaFromDiffuseTexture = true;
        material.backFaceCulling = false; // Optional: Ensures that both sides of the plane are rendered
        material.alphaMode = Engine.ALPHA_COMBINE;
        material.transparencyMode = Material.MATERIAL_ALPHABLEND;
        material.needDepthPrePass = false; // Helps with transparency issues

        material.emissiveColor = new Color3(255, 255, 255);

        return material;
    }

    // #region Create arrows

    protected createArrows(
        parent: Mesh,
        children: Mesh[],
        childrenLevel: number,
        scene: Scene
    ): void {
        const parentOffset = this.organisationHeight + 0.125;

        // Create Vector3s with the offset
        const modifiedParent = new Vector3(
            parent.position.x,
            parent.position.y - parentOffset,
            parent.position.z
        );

        children.map((child: Mesh, index: number) => {
            if (childrenLevel >= 1) {
                const shaftLength1 = this.yDistance / 2;

                const parentShaft = this.createShaft(shaftLength1, scene);
                parentShaft.position = modifiedParent;
                parentShaft.material = this.arrowMaterial;

                const modifiedChild = new Vector3(
                    child.position.x,
                    child.position.y + this.organisationHeight + 0.125,
                    child.position.z
                );
                const shaftLength2 = this.yDistance / 2;

                const childShaft = this.createShaft(shaftLength2, scene);
                childShaft.position = modifiedChild;
                childShaft.material = this.arrowMaterial;
            }
        });

        if (
            childrenLevel >= 1 &&
            children.length > 1 &&
            !children.some(child => child.id.includes('employee'))
        ) {
            const firstPoint = children[0].position;
            const lastPoint = children[children.length - 1].position;
            const startingPoint = new Vector3(firstPoint.x, firstPoint.y + 0.75, firstPoint.z);
            const endingPoint = new Vector3(lastPoint.x, lastPoint.y + 0.75, lastPoint.z);

            // Calculate the direction and length of the arrow
            const direction = endingPoint.subtract(startingPoint);
            const length = direction.length();

            // Shaft
            const shaftLength = length;
            const shaft = this.createShaft(shaftLength, scene);
            shaft.position = startingPoint.add(
                direction.normalize().scale(shaftLength * this.half)
            );
            shaft.rotation = new Vector3(0, 0, this.rotationNinentyDegrees);
            shaft.material = this.arrowMaterial;
        }
    }

    // #endregion

    // #region Create Organisations

    protected generateOrganisations(scene: Scene): void {
        this.assignXCoordinates(
            this.organisationWithCoordinates,
            0,
            0,
            !!(
                this.organisationWithCoordinates.children &&
                this.organisationWithCoordinates.children.length
            )
        );
        this.totalHeight = this.organisationHeight;
        this.generateOrganisation(
            { x: 0, y: 0, z: this.boxZ },
            this.organisationWithCoordinates,
            0,
            scene
        );
    }

    private getIconMaterial(nodeType: string): StandardMaterial {
        switch (nodeType) {
            case 'root':
            case 'organisation':
                return this.organisationMaterial;
            case 'department':
                return this.departmentMaterial;
            case 'account':
                return this.accountMaterial;
            case 'agent':
                return this.agentMaterial;
            case 'aiagent':
            case 'ai agent':
                return this.aiAgentMaterial;
        }
    }

    protected generateOrganisation(
        pos: Position,
        organisation: OrganisationWithCoordinates,
        level: number,
        scene: Scene
    ): Mesh {
        let orgType: string;
        if (!organisation.metadata && organisation.type) {
            organisation.metadata = {
                name: organisation.type,
            };
        }
        orgType = organisation.metadata?.['name'];
        let objExplorerItem: ObjectExplorerItem;
        if (orgType) {
            objExplorerItem = this.objExplorerService
                .objectExplorerItems()
                .find(o => o.id.toLowerCase() === orgType.toLowerCase());
        }

        // Meshes that should be merged
        const meshes: Mesh[] = [];

        // Create taskBackground
        const taskBackground = SuperEllipsoid.CreateSuperEllipsoid(
            'taskBackground',
            this.organisationResolution,
            this.organisationRoundnessY,
            this.organisationRoundnessX,
            this.organisationWidth,
            this.organisationDepth,
            this.organisationHeight,
            scene
        );
        this.setPosAndMaterial(taskBackground, this.selectionMaterial, {
            x: 0,
            y: 0,
            z: 0,
        });

        var icon = Mesh.CreatePlane('icon', 0.2, scene);
        icon.position.y = 0.3;
        icon.position.z = this.boxZ - 0.1;
        icon.position.x = -0.75;
        icon.material = this.getIconMaterial(orgType?.toLowerCase().replaceAll(' ', '') ?? '');

        meshes.push(taskBackground);
        meshes.push(icon);

        // Create taskPrimaryBackground
        const taskPrimaryBackground = SuperEllipsoid.CreateSuperEllipsoid(
            `${organisation.id}_taskPrimaryBackground`,
            this.organisationResolution,
            this.organisationRoundnessY,
            this.organisationRoundnessX,
            this.organisationWidth + this.borderThickness,
            this.organisationDepth,
            this.organisationHeight + this.borderThickness,
            scene
        );
        this.setPosAndMaterial(taskPrimaryBackground, this.primaryBoxMaterial, {
            x: 0,
            y: 0,
            z: this.borderBackgroundPosZ,
        });
        meshes.push(taskPrimaryBackground);

        // TODO createText for ID
        if (this.showId) {
            const id = this.createText(
                { x: 0, y: 0.2, z: this.textPosZ },
                organisation.id,
                organisation.id,
                scene
            );

            meshes.push(id);

            const title = this.createText(
                { x: 0, y: -0.2, z: this.textPosZ },
                organisation.title,
                organisation.title,
                scene
            );

            meshes.push(title);
        } else {
            const title = this.createText(
                { x: 0, y: 0, z: this.textPosZ },
                organisation.title,
                organisation.title,
                scene
            );

            if (organisation.type) {
                organisation.type = `${objExplorerItem.name}`;
            }

            const type = this.createText(
                { x: 0, y: 0.25, z: this.textPosZ },
                organisation.type,
                organisation.type,
                scene
            );

            let infoTypePropertyToShow;
            if (organisation.type && objExplorerItem?.metadata) {
                infoTypePropertyToShow = objExplorerItem.metadata['infoTypePropertyToShow'];
            }

            function getInfoTypeProperty(
                organisation: OrganisationWithCoordinates,
                infoTypePropertyToShow: string
            ): string {
                if (!infoTypePropertyToShow) {
                    return '';
                }
                if (!organisation.infoTypes) {
                    return '';
                }
                let [id, propertyName] = infoTypePropertyToShow.split('.');
                if (!organisation.infoTypes[id]) {
                    return '';
                }
                return organisation.infoTypes?.[id]?.[propertyName] ?? '';
            }

            if (infoTypePropertyToShow) {
                const infoType = this.createText(
                    { x: 0, y: -0.35, z: this.textPosZ },
                    organisation.type,
                    getInfoTypeProperty(organisation, infoTypePropertyToShow),
                    scene
                );
                meshes.push(infoType);
            }

            meshes.push(title);
            meshes.push(type);
        }

        const mergedMesh = this.createMergedMesh(meshes, pos, organisation.id);
        this.organisationMeshes.push(mergedMesh);

        const children: Mesh[] = [];

        if (organisation.metadata?.['employees']?.length) {
            let tempTotalHeight = (this.organisationHeight * level + 1) * 3;
            this.totalHeight =
                this.totalHeight < tempTotalHeight ? tempTotalHeight : this.totalHeight;

            organisation.metadata?.['employees'].forEach(
                (child: OrganisationWithCoordinates, index: number) => {
                    const newLevel = level + 1;
                    const newPos: Position = {
                        x: child.x,
                        y: child.y,
                        z: this.boxZ,
                    };
                    const childMesh = this.generateEmployee(newPos, child, newLevel, scene);
                    // children.push(childMesh);
                }
            );
        }
        if (organisation.children?.length) {
            let tempTotalHeight = (this.organisationHeight * level + 1) * 3;
            this.totalHeight =
                this.totalHeight < tempTotalHeight ? tempTotalHeight : this.totalHeight;

            organisation.children.forEach((child: OrganisationWithCoordinates, index: number) => {
                const newLevel = level + 1;
                const newPos: Position = {
                    x: child.x,
                    y: child.y,
                    z: this.boxZ,
                };
                const childMesh = this.generateOrganisation(newPos, child, newLevel, scene);
                children.push(childMesh);
            });
        }

        this.createArrows(mergedMesh, children, level + 1, scene);

        const tempLeft = pos.x - this.organisationWidth * 2;
        if (!this.leftMostPoint || tempLeft < this.leftMostPoint) {
            this.leftMostPoint = tempLeft;
        }
        const tempRight = pos.x + this.organisationWidth * 2;
        if (!this.rightMostPoint || tempRight > this.rightMostPoint) {
            this.rightMostPoint = tempRight;
        }

        return mergedMesh;
    }

    getAllChildren(org: OrganisationStructure): OrganisationStructure[] {
        let allChildren: OrganisationStructure[] = [];

        if (org.children && org.children.length > 0) {
            for (const child of org.children) {
                allChildren.push(child);
                allChildren = allChildren.concat(this.getAllChildren(child));
            }
        }

        return allChildren;
    }

    calculateSubtreeWidth(root: OrganisationWithCoordinates): number {
        const width = this.organisationWidth * 2.5;

        let totalWidth = 0;

        if (root.children?.length) {
            root.children.forEach(child => {
                totalWidth += this.calculateSubtreeWidth(child);
            });
            if (root.metadata?.['employees']?.length) {
                totalWidth += width;
            }
        } else {
            totalWidth = width;
        }

        return totalWidth;
    }

    assignXCoordinates(
        root: OrganisationWithCoordinates,
        depth: number,
        x: number,
        hasSibling: boolean
    ): number {
        if (!root) {
            return;
        }

        const width = this.organisationWidth * 2.5;

        // Calculate the total width for the subtree rooted at the current node
        const subtreeWidth = this.calculateSubtreeWidth(root);

        // Assign X-coordinate to the current node based on depth
        if (x === 0 && depth === 0) {
            root.x = x;
        } else if (subtreeWidth && root.children && !hasSibling) {
            root.x = x;
        } else if (subtreeWidth && root.children) {
            root.x = x - width / 2 + subtreeWidth / 2;
        } else {
            root.x = x;
        }

        root.y = -(depth * this.organisationHeight * 3);

        // Distribute the available width equally among the child nodes
        let xOffset;
        if (depth === 0 && root.children?.length > 1) {
            xOffset = root.x - subtreeWidth / 2 + width / 2;
        } else if (depth === 0 && root.children?.length <= 1) {
            xOffset = 0;
        } else if (root.children?.length === 1) {
            xOffset = root.x;
        } else if (root.children?.length) {
            xOffset = root.x - subtreeWidth / 2 + width / 2;
        } else if (root.metadata?.['employees']?.length) {
            xOffset = root.x;
        }

        // Recursively assign X-coordinates to child nodes
        if (root.children) {
            for (const child of root.children) {
                xOffset = this.assignXCoordinates(
                    child,
                    depth + 1,
                    xOffset,
                    !!(root.children && root.children.length > 1)
                );
            }
        }
        if (root.metadata?.['employees']) {
            let _depth = depth + 1;
            for (const child of root.metadata?.['employees']) {
                this.assignXCoordinates(child, _depth, xOffset, false);
                _depth += 0.5;
            }
        }

        if (subtreeWidth) {
            if (!hasSibling && root.children?.length > 2) {
                return x;
            }
            return x + subtreeWidth;
        }
        return x + width;
    }

    // #endregion

    private createCirclePlane(name: string, width: number, depth: number, scene: Scene): Mesh {
        // const width = 0.25; // with 0 radius
        // const depth = 0.25; //with 0 radius
        const radius = 0.5 * Math.min(width, depth);
        const dTheta = Math.PI / 32;
        const shape = [];

        //bottom left corner
        let centerX = -(0.5 * width - radius);
        let centerZ = -(0.5 * depth - radius);
        for (let theta = Math.PI; theta <= 1.5 * Math.PI; theta += dTheta) {
            shape.push(
                new Vector3(
                    centerX + radius * Math.cos(theta),
                    0,
                    centerZ + radius * Math.sin(theta)
                )
            );
        }

        //bottom right corner
        centerX = 0.5 * width - radius;
        for (let theta = 1.5 * Math.PI; theta <= 2 * Math.PI; theta += dTheta) {
            shape.push(
                new Vector3(
                    centerX + radius * Math.cos(theta),
                    0,
                    centerZ + radius * Math.sin(theta)
                )
            );
        }

        //top right corner
        centerZ = 0.5 * depth - radius;
        for (let theta = 0; theta <= 0.5 * Math.PI; theta += dTheta) {
            shape.push(
                new Vector3(
                    centerX + radius * Math.cos(theta),
                    0,
                    centerZ + radius * Math.sin(theta)
                )
            );
        }

        //top left corner
        centerX = -(0.5 * width - radius);
        for (let theta = 0.5 * Math.PI; theta <= Math.PI; theta += dTheta) {
            shape.push(
                new Vector3(
                    centerX + radius * Math.cos(theta),
                    0,
                    centerZ + radius * Math.sin(theta)
                )
            );
        }

        const polygon = MeshBuilder.CreatePolygon(
            name,
            { shape: shape, sideOrientation: Mesh.DOUBLESIDE },
            scene
        );
        polygon.rotation.x = -0.5 * Math.PI;

        return polygon;
    }

    generateEmployee(
        pos: Position,
        employee: OrganisationWithCoordinates,
        level: number,
        scene: Scene
    ): Mesh {
        // Meshes that should be merged
        const meshes: Mesh[] = [];

        // Create taskBackground
        const employeeBackground = SuperEllipsoid.CreateSuperEllipsoid(
            'employeeBackground',
            this.organisationResolution,
            this.organisationRoundnessY,
            this.organisationRoundnessX,
            1,
            this.organisationDepth,
            0.25,
            scene
        );
        this.setPosAndMaterial(employeeBackground, this.selectionMaterial, {
            x: 0,
            y: 0.25,
            z: 0,
        });
        meshes.push(employeeBackground);
        // Create taskBackground
        const employeePrimaryBackground = SuperEllipsoid.CreateSuperEllipsoid(
            'employeePrimaryBackground',
            this.organisationResolution,
            this.organisationRoundnessY,
            this.organisationRoundnessX,
            1 + this.borderThickness,
            this.organisationDepth,
            0.25 + this.borderThickness,
            scene
        );
        this.setPosAndMaterial(employeePrimaryBackground, this.primaryBoxMaterial, {
            x: 0,
            y: 0.25,
            z: this.borderBackgroundPosZ,
        });
        meshes.push(employeePrimaryBackground);

        var user_picture = this.createCirclePlane('user_picture', 0.4, 0.4, scene);
        user_picture.position.y = 0.25;
        user_picture.position.z = this.boxZ - 0.1;
        user_picture.position.x = -0.75;

        user_picture.material = this.createImageMaterial(employee.metadata['picture'], scene);

        meshes.push(user_picture);

        const name = this.createText(
            { x: 0, y: 0.225, z: this.textPosZ },
            employee.title,
            employee.title,
            scene
        );
        meshes.push(name);

        const uuid = uuidv4();
        const mergedMesh = this.createMergedMesh(meshes, pos, `employee_${employee.id}_${uuid}`);

        const tempLeft = pos.x - this.organisationWidth * 2;
        if (!this.leftMostPoint || tempLeft < this.leftMostPoint) {
            this.leftMostPoint = tempLeft;
        }
        const tempRight = pos.x + this.organisationWidth * 2;
        if (!this.rightMostPoint || tempRight > this.rightMostPoint) {
            this.rightMostPoint = tempRight;
        }

        return mergedMesh;
    }
}

export interface OrganisationWithCoordinates extends OrganisationSubType {
    x: number;
    y: number;
    children: OrganisationWithCoordinates[];
}
