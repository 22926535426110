import { CommonModule } from '@angular/common';
import { NgModule, forwardRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatSliderModule } from '@ngx-formly/material/slider';
import { AlertComponent } from 'app/core/alert/alert/alert.component';
import { HasRoleAndPermissionDirective } from 'app/core/auth/roles-and-permissions/directive/has-role-and-permission.directive';
import { ComponentFactoryDirective } from 'app/core/componentFactory/component-factory.directive';
import { ActionBarComponent } from 'app/core/dialogBuilder/action-bar/action-bar.component';
import { ContentLayoutWrapperComponent } from 'app/core/ui/content-layout-wrapper/content-layout-wrapper.component';
import { BpFlowchartHorizontalComponent } from './babylonjs/bp-flowchart/bp-flowchart-horizontal.component';
import { BpFlowchartVerticalComponent } from './babylonjs/bp-flowchart/bp-flowchart-vertical.component';
import { BpOrganisationComponent } from './babylonjs/bp-organisation/bp-organisation/bp-organisation.component';
import { FileObserverComponent } from './babylonjs/file-observer/file-observer.component';
import { FlowchartComponent } from './flowchart/flowchart.component';
import {
    FlowchartHeaderComponent,
    OrganisationHeaderComponent,
} from './reusable-components/babylon-header/babylon-header.component';
import { FlowchartResizerComponent } from './reusable-components/flowchart-resizer/flowchart-resizer.component';
import { FlowchartContainerComponent } from './reusable-components/flowchart/flowchart-container/flowchart-container.component';
import { InspectorComponent } from './reusable-components/inspector/inspector.component';
import { OrganisationContainerComponent } from './reusable-components/organisation/organisation-container/organisation-container.component';
import { BpPrintViewComponent } from './reusable-components/print-views/bp-print-view/bp-print-view.component';
import { OrgPrintViewComponent } from './reusable-components/print-views/org-print-view/org-print-view.component';
import { SpinnerComponent } from './reusable-components/spinner/spinner.component';
import { SvgAreaComponent } from './svgjs/svg-area/svg-area.component';
import { SvjgAreaComponent } from './svgjs/svg-area/svjg-area/svjg-area.component';
import { SvgFlowchartComponent } from './svgjs/svg-flowchart/svg-flowchart.component';
import { SvgObserverComponent } from './svgjs/svg-observer/svg-observer.component';
import { SvgjsComponent } from './svgjs/svgjs/svgjs.component';

@NgModule({
    declarations: [
        FlowchartComponent,
        BpFlowchartVerticalComponent,
        BpFlowchartHorizontalComponent,
        FlowchartContainerComponent,
        FlowchartHeaderComponent,
        OrganisationContainerComponent,
        OrganisationHeaderComponent,
        BpPrintViewComponent,
        OrgPrintViewComponent,
        BpOrganisationComponent,
        SvgjsComponent,
        InspectorComponent,
        FileObserverComponent,
        SvgObserverComponent,
        FlowchartResizerComponent,
        SvgFlowchartComponent,
        SpinnerComponent,
        SvgAreaComponent,
        SvjgAreaComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        // FlowchartDirective,
        // Flowchart2Directive,
        // Flowchart3Directive,
        MatMenuModule,
        MatIconModule,
        ComponentFactoryDirective,
        MatTooltipModule,
        ActionBarComponent,
        HasRoleAndPermissionDirective,
        TranslocoModule,
        MatSliderModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        FormlyModule,
        FormlyMaterialModule,
        FormlyMatSliderModule,
        MatDividerModule,
        AlertComponent,
        FuseAlertComponent,
        forwardRef(() => ContentLayoutWrapperComponent),
    ],
    exports: [
        FlowchartComponent,
        BpFlowchartVerticalComponent,
        BpFlowchartHorizontalComponent,
        FlowchartContainerComponent,
        FlowchartHeaderComponent,
        OrganisationContainerComponent,
        OrganisationHeaderComponent,
        BpPrintViewComponent,
        OrgPrintViewComponent,
        InspectorComponent,
        FileObserverComponent,
        SvgObserverComponent,
        AlertComponent,
        SvgFlowchartComponent,
        SpinnerComponent,
        SvgAreaComponent,
        SvjgAreaComponent,
    ],
})
export class FlowchartModule {}
