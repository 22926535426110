<div *transloco="let t; read: 'flowchart'" class="flex flex-row justify-end gap-2">
    <action-bar
        *ngIf="_config"
        HasRoleAndPermission
        [hasPermissionsAndRoles]="['embpm_rcu']"
        [dropdownMode]="true"
        [actionDescriptors]="_config.actions"
        [executeActionSignal]="_config.executeActionSignal">
        <ng-container menuContent>
            <mat-divider></mat-divider>
            <div *ngIf="showCursorAndSensitivityOptions(); then sensitivityOptions"></div>
            <ng-template #sensitivityOptions>
                <div class="flex flex-col px-4">
                    <span class="text-secondary text-center"> {{ t('cursorMovement') }} </span>
                </div>
                <button
                    mat-menu-item
                    [disabled]="movingType === movingTypeValues().Natural"
                    (click)="changeMovingType()">
                    {{ t(movingTypeValues().Natural) }}
                    <mat-icon
                        *ngIf="movingType === movingTypeValues().Natural"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="movingType !== movingTypeValues().Natural"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                </button>
                <button
                    mat-menu-item
                    [disabled]="movingType === movingTypeValues().Inverted"
                    (click)="changeMovingType()">
                    {{ t(movingTypeValues().Inverted) }}
                    <mat-icon
                        *ngIf="movingType === movingTypeValues().Inverted"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="movingType !== movingTypeValues().Inverted"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                </button>
                <mat-divider></mat-divider>
                <div class="flex flex-col px-4">
                    <span class="text-secondary text-center"> {{ t('sensitivity') }} </span>
                </div>
                <button
                    mat-menu-item
                    [disabled]="sensitivityType === sensitivityTypeValues().Fixed"
                    (click)="changeSensitivityType()">
                    {{ t(sensitivityTypeValues().Fixed) }}
                    <mat-icon
                        *ngIf="sensitivityType === sensitivityTypeValues().Fixed"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="sensitivityType !== sensitivityTypeValues().Fixed"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                </button>
                <button
                    mat-menu-item
                    [disabled]="sensitivityType === sensitivityTypeValues().Varied"
                    (click)="changeSensitivityType()">
                    {{ t(sensitivityTypeValues().Varied) }}
                    <mat-icon
                        *ngIf="sensitivityType === sensitivityTypeValues().Varied"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="sensitivityType !== sensitivityTypeValues().Varied"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                </button>
            </ng-template>
            <div *ngIf="type === 'flowchart'; then flowchartOptions"></div>
            <ng-template #flowchartOptions>
                <!-- #region viewType -->
                <mat-divider *ngIf="showCursorAndSensitivityOptions()"></mat-divider>
                <div class="flex flex-col px-4">
                    <span class="text-secondary text-center"> {{ t('viewType') }} </span>
                </div>
                <button
                    mat-menu-item
                    [disabled]="viewType() === viewTypeValues().SVG"
                    (click)="changeViewType(viewTypeValues().SVG)">
                    <mat-icon
                        *ngIf="viewType() === viewTypeValues().SVG"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="viewType() !== viewTypeValues().SVG"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                    {{ t(viewTypeValues().SVG) }}
                </button>
                <button
                    mat-menu-item
                    [disabled]="viewType() === viewTypeValues().Horizontal"
                    (click)="changeViewType(viewTypeValues().Horizontal)">
                    <mat-icon
                        *ngIf="viewType() === viewTypeValues().Horizontal"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="viewType() !== viewTypeValues().Horizontal"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                    {{ t(viewTypeValues().Horizontal) }}
                </button>
                <button
                    mat-menu-item
                    [disabled]="viewType() === viewTypeValues().Vertical"
                    (click)="changeViewType(viewTypeValues().Vertical)">
                    <mat-icon
                        *ngIf="viewType() === viewTypeValues().Vertical"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="viewType() !== viewTypeValues().Vertical"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                    {{ t(viewTypeValues().Vertical) }}
                </button>
                <!-- #endregion -->
                <!-- #region flowchartType -->
                <mat-divider></mat-divider>
                <div class="flex flex-col px-4">
                    <span class="text-secondary text-center"> {{ t('flowchartType') }} </span>
                </div>
                <button
                    mat-menu-item
                    [disabled]="flowchartType() === flowchartTypeValues().all"
                    (click)="changeFlowchartType(flowchartTypeValues().all)">
                    <mat-icon
                        *ngIf="flowchartType() === flowchartTypeValues().all"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="flowchartType() !== flowchartTypeValues().all"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                    {{ t(flowchartTypeValues().all) }}
                </button>
                <button
                    mat-menu-item
                    [disabled]="flowchartType() === flowchartTypeValues().technical"
                    (click)="changeFlowchartType(flowchartTypeValues().technical)">
                    <mat-icon
                        *ngIf="flowchartType() === flowchartTypeValues().technical"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="flowchartType() !== flowchartTypeValues().technical"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                    {{ t(flowchartTypeValues().technical) }}
                </button>
                <button
                    mat-menu-item
                    [disabled]="flowchartType() === flowchartTypeValues().financial"
                    (click)="changeFlowchartType(flowchartTypeValues().financial)">
                    <mat-icon
                        *ngIf="flowchartType() === flowchartTypeValues().financial"
                        svgIcon="mat_solid:radio_button_checked"></mat-icon>
                    <mat-icon
                        *ngIf="flowchartType() !== flowchartTypeValues().financial"
                        svgIcon="mat_solid:radio_button_unchecked"></mat-icon>
                    {{ t(flowchartTypeValues().financial) }}
                </button>
                <!-- #endregion -->
            </ng-template>
        </ng-container>
    </action-bar>
    <button
        *ngIf="type === 'flowchart'"
        mat-icon-button
        (click)="toggleFullscreen()"
        [matTooltip]="t('fullscreen')">
        <mat-icon *ngIf="!isFullscreen" svgIcon="mat_outline:open_in_full"></mat-icon>
        <mat-icon *ngIf="isFullscreen" svgIcon="mat_outline:close_fullscreen"></mat-icon>
    </button>
</div>
