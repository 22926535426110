import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Signal,
    WritableSignal,
    computed,
    effect,
} from '@angular/core';
import { BusinessProcessTask } from 'app/api';
import { ComponentFactoryConfig } from 'app/core/componentFactory/component-factory.directive';
import { BabylonContextMenuItem } from '../models/babylon-context-menu-item';
import { MovingType, SensitivityType, ViewType } from '../models/moving-type';
import { BabylonChartConfiguration } from '../babylonjs/core/babylon-chart-config.model';

@Component({
    selector: 'flowchart',
    templateUrl: 'flowchart.component.html',
    styleUrls: ['flowchart.component.scss'],
})
export class FlowchartComponent implements OnInit {
    @Input() tasks: Signal<BusinessProcessTask[]>;
    @Input() width?: string = '100';
    @Input() vm?: any;
    @Input() flowchartType: 'flowchart' | 'flowchart2';
    @Input() isPrinting?: boolean = false;
    @Input() cameraScale: WritableSignal<string>;
    @Input() menuItems: Signal<BabylonContextMenuItem[]>;
    @Input() movingType: WritableSignal<MovingType>;
    @Input() viewType: WritableSignal<ViewType>;
    @Input() sensitivityType: WritableSignal<SensitivityType>;
    @Input() coloredTaskIds: Signal<string[]>;
    @Input() config: BabylonChartConfiguration;

    @Input() selectedTaskId?: string;

    compFactored: boolean = false;
    compFactoryConfig: ComponentFactoryConfig;

    @Output() rowClick = new EventEmitter<BusinessProcessTask>();
    @Output() rowDoubleClick = new EventEmitter<BusinessProcessTask>();

    taskIds = computed(() => this.tasks()?.map(bpTask => bpTask.id));

    constructor() {
        effect(
            () => {
                this.tasks();

                if (!this.viewType()) {
                    this.viewType.set(ViewType.Vertical);
                }

                if (
                    !this.taskIds()?.some(id => id === this.selectedTaskId) &&
                    this.flowchartType === 'flowchart' &&
                    !this.isPrinting
                ) {
                    if (this.rowClick && this.tasks() && this.tasks().length) {
                        setTimeout(() => {
                            this.rowClick.emit(this.tasks()[0]);
                            this.selectedTaskId = this.tasks()[0].id;
                        });
                    }
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }
    ngOnInit(): void {
        if (!this.vm) {
            this.vm = {
                taskInit: [
                    {
                        category: 'start',
                        data: {},
                    },
                    {
                        category: 'end',
                        data: {},
                    },
                ],
            };
        }
    }

    onMeshClick(id: string): void {
        const task = this.tasks().find((bpt: BusinessProcessTask) => bpt.id === id);
        this.onRowClick(task);
    }
    onMeshDoubleClick(id: string): void {
        const task = this.tasks().find((bpt: BusinessProcessTask) => bpt.id === id);
        this.rowDoubleClick.emit(task);
    }

    onRowClick(task: BusinessProcessTask): void {
        this.rowClick.emit(task);
        this.selectedTaskId = task.id;
    }

    trackByFn(index: number, item: any): any {
        return item.no;
    }

    viewTypeValue(): typeof ViewType {
        return ViewType;
    }

    setCameraScale(newValue: string): void {
        this.cameraScale.set(newValue);
    }
}
