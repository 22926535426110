import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    Signal,
    ViewChild,
    ViewChildren,
    WritableSignal,
} from '@angular/core';
import { BusinessProcessTask } from 'app/api';
import html2canvas from 'html2canvas';
import { Subject, takeUntil } from 'rxjs';
import { BabylonChartConfiguration } from '../../babylonjs/core/babylon-chart-config.model';
import { BabylonContextMenuItem } from '../../models/babylon-context-menu-item';
import { MovingType, SensitivityType } from '../../models/moving-type';
import { PrintService } from '../../print.service';
import { FuseConfig, FuseConfigService, Scheme } from '@fuse/services/config';

@Component({
    selector: 'lib-svg-flowchart',
    templateUrl: './svg-flowchart.component.html',
    styleUrl: './svg-flowchart.component.scss',
})
export class SvgFlowchartComponent implements OnDestroy {
    protected destroyed$ = new Subject<void>();

    @ViewChild('containerDiv') containerDiv: ElementRef;

    @ViewChild('start') startRef: ElementRef;
    @ViewChild('end') endRef: ElementRef;
    @ViewChildren('task') taskRefs: QueryList<ElementRef>;

    @Input() movingType: WritableSignal<MovingType>;

    /** The scale of the camera */
    @Input() cameraScale: WritableSignal<string>;

    @Input() sensitivityType: WritableSignal<SensitivityType>;

    @Input() config: BabylonChartConfiguration;

    /** An EventEmitter which emits an event if a clickable Mesh is clicked */
    @Output() meshClicked: EventEmitter<string> = new EventEmitter();
    /** An EventEmitter which emits an event if a clickable Mesh is clicked */
    @Output() meshDoubleClicked: EventEmitter<string> = new EventEmitter();
    /** The BusinessProcessTasks that should be rendered */
    @Input() processes: Signal<BusinessProcessTask[]>;

    /** The type of the flowchart */
    @Input() type: 'flowchart' | 'flowchart2';
    /** The id of the selected BusinessProcessTask */
    @Input() selectedId?: string;
    private _selectedId?: string;

    @Input() menuItems: Signal<BabylonContextMenuItem[]>;
    @Input() coloredTaskIds: Signal<string[]> | undefined;

    clickTimeout: any;

    constructor(
        public printService: PrintService,
        protected fuseConfigService: FuseConfigService
    ) {
        this.subscribeToPrint();
        this.printService.reSubscribe.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.subscribeToPrint();
        });
        this.fuseConfigService.config$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((config: FuseConfig) => {
                // Store the config
                this.scheme = config.scheme;
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    onClick(event: MouseEvent, task: BusinessProcessTask): void {
        event.stopPropagation();
        // Clear any existing timer
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
        }

        // Set a new timer
        this.clickTimeout = setTimeout(() => {
            // Handle single click action
            this.meshClicked.emit(task.id);
            this.clickTimeout = null; // Clear the timer
        }, 250); // Adjust the timeout value if needed
    }
    onDoubleClick(event: MouseEvent, task: BusinessProcessTask): void {
        event.stopPropagation();
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        }
        this.meshDoubleClicked.emit(task.id);
    }

    /** Subscribing for print event */
    private subscribeToPrint(): void {
        this.printService.printScene.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this._selectedId = `${this.selectedId}`;
            this.selectedId = undefined;
            setTimeout(() => {
                this.convertDivToImage();
            }, 10);
        });

        this.printService.printDetailedScene.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this._selectedId = `${this.selectedId}`;
            this.selectedId = undefined;
            setTimeout(() => {
                this.convertDivToImages();
            }, 10);
        });
    }

    scheme: 'dark' | 'light' | 'auto' = 'light';
    originalScheme: 'dark' | 'light' | 'auto';
    printing: boolean = false;

    changeScheme() {
        if (this.scheme === 'light') this.scheme = 'dark';
        else this.scheme = 'light';
        this.setScheme(this.scheme);
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void {
        this.fuseConfigService.config = { scheme };
        this.updateConfigInLocalStorage('scheme', scheme);
    }

    /**
     * Sync with local storage
     *
     */
    updateConfigInLocalStorage(key: string, value: any) {
        let config: any = localStorage.getItem('fuseConfig') ?? '{}';
        try {
            config = JSON.parse(config);
            config[key] = value;
            localStorage.setItem('fuseConfig', JSON.stringify(config));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async convertDivToImage() {
        this.printing = true;
        this.prepareSchemeForPrinting();

        const image = await this.printImageOfDiv(this.containerDiv);

        this.printService.image = image;

        this.printService.printScene.complete();

        this.finaliseSchemeAfterPrinting();
    }

    async convertDivToImages(): Promise<void> {
        this.printing = true;
        this.prepareSchemeForPrinting();

        let images = [];

        const start = await this.printImageOfDiv(this.startRef);
        images.push(start);

        await Promise.all(
            this.taskRefs.map(async taskRef => {
                const image = await this.printImageOfDiv(taskRef);
                images.push(image);
            })
        );

        const end = await this.printImageOfDiv(this.endRef);
        images.push(end);

        this.printService.images = images;

        this.printService.printDetailedScene.complete();

        this.finaliseSchemeAfterPrinting();
    }

    private async printImageOfDiv(divRef: ElementRef): Promise<HTMLImageElement> {
        const divElement = divRef.nativeElement;

        const canvas = await html2canvas(divElement);

        const pngData = canvas.toDataURL('image/png');
        const image = new Image();
        image.src = pngData;
        return image;
    }

    private prepareSchemeForPrinting(): void {
        this.originalScheme = this.scheme;
        if (this.scheme === 'dark' || this.scheme === 'auto') {
            this.changeScheme();
        }
    }

    private finaliseSchemeAfterPrinting(): void {
        setTimeout(() => {
            this.selectedId = this._selectedId;
            if (this.originalScheme === 'dark') {
                this.changeScheme();
            }
            this.printing = false;
        }, 200);
    }
}
