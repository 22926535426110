export class PrintViewComponent {
    static openAlert(): void {
        alert(
            'Please ensure that Headers and footers are turned off, and the Background graphics are turned on in the print settings.'
        );
    }

    static getStyles(): string {
        return `
        <style>
            body {
                font-family: 'Inter', sans-serif; 
                margin: 0;
            }
            .printContainer {
                display: flex;
                flex-direction: column;
                padding: 1rem; /* 16px */
                gap: 1rem; /* 16px */
                width: calc(100% - 2rem);
            }

            .emLogo {
                width: 2.5rem; /* 40px */
                height: 2.5rem; /* 40px */
                background-color: #2196F3 !important;
                border-radius: 0.25rem; /* 4px */
                font-size: 1.25rem; /* 20px */
                line-height: 1.75rem; /* 28px */ 
                font-weight: 700;
                color: rgb(255 255 255);
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
            }

            img,
            .commonDescription {
                max-width: 100%;
                height: auto;
                display: block;
            }
            
            .commonDescription {
                margin-left: 1rem;
            }
            img {
                margin: 0 auto;
            }

            h1,
            h2,
            h3,
            p {
                margin: 0;
            }

            .padding-b {
                padding-bottom: 1rem;
            }

            .border-b {
                border-bottom: 1px solid rgba(226, 232, 240, 1);
            }

            .padding-t {
                padding-top: 1rem;
            }

            .border-t {
                border-top: 1px solid rgba(226, 232, 240, 1);
            }

            .my-auto {
                margin-top: auto;
                margin-bottom: auto;
            }

            .bpTitle {
                font-size: 1.5rem;
                margin-top: auto;
                margin-bottom: auto;
            }

            .h1 {
                font-size: 1.25rem; /* 20px */
                line-height: 1.75rem; /* 28px */
                font-weight: normal;
            }
            .h2 {
                font-size: 1.125rem; /* 18px */
                line-height: 1.75rem; /* 28px */
                font-weight: normal;
            }
            .h3 {
                font-size: 0.875rem; /* 14px */
                line-height: 1.25rem; /* 20px */
                font-weight: normal;
                color: #64748b;
            }
            .h4 {
                font-size: 0.875rem; /* 14px */
                font-weight: normal;
                margin: 0;
            }
            
            table {
                box-shadow: none;
                margin-left: 1rem;
            }

            .headerCol {
                width: 125px;
                font-weight: bold;
            }

            .headerRow {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            p {
                white-space: pre-wrap; /* CSS3 */
                word-wrap: break-word; /* IE */
                overflow-wrap: anywhere; /* CSS3 */
            }

            .tasks {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }

            .task {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            @media print {
                .emLogo {
                    background-color: #2196f3 !important;
                    color: rgb(255 255 255) !important;
                    -webkit-print-color-adjust: exact; /* Ensures background color is printed */
                    print-color-adjust: exact; /* Ensures background color is printed */
                }
            }

            // Flowchart CSS

            .fit-height {
                height: fit-content !important;
            }

            .arrow {
                stroke: #000000;
                fill: #000000;
                stroke-width: 0.3;
            }

            .arrow-without-fill {
                stroke: #000000;
                fill: none;
                stroke-width: 0.3;
            }

            .stroke {
                stroke: #2196F3;
            }
            .stroke-warn {
                stroke: rgb(220, 38, 38);
            }

            .text {
                fill: #000000 !important;
                stroke: #000000 !important;
                stroke-width: 0.1;
            }

            .white-text {
                fill: #000000 !important;
                stroke: #000000 !important;
                stroke-width: 0.1;
            }

            .background {
                fill: #f1f5f9;
            }

            .primary-background {
                fill: #2196F3;
            }
            .warn-background {
                fill: rgb(220, 38, 38);
            }
        </style>
    `;
    }

    static printContextInNewWindow(title: string): void {
        const content = document.querySelector('.print-section');
        const newWindow = window.open(
            '',
            '_blank',
            'width=1000,height=800,scrollbars=yes,menubar=no'
        );
        const print = { content, newWindow };

        const styles = this.getStyles();

        print.newWindow.document.write(`
        <html>
            <head>
                <link
                    href="assets/fonts/inter/inter.css"
                    rel="stylesheet">
                ${styles}
                <title>${title}</title>
            </head>
        <body>`);
        print.newWindow.document.write(print.content.innerHTML);
        print.newWindow.document.write('</body></html>');
        print.newWindow.document.close();

        // print.newWindow.print();
    }

    /**
     * Static function which prepares the component to be printed.
     *
     * Opens an alert that warns the user about certain recommended settings for printing.
     *
     * Also opens a new window with the component in it which would be printed. Starts the
     * printing procedure to make sure the user can easily print the document.
     */
    static print(): void {
        throw new Error('print is not implemented!');
    }
}

export interface Print {
    content: Element;
    newWindow: Window;
}
