import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Injector,
    NgZone,
    SimpleChanges,
} from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { BusinessProcessCategory, BusinessProcessTask } from 'app/api';
import { CSG, Mesh, MeshBuilder, PickingInfo, Scene, Vector3 } from 'babylonjs';
import { BabylonBusinessProcessFlowchartComponent } from '../core/babylon-business-process-flowchart.component';
import { PrintService } from '../../print.service';
import { FlowchartLoadingSpinnerService } from '../../reusable-components/flowchart/flowchart-container/flowchart-loading-spinner.service';

@Component({
    selector: 'bp-flowchart-horizontal',
    templateUrl: './bp-flowchart.component.html',
    styleUrls: ['./bp-flowchart.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class BpFlowchartHorizontalComponent extends BabylonBusinessProcessFlowchartComponent {
    // Properties for columns and cells
    protected cellWidth = 10;
    protected cellDepth = 0.1;
    protected borderThickness = 0.025;

    // Overriden properties
    protected override cameraZ = -15;

    protected override printWidth: number = 3840;
    protected override printHeight: number = 2160;

    protected override zeroCameraPoint_offsetZ: number = 2;
    protected override zeroCameraPoint_offsetY: number = 4;

    protected override verticalArrowLengthCorrection = 0.1;
    protected override backgroundHeightMultiplier = 3;

    protected override topMostPoint = 3.5;
    protected override cameraY = -3;

    // Roles in Scene
    protected roles: any = {};

    protected colHeaders: Mesh[] = [];

    // Properties
    protected minNumOfEntries = 2;
    protected verticalArrowLength = 0.25;
    protected headerHeight = 2.5;
    protected headerTextPosCorrection = -0.175;
    protected headerTextSize = 0.25;

    constructor(
        el: ElementRef,
        ngZone: NgZone,
        injector: Injector,
        fuseConfigService: FuseConfigService,
        babylonService: PrintService,
        spinnerService: FlowchartLoadingSpinnerService
    ) {
        super(el, ngZone, injector, fuseConfigService, babylonService, spinnerService);
        this.enableCursorMovement.set(true);
    }

    protected override handleSpecialEventOnCameraMovement(intersection: PickingInfo): void {
        this.colHeaders.forEach(header => {
            if (intersection.pickedPoint.y < 3.5) {
                header.position.y = intersection.pickedPoint.y - 3.5;
            } else {
                header.position.y = 0;
            }
        });
    }

    // Overriden functions

    override ngOnChanges(changes: SimpleChanges): void {
        if (changes['processes']) {
            this.allMeshes = {};
            this.roles = {};
            this.colHeaders = [];
        }

        super.ngOnChanges(changes);
    }

    protected override calculateTotalWidth(): number {
        if (Object.keys(this.roles).length > 1) {
            this.cameraZ = -15;
            return this.cellWidth * Object.keys(this.roles).length;
        }
        this.cameraZ = -8;
        return 8;
    }

    protected override getCameraX(): number {
        return (
            this.cellWidth * Object.keys(this.roles).length * this.half - this.cellWidth * this.half
        );
    }

    protected override createArrow(
        scene: Scene,
        a: Mesh,
        b: Mesh,
        yOffsetOfA: number,
        yOffsetOfB: number
    ): Mesh {
        if (a.position.x === b.position.x) {
            return super.createArrow(scene, a, b, yOffsetOfA, yOffsetOfB);
        }

        // Create Vector3s with the offset
        const modifiedA = new Vector3(a.position.x, a.position.y - yOffsetOfA, a.position.z);
        const modifiedB = new Vector3(a.position.x, b.position.y + yOffsetOfB, b.position.z);

        // Calculate the direction and length of the arrow
        const direction = modifiedB.subtract(modifiedA);
        const length = direction.length();
        let verticalLength3 =
            length > this.minNumOfEntries
                ? this.verticalArrowLength
                : direction.length() * this.half;

        let verticalLength1 = length - verticalLength3;

        modifiedB.x = b.position.x;
        modifiedB.y = modifiedA.y;

        const horizontalLength = modifiedB.subtract(modifiedA).length();

        // Sphere
        const sphere = this.createSphere(scene);

        // Vertical Shaft 1
        const shaftLength1 = verticalLength1 - this.verticalArrowLengthCorrection;
        const shaft1 = this.createShaft(shaftLength1, scene);

        // Horizontal Shaft 1
        const shaftLength2 = horizontalLength;
        const shaft2 = this.createShaft(shaftLength2, scene);

        // Vertical Shaft 2
        const shaftLength3 = verticalLength3 - this.verticalArrowLengthCorrection;
        const shaft3 = this.createShaft(shaftLength3, scene);

        // Tip
        const tip = this.createTip(scene);

        // Set the positions
        const xOffset = (b.position.x - a.position.x) * this.half;

        sphere.position = modifiedA;
        shaft1.position = sphere.position.add(
            direction.normalize().scale(shaftLength1 * this.half + this.sphereRadius * this.half)
        );
        shaft2.position = shaft1.position.add(
            direction.normalize().scale(shaftLength1 * this.half)
        );
        shaft2.position.x += xOffset;
        shaft2.rotation = new Vector3(0, 0, this.rotationNinentyDegrees);
        shaft3.position = shaft2.position.add(
            direction.normalize().scale(shaftLength3 * this.half)
        );
        shaft3.position.x += xOffset;
        tip.position = shaft3.position.add(
            direction.normalize().scale(shaftLength3 * this.half + this.tipLength * this.half)
        );

        // Set the materials
        sphere.material = this.arrowMaterial;
        shaft1.material = this.arrowMaterial;
        shaft2.material = this.arrowMaterial;
        shaft3.material = this.arrowMaterial;
        tip.material = this.arrowMaterial;

        const mergedArrow = this.createMergedMesh(
            [sphere, shaft1, shaft2, shaft3, tip],
            { x: 0, y: 0, z: 0 },
            'connectionArrow'
        );
        return mergedArrow;
    }

    override createBackground(scene: Scene): void {
        // Extract all responsible strings from the list
        let responsibleStrings = this.processes.map(item => item.data.action.responsible);

        // Remove duplicates using a Set
        let rolesArray = Array.from(new Set(responsibleStrings));

        rolesArray = rolesArray.filter(role => role !== '');

        rolesArray.map((role: string, index: number) => {
            this.roles[role] = index * this.cellWidth;
        });

        const size = rolesArray.length * this.cellWidth * this.backgroundWidthMultiplier;
        const height = this.calculateTotalHeight() * this.backgroundHeightMultiplier;
        const x = (Object.keys(this.roles).length - 1) * (this.cellWidth * this.half);
        const y =
            -((height / this.backgroundHeightMultiplier) * this.half) + this.entryHeight + 1.375;

        // Create the background plane
        this.createBackgroundPlane(size, height, scene, x, y);

        this.createColumns(scene);

        this.camera.position.x = this.getCameraX();
    }

    override async generateProcesses(scene: Scene): Promise<void> {
        const listOfProcesses: Mesh[] = [];

        // Starting point
        const entry = this.createEndpoint(this.startingPointPos, 'S', scene);
        listOfProcesses.push(entry);

        // Create the processes
        let y = 0;
        let x = 0;

        for (let index = 0; index < this.processes.length; index++) {
            const bpTask: BusinessProcessTask = this.processes[index];
            x = this.roles[bpTask.data.action.responsible];

            if (!x && x !== 0) {
                x = index > 0 ? this.roles[this.processes[index - 1].data.action.responsible] : 0;
            }

            if (bpTask.category === BusinessProcessCategory.Process) {
                const asd = await this.createProcess(bpTask.id, { x, y, z: 0 }, bpTask, scene);
                listOfProcesses.push(asd);
            } else {
                listOfProcesses.push(
                    this.createDecision(
                        bpTask.id,
                        { x, y: y + this.decisionHeight * this.half, z: 0 },
                        bpTask,
                        scene
                    )
                );
            }
            if (listOfProcesses.length > 1) {
                let yOffsetOfA: number;
                if (listOfProcesses.length === 2) {
                    yOffsetOfA = this.entryDiameter - 0.1;
                } else {
                    yOffsetOfA =
                        this.processes[index - 1].category === BusinessProcessCategory.Process
                            ? this.processHeight
                            : this.decisionHeight * this.half;
                }

                let yOffsetOfB: number =
                    bpTask.category === BusinessProcessCategory.Process
                        ? this.processHeight
                        : this.decisionHeight * this.half;

                const connectionArrow = this.createArrow(
                    scene,
                    listOfProcesses[index],
                    listOfProcesses[index + 1],
                    yOffsetOfA,
                    yOffsetOfB
                );
                if (index > 0) {
                    this.allMeshes[this.processes[index - 1].id].arrowToNext = connectionArrow;
                    this.allMeshes[this.processes[index - 1].id].index = index - 1;
                }
            }

            y += this.calculateNextY(bpTask, index);
        }

        // Ending point
        const endpoint = this.createEndpoint({ x, y, z: 0 }, 'E', scene);

        const yOffsetOfA =
            this.processes[this.processes.length - 1].category === BusinessProcessCategory.Process
                ? this.processHeight
                : this.decisionHeight * this.half;
        const connectionArrow = this.createArrow(
            scene,
            listOfProcesses[listOfProcesses.length - 1],
            endpoint,
            yOffsetOfA,
            this.entryDiameter * this.half
        );

        this.allMeshes[this.processes[this.processes.length - 1].id].arrowToNext = connectionArrow;
        this.allMeshes[this.processes[this.processes.length - 1].id].index =
            this.processes.length - 1;
        // TODO
        this.allMeshes['endpoint'] = {
            centralMesh: endpoint,
            arrowsForEntries: [],
            entries: [],
            id: 'endpoint',
            index: Object.keys(this.allMeshes).length,
            isCursorOverMesh: false,
            position: { x: 0, y: y - 0.6, z: 0 },
            arrowToNext: undefined,
            bpTask: {},
            centerPosition: new Vector3(0, y - 0.6, 0),
            totalHeight: 1,
        };
    }

    // Special functions

    /**
     * Create a cell with the given properties
     *
     * @param cellWidth
     * @param cellHeight
     * @param cellDepth
     * @param borderThickness
     * @param scene
     * @returns Mesh
     */
    protected createCell(
        cellWidth: number,
        cellHeight: number,
        cellDepth: number,
        borderThickness: number,
        scene: Scene
    ): Mesh {
        const cell1 = MeshBuilder.CreateBox(
            'cell1',
            { width: cellWidth, height: cellHeight, depth: cellDepth + 2 * borderThickness },
            scene
        );
        cell1.position.x = -cellWidth * this.half;
        cell1.material = this.backgroundBoxMaterial;

        const border1 = MeshBuilder.CreateBox(
            'border1',
            {
                width: cellWidth + 2 * borderThickness,
                height: cellHeight + 2 * borderThickness,
                depth: cellDepth,
            },
            scene
        );
        border1.position.x = -cellWidth * this.half;
        border1.material = this.primaryBoxMaterial;

        // Use boolean operation to subtract the inner box from the border
        const subtraction1 = CSG.FromMesh(border1).subtract(CSG.FromMesh(cell1));
        border1.dispose(); // Dispose the original border
        cell1.dispose();
        const result1 = subtraction1.toMesh('result1', this.primaryBoxMaterial, scene);
        return result1;
    }

    /**
     * Creates the columns regarding the number of roles in the scene.
     *
     * @param scene
     */
    protected createColumns(scene: Scene): void {
        // Create three box meshes for the table cells
        const cellHeight = this.calculateTotalHeight() + 1;

        let x = 0;
        let y = -(cellHeight * this.half);

        this.leftMostPoint = x - this.cellWidth / 2 - 1;
        this.rightMostPoint =
            this.leftMostPoint + Object.keys(this.roles).length * this.cellWidth + 2;

        Object.keys(this.roles).forEach((role: string, index: number) => {
            x = this.roles[role];

            const a = new Vector3(x, this.headerHeight, this.boxZ);
            const b = new Vector3(x, y, this.boxZ);
            const direction = b.subtract(a);

            // Column Header
            const cellHeader = this.createCell(
                this.cellWidth,
                1,
                this.cellDepth,
                this.borderThickness,
                scene
            );
            cellHeader.position = new Vector3(x, this.headerHeight, this.boxZ);

            const cellBackground = MeshBuilder.CreatePlane(
                'not_clickable_plane',
                {
                    size: this.cellWidth,
                    height: this.headerHeight / 2,
                },
                scene
            );

            cellBackground.position = new Vector3(x, this.headerHeight, this.boxZ - 0.1);
            cellBackground.material = this.primaryBoxMaterial;

            const cellHeaderText = this.createText(
                { x, y: this.headerHeight + this.headerTextPosCorrection, z: this.boxZ - 0.1 },
                role,
                role,
                scene,
                this.headerTextSize
            );
            const colHeader = this.createMergedMesh(
                [cellHeader, cellHeaderText, cellBackground],
                { x: 0, y: 0, z: 0 },
                'colHeader'
            );
            this.colHeaders.push(colHeader);

            // Column Content
            const cell_content = this.createCell(
                this.cellWidth,
                cellHeight,
                this.cellDepth,
                this.borderThickness,
                scene
            );
            cell_content.position = cellHeader.position.add(
                direction.normalize().scale(this.headerHeight * 0.2 + cellHeight * this.half)
            );
        });
    }
}
