<div
    *transloco="let t; read: 'flowchart'"
    class="resizerContainer"
    (mouseenter)="onHover = true"
    (mouseleave)="onHover = false">
    <mat-slider *ngIf="onHover" [min]="minScale" [max]="maxScale" step="5">
        <input #slider matSliderThumb [value]="scaleValue()" (input)="modifyScale(slider.value)" />
    </mat-slider>
    <button
        class="my-auto"
        *ngIf="onHover"
        mat-icon-button
        (click)="fitScreen()"
        [disabled]="scaleValue() === 0"
        [matTooltip]="t('fit')">
        <mat-icon class="text-current" svgIcon="mat_solid:fit_screen"></mat-icon>
    </button>
    <button
        class="my-auto"
        mat-icon-button
        (click)="decrease()"
        [disabled]="!decreasable"
        [matTooltip]="t('decrease')">
        <mat-icon class="text-current" svgIcon="mat_solid:remove"></mat-icon>
    </button>
    <span class="my-auto"> {{ scaleValuePresented() }} </span>
    <button
        class="my-auto"
        mat-icon-button
        (click)="increase()"
        [disabled]="!increasable"
        [matTooltip]="t('increase')">
        <mat-icon class="text-current" svgIcon="mat_solid:add"></mat-icon>
    </button>
</div>
