<div
    *ngIf="processes && processes() && processes().length"
    class="svgHeight w-full overflow-auto flex flex-col gap-0">
    <div #containerDiv class="containerDiv flex flex-col gap-0">
        <div #start [ngStyle]="{ width: cameraScale() + '%' }" class="mx-auto px-2">
            <lib-svgjs [isStartPoint]="true"></lib-svgjs>
        </div>
        <div
            *ngFor="let task of processes()"
            #task
            [ngStyle]="{ width: cameraScale() + '%' }"
            [ngClass]="selectedId === task.id ? 'selectionBackground' : ''"
            class="mx-auto px-8 cursor-pointer">
            <lib-svgjs
                [task]="task"
                (click)="onClick($event, task)"
                (dblclick)="onDoubleClick($event, task)"></lib-svgjs>
        </div>
        <div #end [ngStyle]="{ width: cameraScale() + '%' }" class="mx-auto px-2">
            <lib-svgjs [isEndPoint]="true"></lib-svgjs>
        </div>
    </div>

    <image *ngIf="printService.image" [src]="printService.image.src"></image>
</div>
<div
    *ngIf="printing"
    class="showPrintPreparationView flex flex-col justify-center text-center"
    [ngClass]="originalScheme + 'Background'">
    <h1 [ngClass]="originalScheme + 'FontColor'"> Preparing print view... </h1>
</div>
