import { Component, Input, Signal, ViewChild, WritableSignal, effect, signal } from '@angular/core';
import {
    BusinessProcessCategory,
    BusinessProcessTask,
    BusinessProcessTaskActionCategory,
} from 'app/api';

import { MatDrawer } from '@angular/material/sidenav';
import { TypeOfProcess } from '@em4cloud/process-tree';
import { BabylonContextMenuItem, FlowchartType } from '../../../../public-api';
import { BabylonChartConfiguration } from '../../../babylonjs/core/babylon-chart-config.model';
import { MovingType, SensitivityType, ViewType } from '../../../models/moving-type';
import createDeepCopy from '../../../babylonjs/core/createDeepCopy';
import { FlowchartLoadingSpinnerService } from './flowchart-loading-spinner.service';
import { DependencyService } from '../../../svgjs/svg-flowchart/dependency.service';

@Component({
    selector: 'flowchart-container',
    templateUrl: './flowchart-container.component.html',
    styleUrls: ['./flowchart-container.component.scss'],
})
export class FlowchartContainerComponent {
    @Input() config: FlowchartContainerConfig;
    @Input() menuItems: Signal<BabylonContextMenuItem[]>;
    @Input() coloredTaskIds: Signal<string[]>;
    @Input() chartConfig: BabylonChartConfiguration;
    @Input() editMode?: Signal<boolean>;
    @Input() modifyTasks?: (tasks: BusinessProcessTask[]) => BusinessProcessTask[];

    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    drawerMode: 'side' | 'over';
    TypeOfProcess: TypeOfProcess = TypeOfProcess.Task;

    static selectTaskCode: string = 'select_task_';
    static taskDoubleClickedCode: string = 'task_doubleClicked_';

    selectedWidth: string = '100';

    cameraScale: WritableSignal<string> = signal(null);
    movingType: WritableSignal<MovingType> = signal(null);
    viewType: WritableSignal<ViewType> = signal(null);
    sensitivityType: WritableSignal<SensitivityType> = signal(null);
    flowchartType: WritableSignal<FlowchartType> = signal(null);

    /**
     * The tasks of the BusinessProcess
     */
    tasks: WritableSignal<BusinessProcessTask[]> = signal(null);

    selectedTaskId: string;

    constructor(
        public spinnerService: FlowchartLoadingSpinnerService,
        private dependencyService: DependencyService
    ) {
        this.cameraScale.set(this.selectedWidth);

        const movingType = localStorage.getItem('movingType');
        if (!movingType) {
            localStorage.setItem('movingType', MovingType.Natural);
        }
        this.movingType.set(MovingType[movingType]);

        const sensitivityType = localStorage.getItem('sensitivityType');
        if (!movingType) {
            localStorage.setItem('sensitivityType', SensitivityType.Varied);
        }
        this.sensitivityType.set(SensitivityType[sensitivityType]);

        effect(() => {
            const id = this.config?.service.selectedId();
            this.selectedTaskId = id;
        });

        effect(
            () => {
                const tasks: BusinessProcessTask[] = this.config?.service.model()?.tasks;
                const flowchartType = this.flowchartType();
                this.prepareTasks(tasks, flowchartType);
            },
            {
                allowSignalWrites: true,
            }
        );

        effect(
            () => {
                const code = this.config.executeActionSignal();

                if (code && code.includes('resize')) {
                    const resizeValue = code.split('_')[1];
                    this.changeWidth(resizeValue);
                } else if (code && code.includes('movingType')) {
                    const movingType = code.split('_')[1];
                    this.changeMovingType(MovingType[movingType]);
                } else if (code && code.includes('viewType')) {
                    const viewType = code.split('_')[1];
                    this.viewType.set(ViewType[viewType]);
                } else if (code && code.includes('flowchartType')) {
                    const flowchartType = code.split('_')[1];
                    this.flowchartType.set(FlowchartType[flowchartType]);
                } else if (code && code.includes('sensitivityType')) {
                    const sensitivityType = code.split('_')[1];
                    this.changeSensitivityType(SensitivityType[sensitivityType]);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    private prepareTasks(tasks: BusinessProcessTask[], flowchartType: FlowchartType): void {
        let copiedTasks: BusinessProcessTask[];
        if (tasks?.length) {
            this.spinnerService.showLoadingSpinner.set(true);
            copiedTasks = tasks.map(t => createDeepCopy(t));
            copiedTasks.map(t => (t.data.text1 = ''));
            copiedTasks.map(t => (t.data.text2 = ''));

            if (typeof this.modifyTasks !== 'undefined' && copiedTasks?.length) {
                copiedTasks = this.modifyTasks(copiedTasks);
            }

            // Setting indexes for dependencies
            copiedTasks = copiedTasks.map((t: BusinessProcessTask) => {
                if (t.data.action.dependencies?.length) {
                    t.data.action.dependencies = t.data.action.dependencies.map((d: any) => {
                        const taskId = d.id;
                        const index = copiedTasks.findIndex(t => t.id === taskId);
                        d.index = index;

                        return d;
                    });
                }

                return t;
            });

            /**
             * Filter entries by flowchart type
             * For all nothing should be filtered out
             * For technical fiDocument(9) should be filtered out
             * For financial ... should be filtered out
             */
            copiedTasks = copiedTasks.map(t => {
                if (flowchartType === FlowchartType.technical) {
                    t.data.input = t.data.input.filter(input => input.category !== 9);
                    t.data.output = t.data.output.filter(input => input.category !== 9);
                } else if (flowchartType === FlowchartType.financial) {
                    t.data.input = t.data.input.filter(input => input.category === 9);
                    t.data.output = t.data.output.filter(input => input.category === 9);
                }
                return t;
            });

            this.dependencyService.tasks = copiedTasks;

            copiedTasks = this.config.service.replaceSystemIdWithSystemTitle(copiedTasks);
        } else {
            this.onRowClicked(undefined);
        }

        this.tasks.set(copiedTasks && copiedTasks.length ? [...copiedTasks] : []);
    }

    changeFlowchartType(newType: string): void {
        const tasks = this.tasks();

        tasks.forEach(task => {});
    }

    changeMovingType(movingType: MovingType): void {
        localStorage.setItem('movingType', movingType);
        this.movingType.set(movingType);
    }

    changeSensitivityType(sensitivityType: SensitivityType): void {
        localStorage.setItem('sensitivityType', sensitivityType);
        this.sensitivityType.set(sensitivityType);
    }

    /**
     * Changes the selectedWidth property with the given one
     * @param width
     */
    changeWidth(width: string): void {
        this.selectedWidth = width;
        this.cameraScale.set(this.selectedWidth);
    }

    /**
     * Sets a new value for executeActionSignal with: select_task_${task.id}
     * @param task The selected task
     */
    onRowClicked(task: BusinessProcessTask | null): void {
        this.config.executeActionSignal.set(
            `${FlowchartContainerComponent.selectTaskCode}${task ? task.id : null}`
        );
    }
    /**
     * Sets a new value for executeActionSignal with: select_task_${task.id}
     * @param task The selected task
     */
    onRowDoubleClicked(task: BusinessProcessTask): void {
        this.config.executeActionSignal.set(
            `${FlowchartContainerComponent.taskDoubleClickedCode}${task.id}`
        );
    }
}

/**
 * Descriptor for the flowchart width buttons
 */
export interface WidthButton {
    label: string;
    value: string;
}

/**
 * The configuration of the FlowchartContainer.
 * The FlowchartContainer can have two flowchart types, either 'flowchart' or 'flowchart2'.
 */
export interface FlowchartContainerConfig {
    flowchartType: 'flowchart' | 'flowchart2';
    executeActionSignal?: WritableSignal<string>;
    service: any;
    isPrinting?: boolean;
}
