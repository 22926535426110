import { HttpClient } from '@angular/common/http';
import {
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    InputSignal,
    Output,
    ViewChild,
    effect,
    input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FuseConfigService } from '@fuse/services/config';
import { SVG, Svg } from '@svgdotjs/svg.js';
import { Area } from 'app/api';
import { Observable, lastValueFrom } from 'rxjs';
import { FlowchartLoadingSpinnerService } from '../../../reusable-components/flowchart/flowchart-container/flowchart-loading-spinner.service';
import properties from '../../svgjs/svgjs.properties';
import { SvgjsService } from '../../svgjs/svgjs.service';

@Component({
    selector: 'lib-svjg-area',
    templateUrl: './svjg-area.component.html',
    styleUrl: './svjg-area.component.scss',
})
export class SvjgAreaComponent {
    @ViewChild('svgContainer') svgContainer!: ElementRef;
    area: InputSignal<Area> = input({});
    type: InputSignal<'area' | 'system' | 'process'> = input('area');
    @Output() onDoubleClicked: EventEmitter<any> = new EventEmitter();

    private svg: Svg;

    protected theme: 'light' | 'dark';

    constructor(
        private service: SvgjsService,
        private http: HttpClient,
        private spinnerService: FlowchartLoadingSpinnerService,
        protected fuseConfigService: FuseConfigService,
        private _destroy: DestroyRef
    ) {
        this.fuseConfigService.config$
            .pipe(takeUntilDestroyed(this._destroy))
            .subscribe((fuseConfig: any) => {
                const newTheme = fuseConfig['scheme'];
                if (this.theme !== newTheme) {
                    this.theme = newTheme;
                    this.setUpSvgScene();
                }
            });

        effect(
            () => {
                if (this.area()) {
                    this.setUpSvgScene();
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    async setUpSvgScene(): Promise<void> {
        if (this.svg) {
            this.svg.clear();
        } else if (!this.svg && this.svgContainer) {
            this.svg = SVG().addTo(this.svgContainer.nativeElement);
        } else {
            return;
        }

        let children = this.countTotalChildrenDeep(this.area());
        let svgName: string;
        if (this.type() === 'process') {
            let status: 'active' | 'inactive' | 'development';
            switch ((this.area() as any).status) {
                case 'A':
                    status = 'active';
                    break;
                case 'I':
                    status = 'inactive';
                    break;
                case 'D':
                default:
                    status = 'development';
                    break;
            }

            svgName = `area_process_${status}_${this.theme}`;
        } else {
            if (!this.area().id) {
                return;
            }
            svgName =
                children > 1
                    ? `${this.type()}_${children}_${this.theme}`
                    : `${this.type()}_${this.theme}`;
        }
        await this.fetchAndLoadMainSVG(svgName, this.type());

        this.adjustViewBoxToFitContent();

        this.spinnerService.showLoadingSpinner.set(false);
    }

    fetchSvg(svgName: string): Observable<string> {
        return this.http.get(`/assets/svgs/${svgName}.svg`, { responseType: 'text' });
    }

    async fetchAndLoadMainSVG(svgName: string, type: string): Promise<void> {
        if (!this.service.main.get(svgName)) {
            const svg = await lastValueFrom(this.fetchSvg(svgName));
            this.service.main.set(svgName, { svg, type });
        }
        this.modifyMainSVG(svgName, this.service.main.get(svgName).svg);
    }

    private modifyMainSVG(svgName: string, svgContent: string) {
        let mainProperties: any =
            this.type() === 'process' ? properties.area_process : properties.area;
        let viewBox: any = properties.container.viewBox;

        svgContent = this.replacePlaceholdersWithContents(
            svgName,
            svgContent,
            mainProperties.content,
            this.area()
        );

        const main = this.svg.svg(svgContent);
        const children = this.countTotalChildrenDeep(this.area());
        main.viewbox(0, 0, 200 * children, 100);

        this.setPositionAndSizeToSvg(0, -139, mainProperties.width, mainProperties.height);
    }

    private countTotalChildrenDeep(area: Area): number {
        let total = 0;
        if (area.children?.length) {
            area.children.forEach(child => {
                total += this.countTotalChildrenDeep(child);
            });
            return total;
        }
        return total + 1;
    }

    private replacePlaceholdersWithContents(
        svgName: string,
        svgContent: string,
        contents: { placeholder: string; value: string }[],
        data: any
    ): string {
        contents.forEach(content => {
            // Extract value from the data using the keys
            const keys = content.value.split('.');
            let value = data;
            keys.forEach(key => {
                value = value[key];
            });

            // Split value into two lines if it exceeds 10 characters
            if (value?.length > 10) {
                const breakpoint = value.lastIndexOf(' ', 20);
                const firstLine = value.slice(0, breakpoint);
                const secondLine = value.slice(breakpoint + 1);

                const splittedSvgName = svgName.split('_');
                const x = Number.parseInt(splittedSvgName[1]) > 1 ? '50%' : '50%';
                // Use <tspan> with x="50%" to center-align the second line
                value = `<tspan x="${x}" dy="-0.6em">${firstLine}</tspan><tspan x="${x}" dy="1.2em">${secondLine}</tspan>`;
            }

            // Replace the placeholder in the SVG content
            const placeholderRegex = new RegExp(content.placeholder, 'g');
            svgContent = svgContent.replace(placeholderRegex, value);
        });

        return svgContent;
    }

    private setPositionAndSizeToSvg(x: number, y: number, width: number, height: number): void {
        const length = this.svg.children().length;
        this.svg.children()[length - 1].width(`${width}%`);
        height = 100;
        this.svg.children()[length - 1].height(`${height}mm`);
        this.svg.children()[length - 1].move(`${x}px`, `${y}px`);
    }

    private adjustViewBoxToFitContent(): void {
        const children = this.countTotalChildrenDeep(this.area());
        this.svg.viewbox(0, 0, 200 * children, 100);
    }

    onDoubleClick($event: any): void {
        $event.stopPropagation();
        this.onDoubleClicked.emit(this.area());
    }
}
