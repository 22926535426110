import {
    Component,
    EventEmitter,
    Input,
    Output,
    WritableSignal,
    computed,
    signal,
} from '@angular/core';

@Component({
    selector: 'lib-flowchart-resizer',
    templateUrl: './flowchart-resizer.component.html',
    styleUrl: './flowchart-resizer.component.scss',
})
export class FlowchartResizerComponent {
    @Input() maxScale: number = 200;
    @Input() minScale: number = 0;
    @Output() updateCameraScale: EventEmitter<string> = new EventEmitter();

    onHover: boolean = false;
    decreasable: boolean = true;
    increasable: boolean = true;

    scaleValue: WritableSignal<number> = signal(100);
    scaleValuePresented = computed(() => {
        return this.scaleValue() === 0 ? 'Fit' : `${this.scaleValue()}%`;
    });

    constructor() {
        this.modifyScale(100);
    }

    increase(): void {
        let newValue = this.scaleValue() + 10;
        this.modifyScale(newValue);
    }
    decrease(): void {
        let newValue = this.scaleValue() - 10;
        this.modifyScale(newValue);
    }

    fitScreen(): void {
        this.modifyScale(0);
    }

    modifyScale(newValue: number): void {
        if (newValue <= 0) {
            newValue = this.minScale;
            this.decreasable = false;
            this.increasable = true;
        } else if (newValue > this.maxScale) {
            newValue = this.maxScale;
            this.decreasable = true;
            this.increasable = false;
        } else {
            this.decreasable = true;
            this.increasable = true;
        }

        this.scaleValue.set(+newValue);
        this.updateCameraScale.emit(`${newValue}`);
    }
}
