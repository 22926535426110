function createDeepCopy(original: any): any {
    let copy: any;

    if (Array.isArray(original)) {
        copy = original.map((item: any) => createDeepCopy(item));
    } else if (typeof original === 'object' && original !== null) {
        copy = { ...original };

        Object.keys(original).forEach(key => {
            if (typeof original[key] === 'object') {
                copy[key] = createDeepCopy(original[key]);
            }
        });
    } else {
        copy = original;
    }

    return copy;
}

export default createDeepCopy;
