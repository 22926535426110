import { Injectable } from '@angular/core';
import { SVGDetails } from './svg-details.model';

@Injectable({
    providedIn: 'root',
})
export class SvgjsService {
    main: Map<string, SVGDetails> = new Map();

    constructor() {}
}
