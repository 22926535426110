<div *transloco="let t; read: 'flowchart'" class="taskProcessChartContainer">
    <!-- TODO then svg; else babylon -->
    <spinner></spinner>
    <div *ngIf="editMode && editMode() && tasks()?.length === 1; then babylon; else babylon"></div>
    <ng-template #babylon>
        <flowchart
            [ngClass]="tasks() && tasks().length ? '' : 'hidden'"
            class="h-full"
            [width]="selectedWidth"
            [tasks]="tasks"
            [flowchartType]="config.flowchartType"
            [isPrinting]="config.isPrinting"
            (rowClick)="onRowClicked($event)"
            (rowDoubleClick)="onRowDoubleClicked($event)"
            [cameraScale]="cameraScale"
            [movingType]="movingType"
            [viewType]="viewType"
            [sensitivityType]="sensitivityType"
            [coloredTaskIds]="coloredTaskIds"
            [config]="chartConfig"
            [selectedTaskId]="selectedTaskId"
            [menuItems]="menuItems"></flowchart>
    </ng-template>
    <ng-template #svg>
        <lib-svgjs [tasks]="tasks"></lib-svgjs>
    </ng-template>
    @if(!tasks() || (tasks() && !tasks().length)) {
    <fuse-alert
        [appearance]="'soft'"
        [type]="'basic'"
        [showIcon]="false"
        class="text-center block p-2">
        {{ t('noData') }}
    </fuse-alert>
    }
</div>
