export const languages = [
    {
        id: 'en',
        label: 'english',
    },
    {
        id: 'de',
        label: 'german',
    },
    {
        id: 'fr',
        label: 'french',
    },
    {
        id: 'es',
        label: 'spanish',
    },
    {
        id: 'pt-BR',
        label: 'brazilian',
    },
    {
        id: 'pl',
        label: 'polish',
    },
    {
        id: 'ru',
        label: 'russian',
    },
];
