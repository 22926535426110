import { Injectable, Signal, WritableSignal, computed, effect, signal } from '@angular/core';
import { BusinessProcessTask } from 'app/api';

@Injectable({
    providedIn: 'root',
})
export class DependencyService {
    private _tasks: WritableSignal<BusinessProcessTask[]> = signal([]);
    public _ongoingIndirectDependencies: any[] = [];

    public get tasks(): Signal<BusinessProcessTask[]> {
        return this._tasks.asReadonly();
    }

    public set tasks(tasks: BusinessProcessTask[]) {
        this._tasks.set(tasks);
    }

    private _dependencies: WritableSignal<any> = signal({});

    constructor() {
        effect(
            () => {
                const dependencies: Record<string, any[]> = {};
                for (const task of this.tasks()) {
                    const { action } = task.data;
                    if (action.dependencies) {
                        dependencies[task.id] = action.dependencies;
                    }
                }
                this._dependencies.set(dependencies);

                for (const task of this.tasks()) {
                    this.hasIndirectDependent(task);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    /**
     * Finds a dependent task of the given type for the specified task.
     *
     * @param task The task to check dependencies for.
     * @param type The type of dependency to search for: 'direct' or 'indirect'.
     * @returns The dependent task if found, otherwise null.
     */
    private findDependentByType(
        task: BusinessProcessTask,
        type: 'direct' | 'indirect'
    ): any | null {
        const index = this.tasks().findIndex(t => t.id === task.id);
        const dependencies = this._dependencies();

        for (const key in dependencies) {
            const dependency = dependencies[key].find(d => d.id === task.id);
            const indexOfDependent = this.tasks().findIndex(t => t.id === key);

            if (
                dependency &&
                ((type === 'direct' && indexOfDependent - 1 === index) ||
                    (type === 'indirect' && indexOfDependent - 1 > index))
            ) {
                return dependency;
            }
        }

        return null; // Handle last task
    }

    /**
     * Checks if the given task has any direct dependencies.
     *
     * A direct dependency is defined as a dependency where the dependent task is directly after the given task.
     * If the given task is the last task in the list, it is always considered to have a direct dependency.
     *
     * @param task The task to check
     * @returns true if the task has a direct dependency, false otherwise
     */
    public hasDirectDependent(task: BusinessProcessTask): boolean {
        return (
            !!this.findDependentByType(task, 'direct') ||
            this.tasks().findIndex(t => t.id === task.id) === this.tasks().length - 1
        );
    }

    /**
     * Checks if the given task has any indirect dependencies.
     *
     * If the task has an indirect dependency, the method adds the dependency to the
     * _ongoingIndirectDependencies array and returns true. Otherwise, it returns false.
     *
     * @param task The task for which to check if there are any indirect dependencies.
     * @returns True if the task has any indirect dependencies, false otherwise.
     */
    public hasIndirectDependent(task: BusinessProcessTask): boolean {
        const dependency = this.findDependentByType(task, 'indirect');
        if (dependency && !this._ongoingIndirectDependencies.includes(dependency)) {
            this._ongoingIndirectDependencies.push(dependency);
        }
        return !!dependency;
    }

    /**
     * Returns true if there are any indirect dependencies ongoing, and false otherwise.
     *
     * The method checks if there are any indirect dependencies ongoing by looking at the
     * _ongoingIndirectDependencies array. If the array is empty, the method returns false.
     * Otherwise, it iterates over the tasks in the array and checks if there is a task
     * that depends on the task with the given id and has a higher index than the task with
     * the given id. If it finds such a task, it returns true. If it does not find any
     * such task, it returns false.
     *
     * @param task The task for which to check if there are any ongoing indirect dependencies.
     * @returns True if there are any ongoing indirect dependencies, false otherwise.
     */
    public areThereAnyOngoingIndirectDependencies(task: BusinessProcessTask): boolean {
        if (this._ongoingIndirectDependencies.length === 0) {
            return false;
        }

        const index = this.tasks().indexOf(task);
        for (const ongoingDependency of this._ongoingIndirectDependencies) {
            const tasks = this.tasks().filter(
                t =>
                    t.data.action.dependencies?.some((d: any) => d.id === ongoingDependency.id) &&
                    t.no > ongoingDependency.index + 2
            );

            if (tasks.length) {
                const indexOfDependentTask = ongoingDependency.index;
                const indexOfDependingTask = tasks[0].no - 1;
                return indexOfDependentTask < index && index + 1 < indexOfDependingTask;
            }
        }

        return false;
    }

    /**
     * Checks if the given task has an indirect dependency with one of the ongoing indirect dependencies.
     *
     * @param task The BusinessProcessTask to check for indirect dependency.
     * @returns true if the task has an indirect dependency with one of the ongoing indirect dependencies. Otherwise, false.
     */
    private hasIndirectDependency(task: BusinessProcessTask): boolean {
        return this._ongoingIndirectDependencies.some(indirectDependency =>
            task.data.action.dependencies?.includes(indirectDependency)
        );
    }

    /**
     * Checks if the next task has an indirect dependency based on the given task.
     *
     * @param task The current BusinessProcessTask to check for indirect dependency.
     * @returns Whether the next task has an indirect dependency.
     */
    public nextTaskHasIndirectDependency(task: BusinessProcessTask): boolean {
        const currentTaskIndex = this.tasks().findIndex(t => t.id === task.id);
        const nextTask = this.tasks()[currentTaskIndex + 1];
        return nextTask ? this.hasIndirectDependency(nextTask) : false;
    }
}
