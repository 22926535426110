import { CdkMenuItem, CdkMenu } from '@angular/cdk/menu';
import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
function MycdkContextMenuComponent_For_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵlistener("click", function MycdkContextMenuComponent_For_3_Conditional_1_Template_div_click_1_listener() {
      i0.ɵɵrestoreView(_r9);
      const menuItem_r1 = i0.ɵɵnextContext().$implicit;
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.menuItemClicked(menuItem_r1));
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const menuItem_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(menuItem_r1.title);
  }
}
function MycdkContextMenuComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MycdkContextMenuComponent_For_3_Conditional_1_Template, 3, 1, "ng-container");
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const menuItem_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, ctx_r0.isMenuItemVisible(menuItem_r1) ? 1 : -1);
  }
}
class MycdkContextMenuComponent {
  /**
   * Constructor
   */
  constructor() {
    /**
     * Context menu configuration
     */
    this.menuConfig = {};
    /**
     * Default menu items (if needed)
     * (can be overriden by passing default items in config)
     */
    this.defaultMenuItems = [];
    /**
     * Final menu items
     * Calculated from the menu config and passed default menu items
     */
    this.menuItems = [];
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.setDefaultMenuItems();
    this.setMenuItems();
  }
  /**
   * ngOnDestroy
   */
  ngOnDestroy() {}
  // -----------------------------------------------------------------------------------------------------
  // @ Functions
  // -----------------------------------------------------------------------------------------------------
  /**
   * Sets and overrides (if provided) default menu items
   */
  setDefaultMenuItems() {
    let defaultItems = this.menuConfig.contextMenuDefaultItems;
    let finalDefaultItems = [];
    if (!defaultItems || defaultItems.length == 0) return;
    defaultItems.forEach(item => {
      let defaultItem = this.defaultMenuItems.find(value => value.id == item.id);
      if (defaultItem) finalDefaultItems.push({
        ...defaultItem,
        ...item
      });
    });
    this.defaultMenuItems = finalDefaultItems;
  }
  /**
   * Sets provided menu items along with the processed default menu items
   */
  setMenuItems() {
    this.menuItems = [...this.defaultMenuItems];
    if (this.menuConfig.contextMenuItems) this.menuItems.push(...this.menuConfig.contextMenuItems);
  }
  /**
   * Checks if the menu item should be visible to the user
   *
   * @param menuItem the menu item object
   * @returns whether the menu items should be visible
   */
  isMenuItemVisible(menuItem) {
    // default case (when the value isn't provided)
    if (menuItem.isVisible == null) {
      return true;
    }
    // when 'boolean' value is provided
    if (typeof menuItem.isVisible === 'boolean') {
      return menuItem.isVisible;
    }
    // when 'function' value is provided
    return menuItem.isVisible(this.node);
  }
  /**
   * Called when a menu item is clicked
   *
   * @param menuItem the menu item object
   */
  menuItemClicked(menuItem) {
    this.menuItemClick(this.node, menuItem);
  }
  static {
    this.ɵfac = function MycdkContextMenuComponent_Factory(t) {
      return new (t || MycdkContextMenuComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MycdkContextMenuComponent,
      selectors: [["mycdk-context-menu"]],
      inputs: {
        node: "node",
        menuConfig: "menuConfig",
        defaultMenuItems: "defaultMenuItems",
        menuItemClick: "menuItemClick"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 0,
      consts: [["cdkMenu", "", 1, "menu-container", "mat-elevation-z8"], ["cdkMenuItem", "", 3, "click"]],
      template: function MycdkContextMenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainerStart(0);
          i0.ɵɵelementStart(1, "div", 0);
          i0.ɵɵrepeaterCreate(2, MycdkContextMenuComponent_For_3_Template, 2, 1, "ng-container", null, i0.ɵɵrepeaterTrackByIndex);
          i0.ɵɵelementEnd();
          i0.ɵɵelementContainerEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.menuItems);
        }
      },
      dependencies: [CdkMenuItem, CdkMenu],
      styles: [".menu-container{background-color:var(--fuse-bg-dialog);display:flex;flex-direction:column;min-width:100px;padding:8px 0}.menu-container>div{padding:.5rem 1rem}.menu-container>div:hover{cursor:pointer;background-color:var(--fuse-bg-hover)!important}.menu-container:empty{display:none}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MycdkContextMenuComponent, [{
    type: Component,
    args: [{
      selector: 'mycdk-context-menu',
      standalone: true,
      imports: [CdkMenuItem, CdkMenu],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-container>\n    <div class=\"menu-container mat-elevation-z8\" cdkMenu>\n        @for (menuItem of menuItems; track $index) {\n        <ng-container>\n            @if(isMenuItemVisible(menuItem)) {\n            <ng-container>\n                <div cdkMenuItem (click)=\"menuItemClicked(menuItem)\">{{ menuItem.title }}</div>\n            </ng-container>\n            }\n        </ng-container>\n        }\n    </div>\n</ng-container>\n",
      styles: [".menu-container{background-color:var(--fuse-bg-dialog);display:flex;flex-direction:column;min-width:100px;padding:8px 0}.menu-container>div{padding:.5rem 1rem}.menu-container>div:hover{cursor:pointer;background-color:var(--fuse-bg-hover)!important}.menu-container:empty{display:none}\n"]
    }]
  }], () => [], {
    node: [{
      type: Input,
      args: ['node']
    }],
    menuConfig: [{
      type: Input,
      args: ['menuConfig']
    }],
    defaultMenuItems: [{
      type: Input,
      args: ['defaultMenuItems']
    }],
    menuItemClick: [{
      type: Input,
      args: ['menuItemClick']
    }]
  });
})();

/*
 * Public API Surface of my-cdk
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MycdkContextMenuComponent };
