import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    WritableSignal,
    computed,
    effect,
    signal,
} from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import {
    AbstractMesh,
    ArcRotateCamera,
    Color3,
    Engine,
    FreeCamera,
    Material,
    Matrix,
    Mesh,
    MeshBuilder,
    PickingInfo,
    PointerEventTypes,
    Ray,
    Scene,
    SceneLoader,
    StandardMaterial,
    Vector3,
} from 'babylonjs';
import 'babylonjs-loaders';
import { Subject, takeUntil } from 'rxjs';
import { MovingType, SensitivityType } from '../../models/moving-type';
import { BabylonChartConfiguration } from './babylon-chart-config.model';
import { BabylonDirection, BabylonPickingInfo } from './babylon-directions.model';
import { BlenderMesh } from './blender-mesh';
import { Position } from './position.model';
import { FlowchartLoadingSpinnerService } from '../../reusable-components/flowchart/flowchart-container/flowchart-loading-spinner.service';

/**
 * This abstract class is collecting all the essential functions and
 * workarounds that are needed to create a Scene with BabylonJS.
 *
 * The descended class should has a template that contains a canvas like this:
 *
 * <canvas #renderCanvas></canvas>
 */
@Component({ template: '' })
export abstract class BabylonAncestral implements OnInit, AfterViewInit, OnDestroy {
    protected destroyed$ = new Subject<void>();

    @Input() movingType: WritableSignal<MovingType>;

    /** The scale of the camera */
    @Input() cameraScale: WritableSignal<string>;

    @Input() sensitivityType: WritableSignal<SensitivityType>;

    @Input() config: BabylonChartConfiguration;

    /** An EventEmitter which emits an event if a clickable Mesh is clicked */
    @Output() meshClicked: EventEmitter<string> = new EventEmitter();
    /** An EventEmitter which emits an event if a clickable Mesh is clicked */
    @Output() meshDoubleClicked: EventEmitter<string> = new EventEmitter();

    protected theme: 'light' | 'dark';

    /** Position of the camera on the Y axis */
    protected cameraY: number = -2;
    protected cameraZ: number = -10;

    /** A flag that indicates if the mouse is over the canvas or not */
    protected isMouseOverCanvas: boolean = false;

    protected topMostPoint = 2;
    protected bottomMostPoint: number | undefined;
    protected leftMostPoint: number | undefined;
    protected rightMostPoint: number | undefined;

    protected half = 0.5;
    protected cameraFOV = Math.PI / 4;
    protected zero = 0;
    protected rotationNinentyDegrees = Math.PI * this.half;
    protected quarter = 0.25;

    protected background: Mesh;
    protected backgroundPositionZ = 1;

    // Dimensions for Arrows
    protected sphereRadius: number = 0.1;
    protected tipLength: number = 0.1;
    protected arrowThickness: number = 0.025;
    protected tipDiameter: number = 0.1;

    protected zeroCameraPoint_offsetZ: number = 0;
    protected zeroCameraPoint_offsetY: number = 1.7;

    /** A flag that indicates if the Scene is scrollable or not */
    protected canScroll: boolean = true;

    protected defaultSpeed = 50;
    protected fixedSensitivity = 50;
    protected sensitivity = computed(() => {
        if (this.sensitivityType() === SensitivityType.Fixed) {
            return this.fixedSensitivity;
        }

        const minScale = 100;
        const maxScale = 200;

        // Clamp cameraScale to ensure it stays within the specified range
        const clampedScale = Math.max(minScale, Math.min(+this.cameraScale(), maxScale));

        // Calculate the interpolation factor between 0 and 1 based on the clamped scale
        const t = (clampedScale - minScale) / (maxScale - minScale);

        // Use linear interpolation to calculate sensitivity
        const sensitivity = 100 - t * 99; // Adjust the factor (99 in this case) for the desired slope

        return sensitivity;
    });
    protected movingSpeed = computed(() => {
        const newSpeed = this.cameraScale()
            ? ((201 - +this.cameraScale()) / this.sensitivity()) * this.defaultSpeed
            : this.defaultSpeed;

        return newSpeed;
    });

    // Colors
    protected backgroundColorLight = '#FFFFFF';
    protected backgroundColorDark = '#0F172A';
    protected backgroundBoxColorLight = '#FFFFFF';
    protected backgroundBoxColorDark = '#0F172A';
    protected primaryBoxColorLight = '#2196f3';
    protected primaryBoxColorDark = '#2196f3';
    protected warnBoxColorLight = '#dc2626';
    protected warnBoxColorDark = '#dc2626';
    protected textColorLight = '#000000';
    protected textColorDark = '#FFFFFF';
    protected whiteTextColorLight = '#FFFFFF';
    protected whiteTextColorDark = '#000000';
    protected arrowColorLight = '#000000';
    protected arrowColorDark = '#FFFFFF';
    protected selectionColorLight = '#B9D3FF';
    protected selectionColorDark = '#53637D';

    // Materials

    /** Material for the background of the Scene */
    protected backgroundMaterial: StandardMaterial;
    /** Material for the background of Processes and Decisions */
    protected backgroundBoxMaterial: StandardMaterial;
    /** Material for the borders of Processes and Decisions */
    protected primaryBoxMaterial: StandardMaterial;
    /** Red Material for the background of Processes and Decisions */
    protected warnBoxMaterial: StandardMaterial;
    /** Material for the texts in the Scene */
    protected textMaterial: StandardMaterial;
    /** Material for the white texts in the Scene */
    protected whiteTextMaterial: StandardMaterial;
    /** Material for the arrows */
    protected arrowMaterial: StandardMaterial;
    /** Material for selection background */
    protected selectionMaterial: StandardMaterial;

    /** The font loaded from a json file */
    protected fontData: any;
    protected font = '/assets/fonts/inter/Inter_Regular.json';
    protected fontSize = 0.1;
    protected characterLimitForEachLine = 25;
    protected characterLimitOffset = 5;
    protected notAvailable = 'N/A';

    @ViewChild('renderCanvas', { static: false })
    canvas: ElementRef<HTMLCanvasElement>;
    protected resizeObserver: ResizeObserver;

    protected isAfterViewInit: WritableSignal<boolean> = signal(false);
    protected enableCursorMovement: WritableSignal<boolean> = signal(false);

    /** The engine of the Babylon */
    protected engine: Engine;
    /** The scene that is rendered by the engine */
    protected scene: Scene;
    /** The camera that is used to see the scene */
    protected camera: FreeCamera | ArcRotateCamera;

    protected disableWheel: boolean = false;

    startTime: number;

    constructor(
        protected el: ElementRef,
        protected ngZone: NgZone,
        protected injector: Injector,
        protected fuseConfigService: FuseConfigService,
        protected spinnerService: FlowchartLoadingSpinnerService
    ) {
        this.fuseConfigService.config$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((fuseConfig: any) => {
                this.theme = fuseConfig['scheme'];
                this.themeChanged();
            });

        effect(() => {
            const isAfterViewInit = this.isAfterViewInit();
            const enableCursorMovement = this.enableCursorMovement();

            if (isAfterViewInit && enableCursorMovement) {
                this.turnCursorMovementOn();
            } else {
                this.turnCursorMovementOff();
            }
        });
    }

    /** Sets up the camera for scaling */
    ngOnInit() {
        effect(
            () => {
                const scale = this.cameraScale();
                if (this.camera && this.cameraScale()) {
                    this.setCameraScale();
                }
            },
            {
                injector: this.injector,
            }
        );

        if (this.config) {
            this.loadConfiguration();
        }
    }

    /** Sets up the event listeners for the mouse over and for the wheel */
    ngAfterViewInit(): void {
        this.isAfterViewInit.set(true);

        this.observeMouseOverAndOut();

        this.observeWheel();
    }

    /**
     * Disposes the engine
     */
    ngOnDestroy() {
        this.turnCursorMovementOff();
        this.stopEngine();
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    protected loadConfiguration(): void {
        // Colors
        if (this.config.colorThemes) {
            const light = this.config.colorThemes.light ?? [];
            Object.keys(light).forEach((key: string) => {
                if (light[key]) {
                    this[`${key}Light`] = light[key];
                }
            });
            const dark = this.config.colorThemes.light ?? [];
            Object.keys(dark).forEach((key: string) => {
                if (dark[key]) {
                    this[`${key}Dark`] = dark[key];
                }
            });
        }

        // Camera
        if (this.config.camera) {
            const camera = this.config.camera;
            this.defaultSpeed = camera.scrollingSpeed ?? this.defaultSpeed;
            this.fixedSensitivity = camera.fixedSensitivity ?? this.fixedSensitivity;
        }

        // Font
        if (this.config.font) {
            this.font = this.config.font;
        }

        // Arrow
        if (this.config.arrow) {
            const arrow = this.config.arrow;
            Object.keys(arrow).forEach((key: string) => {
                if (arrow[key]) {
                    this[key] = arrow[key];
                }
            });
        }
    }

    /**
     * Handle theme changes (light/dark)
     */
    protected themeChanged(): void {
        this.setColors();
        this.backgroundMaterial?.freeze();
        this.backgroundBoxMaterial?.freeze();
        this.primaryBoxMaterial?.freeze();
        this.warnBoxMaterial?.freeze();
        this.textMaterial?.freeze();
        this.whiteTextMaterial?.freeze();
        this.arrowMaterial?.freeze();
        this.selectionMaterial?.freeze();
    }

    /**
     * Creates the Babylon Engine with the reference of the canvas, provides
     * the rendering of the Scene in a renderLoop, and also observes resizements.
     *
     * Uses the createScene and handleSpecificSceneActions functions.
     */
    protected startEngine(reset?: boolean): void {
        this.stopEngine();

        this.spinnerService.showLoadingSpinner.set(true);

        this.engine = new Engine(this.canvas.nativeElement, true, {
            preserveDrawingBuffer: true,
            stencil: true,
            antialias: true,
        });

        this.engine.setHardwareScalingLevel(0.5);

        this.ngZone.run(() => {
            this.createScene(this.engine, this.canvas.nativeElement, reset).then((scene: Scene) => {
                this.scene = scene;
                // this.scene.debugLayer.show();
                this.scene.blockMaterialDirtyMechanism = true;

                this.handleSpecificSceneActions();

                this.spinnerService.showLoadingSpinner.set(false);
                this.engine.runRenderLoop(() => {
                    this.scene.render();
                });
            });
        });
        this.resizeObserver = new ResizeObserver(() => {
            // Execute when the div size changes
            this.engine.resize();
        });

        // Start observing the div container
        this.resizeObserver.observe(this.canvas.nativeElement);
    }

    protected stopEngine(): void {
        // Stop Babylon.js engine and clean up resources
        if (this.engine) {
            // Stop the engine's render loop
            this.engine.stopRenderLoop();

            // Dispose of the scene and its elements
            if (this.scene) {
                this.camera.dispose();
                this.background.dispose();

                this.scene.dispose();
            }

            // Dispose of the engine
            this.resizeObserver.unobserve(this.canvas.nativeElement);
            this.engine.dispose();

            // Set properties to undefined
            this.camera = undefined;
            this.background = undefined;
            this.scene = undefined;
            this.engine = undefined;
            this.clean();
        }
    }

    protected clean(): void {}

    // Camera movement and scaling functions

    /** Returns the x position of the camera by the type of flowchart */
    protected getCameraX(): number {
        throw new Error('getCameraX is not implemented!');
    }

    /** Creates the camera for the user and the printCamera for printing */
    protected createCamera(
        scene: Scene,
        cameraType: 'default' | 'arcRotate' = 'default',
        withControl?: boolean
    ): void {
        // Create and position the camera
        const cameraX = this.getCameraX();

        if (cameraType === 'default') {
            this.camera = new FreeCamera('camera', new Vector3(cameraX, 0, this.cameraZ), scene);
        } else {
            this.camera = new ArcRotateCamera(
                'camera',
                this.rotationNinentyDegrees,
                this.rotationNinentyDegrees,
                this.cameraZ,
                Vector3.Zero(),
                scene
            );
        }
        this.camera.setTarget(Vector3.Zero());
        this.camera.fov = Math.PI * this.quarter;

        // Set the position of the camera on the Y axis
        if (this.cameraY) {
            this.camera.position.y = this.cameraY;
        }

        if (withControl) {
            this.camera.attachControl(true);
            return;
        }
        this.camera.attachControl(false);
        this.camera.inputs.clear();
    }

    protected isGivenIntersectionValid(
        pickedPointOnAxis: number,
        calculatedDeltaOnAxis: number,
        isBigger: boolean,
        leftMostPoint: number
    ): boolean {
        if (isBigger) {
            return (
                pickedPointOnAxis - calculatedDeltaOnAxis > leftMostPoint ||
                (pickedPointOnAxis < leftMostPoint &&
                    pickedPointOnAxis - calculatedDeltaOnAxis - leftMostPoint >
                        pickedPointOnAxis - leftMostPoint)
            );
        }
        return (
            pickedPointOnAxis - calculatedDeltaOnAxis < leftMostPoint ||
            (pickedPointOnAxis > leftMostPoint &&
                pickedPointOnAxis - calculatedDeltaOnAxis - leftMostPoint <
                    pickedPointOnAxis - leftMostPoint)
        );
    }

    private setCameraPositionByParameters(
        originalIntersections: BabylonPickingInfo,
        calculatedDeltaX: number,
        calculatedDeltaY: number
    ): void {
        const currentCameraX = this.camera.position.x;
        const currentCameraY = this.camera.position.y;

        if (
            originalIntersections.left.pickedPoint.x > this.leftMostPoint + 0.5 ||
            originalIntersections.right.pickedPoint.x < this.rightMostPoint - 0.5
        ) {
            // X axis
            const canMoveLeft = this.isGivenIntersectionValid(
                originalIntersections.left.pickedPoint.x,
                calculatedDeltaX,
                true,
                this.leftMostPoint
            );
            const canMoveRight = this.isGivenIntersectionValid(
                originalIntersections.right.pickedPoint.x,
                calculatedDeltaX,
                false,
                this.rightMostPoint
            );
            if (canMoveLeft && canMoveRight) {
                this.scene.activeCamera.position.x -= calculatedDeltaX;
            } else if (!canMoveLeft && canMoveRight) {
                let diff = originalIntersections.left.pickedPoint.x - currentCameraX;
                this.camera.position.x = this.leftMostPoint - diff;
            } else if (canMoveLeft && !canMoveRight) {
                let diff = originalIntersections.right.pickedPoint.x - currentCameraX;
                this.camera.position.x = this.rightMostPoint - diff;
            }
        }

        const canScrollUp = this.isGivenIntersectionValid(
            originalIntersections.top.pickedPoint.y,
            -calculatedDeltaY,
            false,
            this.topMostPoint
        );
        const canScrollDown = this.isGivenIntersectionValid(
            originalIntersections.bottom.pickedPoint.y,
            -calculatedDeltaY,
            true,
            this.bottomMostPoint
        );
        // Y axis

        if (!this.bottomMostPoint) {
            this.bottomMostPoint = -this.calculateTotalHeight();
        }
        if (
            originalIntersections.top.pickedPoint.y < this.topMostPoint ||
            originalIntersections.bottom.pickedPoint.y > this.bottomMostPoint
        ) {
            if (canScrollUp && canScrollDown) {
                this.scene.activeCamera.position.y += calculatedDeltaY;
            } else if (this.canScroll && !canScrollUp && canScrollDown) {
                let diff = originalIntersections.top.pickedPoint.y - currentCameraY;
                this.camera.position.y = this.topMostPoint - diff;
                this.cameraY = this.camera.position.y;
            } else if (this.canScroll && canScrollUp && !canScrollDown) {
                let diff = currentCameraY - originalIntersections.bottom.pickedPoint.y;
                this.camera.position.y = this.bottomMostPoint + diff;
                this.cameraY = this.camera.position.y;
            }
        }
    }

    // #region
    // Event listeners
    protected pointerDownEvent(event: PointerEvent): void {
        if (event.button === 0) {
            // Check if left mouse button is pressed
            this.isDragging = true;
            this.lastPointerX = event.clientX;
            this.lastPointerY = event.clientY;

            event.preventDefault(); // Prevent default behavior for left mouse button
        }
    }

    protected pointerUpEvent(event: any): void {
        this.isDragging = false;
    }

    protected pointerMoveEvent(event: any): void {
        if (this.isDragging) {
            const deltaX = event.clientX - this.lastPointerX;
            const deltaY = event.clientY - this.lastPointerY;
            const originalIntersections = this.getIntersections();

            const calculatedDeltaX = deltaX / this.movingSpeed();
            const calculatedDeltaY = deltaY / this.movingSpeed();

            if (this.movingType() === MovingType.Natural) {
                this.setCameraPositionByParameters(
                    originalIntersections,
                    calculatedDeltaX,
                    calculatedDeltaY
                );
            } else {
                this.setCameraPositionByParameters(
                    originalIntersections,
                    -calculatedDeltaX,
                    -calculatedDeltaY
                );
            }

            this.lastPointerX = event.clientX;
            this.lastPointerY = event.clientY;

            const newIntersections = this.getIntersections();

            this.handleSpecialEventOnCameraMovement(newIntersections.top);
        }
    }

    // #endregion

    private isDragging = false;
    private lastPointerX: number;
    private lastPointerY: number;

    protected turnCursorMovementOn(): void {
        this.canvas.nativeElement.addEventListener('pointerdown', event => {
            this.pointerDownEvent(event);
        });

        this.canvas.nativeElement.addEventListener('pointerup', event => {
            this.pointerUpEvent(event);
        });

        this.canvas.nativeElement.addEventListener('pointermove', event => {
            this.pointerMoveEvent(event);
        });
    }

    protected turnCursorMovementOff(): void {
        this.canvas.nativeElement.removeEventListener('pointerdown', event => {
            this.pointerDownEvent(event);
        });
        this.canvas.nativeElement.removeEventListener('pointerup', event => {
            this.pointerUpEvent(event);
        });
        this.canvas.nativeElement.removeEventListener('pointermove', event => {
            this.pointerMoveEvent(event);
        });
    }

    /** Detect when the mouse enters or leaves the canvas */
    protected observeMouseOverAndOut(): void {
        // Detect when the mouse enters the canvas
        this.canvas.nativeElement.addEventListener('mouseover', () => {
            this.isMouseOverCanvas = true;
        });

        // Detect when the mouse leaves the canvas
        this.canvas.nativeElement.addEventListener('mouseout', () => {
            this.isMouseOverCanvas = false;
        });
    }

    /** Handle the wheel event */
    protected observeWheel(): void {
        if (this.disableWheel) {
            return;
        }
        this.canvas.nativeElement.addEventListener(
            'wheel',
            (event: WheelEvent) => {
                if (this.isMouseOverCanvas) {
                    event.preventDefault();
                    event.stopPropagation();

                    // Handle the scroll for the BabylonJS scene here if needed
                    const delta = event.deltaY * 0.01;

                    const originalIntersections = this.getIntersections();
                    const currentCameraY = this.camera.position.y;

                    const canScrollUp = this.isGivenIntersectionValid(
                        originalIntersections.top.pickedPoint.y,
                        delta,
                        false,
                        this.topMostPoint
                    );
                    const canScrollDown = this.isGivenIntersectionValid(
                        originalIntersections.bottom.pickedPoint.y,
                        delta,
                        true,
                        this.bottomMostPoint
                    );
                    if (this.canScroll && canScrollUp && canScrollDown) {
                        this.camera.position.y -= delta;
                        this.cameraY = this.camera.position.y;
                    } else if (this.canScroll && !canScrollUp && canScrollDown) {
                        let diff = originalIntersections.top.pickedPoint.y - currentCameraY;
                        this.camera.position.y = this.topMostPoint - diff;
                        this.cameraY = this.camera.position.y;
                    } else if (this.canScroll && canScrollUp && !canScrollDown) {
                        let diff = currentCameraY - originalIntersections.bottom.pickedPoint.y;
                        this.camera.position.y = this.bottomMostPoint + diff;
                        this.cameraY = this.camera.position.y;
                    }

                    const newIntersection = this.getIntersections();

                    this.handleSpecialEventOnCameraMovement(newIntersection.top);
                }
            },
            { passive: false }
        );
    }

    /** Add pointer down event using onPointerObservable */
    protected observePointer(): void {
        let singleClickTimer: any; // Timer variable to track single click

        this.scene.onPointerObservable.add(pointerInfo => {
            switch (pointerInfo.type) {
                case PointerEventTypes.POINTERTAP:
                    if (pointerInfo.pickInfo.hit) {
                        const meshName: string = pointerInfo.pickInfo.pickedMesh.name;
                        // Emits the event if the mesh is clickable
                        if (!meshName.startsWith('not_clickable_')) {
                            // Start the timer for single click
                            singleClickTimer = setTimeout(() => {
                                // Emit the single click event if no double click occurs
                                this.meshClicked.emit(meshName);
                            }, 300); // Adjust the delay as needed (e.g., 300 milliseconds)
                        }
                    }
                    break;
                case PointerEventTypes.POINTERDOUBLETAP:
                    if (pointerInfo.pickInfo.hit) {
                        // Clear the single click timer to prevent the single click event
                        clearTimeout(singleClickTimer);
                        const meshName: string = pointerInfo.pickInfo.pickedMesh.name;

                        this.meshDoubleClicked.emit(meshName);
                    }
                case PointerEventTypes.POINTERDOWN:
                    if (
                        pointerInfo.pickInfo.hit &&
                        pointerInfo.pickInfo.pickedMesh.name.includes('draggable')
                    ) {
                        this.pointerDown(pointerInfo.pickInfo.pickedMesh);
                    }
                    break;
                case PointerEventTypes.POINTERUP:
                    this.pointerUp();
                    break;
                case PointerEventTypes.POINTERMOVE:
                    this.pointerMove();
                    break;
            }
        });
    }

    private startingPoint;
    private currentMesh;

    getGroundPosition() {
        var pickinfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, mesh => {
            return mesh == this.background;
        });
        if (pickinfo.hit) {
            return pickinfo.pickedPoint;
        }

        return null;
    }

    private pointerDown(mesh) {
        this.currentMesh = mesh;
        this.startingPoint = this.getGroundPosition();
        if (this.startingPoint) {
            // we need to disconnect camera from canvas
            setTimeout(() => {
                this.camera.detachControl();
                this.isDragging = false;
            }, 0);
        }
    }

    private pointerUp() {
        if (this.startingPoint) {
            this.camera.attachControl(this.canvas, true);

            this.startingPoint = null;
            return;
        }
    }

    private pointerMove() {
        if (!this.startingPoint) {
            return;
        }
        var current = this.getGroundPosition();
        if (!current) {
            return;
        }

        var diff = current.subtract(this.startingPoint);
        this.currentMesh.position.addInPlace(diff);

        this.startingPoint = current;
    }

    /** Sets the position of the camera on the Z axis based on the current scale */
    protected setCameraScale(): void {
        let scale = +this.cameraScale();
        let zPos: number;
        let yPos: number = this.camera.position.y;

        // If the scale is not 0, then the position on the Z axis is calculated
        if (scale > 0) {
            const dimensions = this.getSizeOfScene();

            const maxZPos =
                (dimensions.biggerSide / (2 * Math.tan(this.cameraFOV * this.half))) * -1;
            const minPos = this.cameraZ;

            let distance = maxZPos - minPos;
            if (distance > 3) {
                distance = 3;
            }

            const step = distance / 100;

            zPos = minPos + step * (100 - scale);

            if (zPos > -2) {
                zPos = -2;
            }

            this.canScroll = true;

            // Limits of the position of the camera on the Y axis
            const topLimit = ((100 - +this.cameraScale()) / 10) * -0.25;
            this.bottomMostPoint = -this.calculateTotalHeight();

            // Checks the current position of the camera and sets it to the new limits if needed
            if (this.camera.position.y > topLimit) {
                yPos = topLimit - 0.01;
            } else if (this.camera.position.y < this.bottomMostPoint) {
                yPos = this.bottomMostPoint + 0.01;
            }
        }
        // If the scale is 0, then sets the camera that way that all the rendered meshes are visible
        else {
            let results = this.calculateCameraPositionForZero();
            yPos = results.yPos;
            zPos = results.zPos;

            this.cameraY = yPos;
            this.canScroll = false;
        }

        // Sets the position and target of the camera
        if (this.camera) {
            this.camera.position = new Vector3(this.camera.position.x, yPos, zPos);
            this.camera.setTarget(new Vector3(this.camera.position.x, this.camera.position.y, 0));

            const intersections = this.getIntersections();
            if (
                intersections?.top?.pickedPoint &&
                intersections.top.pickedPoint.y > this.topMostPoint
            ) {
                this.camera.position.y -= intersections.top.pickedPoint.y - this.topMostPoint;
            }
            this.handleSpecialEventOnCameraMovement(intersections.top);
        }
    }

    /**
     * Returns a direction vector which starts from the active camera and collides with the background.
     * @param side BabylonDirection top | bottom | left | right
     * @param invertedViewProjectionMatrix Matrix
     * @returns Vector3
     */
    protected getDirection(side: BabylonDirection, invertedViewProjectionMatrix: Matrix): Vector3 {
        // Calculate the clip space coordinates of the top of the viewport
        let clipSpaceTop: Vector3;

        switch (side) {
            case 'top':
                clipSpaceTop = new Vector3(0, 1, 0.999);
                break;
            case 'bottom':
                clipSpaceTop = new Vector3(0, -1, 0.999);
                break;
            case 'left':
                clipSpaceTop = new Vector3(-1, 0, 0.999);
                break;
            case 'right':
                clipSpaceTop = new Vector3(1, 0, 0.999);
                break;
        }

        const worldSpaceTop = Vector3.TransformCoordinates(
            clipSpaceTop,
            invertedViewProjectionMatrix
        );

        // Get the direction vector from the camera to the calculated point
        const direction = worldSpaceTop.subtract(this.camera.position);
        direction.normalize();

        return direction;
    }

    /**
     * Calculates the top/bottom/left/right most point that the camera can see.
     * @returns BabylonPickingInfo
     */
    protected getIntersections(): BabylonPickingInfo {
        let pickingInfo: BabylonPickingInfo = {
            top: null,
            bottom: null,
            left: null,
            right: null,
        };
        if (!this.background) {
            return pickingInfo;
        }

        const viewMatrix = this.camera.getViewMatrix();
        const projectionMatrix = this.camera.getProjectionMatrix();
        const viewProjectionMatrix = viewMatrix.multiply(projectionMatrix);
        const invertedViewProjectionMatrix = Matrix.Invert(viewProjectionMatrix);

        Object.keys(BabylonDirection).map((direction: string) => {
            const _direction = this.getDirection(
                BabylonDirection[direction],
                invertedViewProjectionMatrix
            );
            const ray = new Ray(this.camera.position, _direction, 100);
            pickingInfo[direction] = ray.intersectsMesh(this.background);
        });

        return pickingInfo;
    }

    // Functions for creating texts

    /**
     * Creates a 3D text with the given parameters.
     *
     * @param pos Position of the text
     * @param name Name for the text mesh
     * @param text The text that should be presented
     * @param scene Scene
     */
    protected createText(
        pos: Position,
        name: string,
        text: string,
        scene: Scene,
        size?: number
    ): Mesh {
        // return;
        if (!text) {
            return;
        }
        if (!size) {
            size = this.fontSize;
        }
        // Make it into two lines
        if (text.length >= this.characterLimitForEachLine + this.characterLimitOffset) {
            return this.createMultilineText(pos, name, text, scene, size);
        }

        const textMesh = MeshBuilder.CreateText(
            name,
            text && text.length ? text : this.notAvailable,
            this.fontData,
            {
                size,
                depth: 0.01,
                resolution: 1,
            },
            scene
        );
        textMesh.position = new Vector3(pos.x, pos.y, pos.z);
        textMesh.material = this.textMaterial;
        return textMesh;
    }

    /**
     * Creates a 3D text with the given parameters in two lines.
     *
     * @param pos Position of the text
     * @param name Name for the text mesh
     * @param text The text that should be presented
     * @param scene Scene
     */
    protected createMultilineText(
        pos: Position,
        name: string,
        text: string,
        scene: Scene,
        size?: number
    ): Mesh {
        const yPos = (size / 4) * 3;
        let texts = text.split(' ');
        let firstText = '';
        let secondText = '';
        let fistTextFinished = false;
        let secondTextFinished = false;
        const space = ' ';

        texts.forEach(word => {
            const wordLength = word.length + 1;
            if (
                !fistTextFinished &&
                firstText.length + wordLength < this.characterLimitForEachLine
            ) {
                firstText += (firstText.length !== 0 ? space : '') + word;
            } else if (
                !secondTextFinished &&
                secondText.length + wordLength < this.characterLimitForEachLine
            ) {
                fistTextFinished = true;
                secondText += (secondText.length !== 0 ? space : '') + word;
            } else {
                secondTextFinished = true;
                secondText += '...';
            }
        });

        if (!size) {
            size = this.fontSize;
        }
        const text1 = this.createText({ x: 0, y: yPos, z: 0 }, `${name}_0`, firstText, scene, size);
        const text2 = this.createText(
            { x: 0, y: -yPos, z: 0 },
            `${name}_1`,
            secondText,
            scene,
            size
        );

        const mesh = this.createMergedMesh([text1, text2], pos, 'multilineText');
        mesh.position = new Vector3(pos.x, pos.y, pos.z);
        mesh.name = name;
        return mesh;
    }

    // Utility functions

    protected async loadMesh(
        fileName: string,
        objectName: string,
        scene: Scene
    ): Promise<BlenderMesh> {
        const { meshes } = await SceneLoader.ImportMeshAsync(
            '',
            '/assets/models/',
            `${fileName}.glb`,
            scene
        );

        const textPositions: Map<string, Vector3> = new Map();

        meshes.map((mesh: AbstractMesh) => {
            if (mesh.name.includes('Text_')) {
                textPositions.set(mesh.name, mesh.position);
            }
        });

        const meshesToMerge = meshes.filter(m => !m.name.includes('Text_')).slice(1);

        const mergedMesh = this.createMergedMesh(
            meshesToMerge as any[],
            { x: 0, y: 0, z: 0 },
            fileName
        );

        meshes[0].dispose();

        const boundingInfo = mergedMesh.getBoundingInfo();
        const height = boundingInfo.maximum.subtract(boundingInfo.minimum).y;

        return {
            mesh: mergedMesh,
            textPositions,
            height,
        };
    }

    protected createBackgroundPlane(
        size: number,
        height: number,
        scene: Scene,
        x: number = 0,
        y: number = 0
    ): void {
        const background = MeshBuilder.CreatePlane(
            'not_clickable_plane',
            {
                size,
                height,
            },
            scene
        );
        background.position = new Vector3(x, y, this.backgroundPositionZ);
        background.material = this.backgroundMaterial;

        this.background = background;
        this.background.freezeWorldMatrix();
        this.background.freezeNormals();
    }

    /**
     * Merges multiple Meshes into one then sets the position and the name for it
     *
     * @param meshes Meshes that should be merged
     * @param pos Position of the merged mesh
     * @param id Unique identifier for the merged mesh
     * @returns Merged mesh
     */
    protected createMergedMesh(meshes: Mesh[], pos: Position, id: string): Mesh {
        const mergedMesh: Mesh | null = Mesh.MergeMeshes(meshes, true, true, null, false, true);
        if (!mergedMesh) {
            throw new Error('There was a problem while merging meshes.');
        }

        mergedMesh.position = new Vector3(pos.x, pos.y, pos.z);
        mergedMesh.name = id;
        mergedMesh.convertToUnIndexedMesh();
        return mergedMesh;
    }

    /**
     * Sets position and material for a Mesh.
     *
     * @param mesh The Mesh that should be set
     * @param material Material for the Mesh
     * @param pos Position for the Mesh
     */
    protected setPosAndMaterial(mesh: Mesh, material: Material, pos: Position) {
        mesh.position = new Vector3(pos.x, pos.y, pos.z);
        mesh.material = material;
    }

    /**
     * Creates the Scene and all its elements in it.
     *
     * @param engine Engine
     * @param canvas Scene
     */
    protected async createScene(
        engine: Engine,
        canvas: HTMLCanvasElement,
        reset?: boolean
    ): Promise<Scene> {
        throw new Error('createScene is not implemented');
    }

    /**
     * If some special observers or actions are needed to be implemented for the Scene,
     * this functions provides them a place to be written.
     */
    protected handleSpecificSceneActions(): void {
        throw new Error('handleSpecificSceneActions is not implemented');
    }

    // Font
    /** Load the font data if necessary */
    protected async loadFont(): Promise<void> {
        if (!this.fontData) {
            this.fontData = await (await fetch(this.font)).json();
        }
    }

    // Colors
    /** Create materials */
    protected createMaterials(): void {
        this.backgroundMaterial = new StandardMaterial('backgroundMaterial');
        this.backgroundBoxMaterial = new StandardMaterial('backgroundBoxMaterial');
        this.primaryBoxMaterial = new StandardMaterial('primaryBoxMaterial');
        this.warnBoxMaterial = new StandardMaterial('warnBoxMaterial');
        this.textMaterial = new StandardMaterial('textMaterial');
        this.whiteTextMaterial = new StandardMaterial('whiteTextMaterial');
        this.arrowMaterial = new StandardMaterial('arrowMaterial');
        this.selectionMaterial = new StandardMaterial('selectionMaterial');
    }

    /** Set the color to materials by theme */
    protected setColors(): void {
        if (!this.engine) {
            return;
        }
        if (this.theme === 'light') {
            this.backgroundMaterial.emissiveColor = Color3.FromHexString(this.backgroundColorLight);
            this.backgroundBoxMaterial.emissiveColor = Color3.FromHexString(
                this.backgroundBoxColorLight
            );
            this.primaryBoxMaterial.emissiveColor = Color3.FromHexString(this.primaryBoxColorLight);
            this.warnBoxMaterial.emissiveColor = Color3.FromHexString(this.warnBoxColorLight);
            this.textMaterial.emissiveColor = Color3.FromHexString(this.textColorLight);
            this.whiteTextMaterial.emissiveColor = Color3.FromHexString(this.whiteTextColorLight);
            this.arrowMaterial.emissiveColor = Color3.FromHexString(this.arrowColorLight);
            this.selectionMaterial.emissiveColor = Color3.FromHexString(this.selectionColorLight);
        } else {
            this.backgroundMaterial.emissiveColor = Color3.FromHexString(this.backgroundColorDark);
            this.arrowMaterial.emissiveColor = Color3.FromHexString(this.arrowColorDark);
            this.selectionMaterial.emissiveColor = Color3.FromHexString(this.selectionColorDark);
            this.backgroundBoxMaterial.emissiveColor = Color3.FromHexString(
                this.backgroundBoxColorLight
            );
            this.primaryBoxMaterial.emissiveColor = Color3.FromHexString(this.primaryBoxColorDark);
            this.warnBoxMaterial.emissiveColor = Color3.FromHexString(this.warnBoxColorDark);
            return;
            // TODO dark mode materials
            this.textMaterial.emissiveColor = Color3.FromHexString(this.textColorDark);
            this.whiteTextMaterial.emissiveColor = Color3.FromHexString(this.whiteTextColorDark);
        }
        this.setTextureToSelectionMaterial();
    }

    protected setTextureToSelectionMaterial(): void {
        // TODO setTextureToSelectionMaterial
        // const emissiveTexture = new Texture('assets/pngs/texture.png', this.scene);
        // this.selectionMaterial.emissiveTexture = emissiveTexture;
    }

    // Arrow elements
    /**
     * Creates a sphere for arrow
     * @param scene Scene
     * @returns Mesh
     */
    protected createSphere(scene: Scene): Mesh {
        const sphere = MeshBuilder.CreateSphere(
            'not_clickable_sphere',
            {
                diameter: this.sphereRadius,
            },
            scene
        );

        return sphere;
    }

    /**
     * Creates a shaft for arrow
     * @param shaftLength length of the shaft
     * @param scene Scene
     * @returns Mesh
     */
    protected createShaft(shaftLength: number, scene: Scene): Mesh {
        const shaft = MeshBuilder.CreateCylinder(
            'not_clickable_shaft',
            {
                height: shaftLength,
                diameter: this.arrowThickness, // adjust as needed
                tessellation: 32,
            },
            scene
        );

        return shaft;
    }
    /**
     * Creates a tip for arrow
     * @param scene Scene
     * @returns Mesh
     */
    protected createTip(scene: Scene): Mesh {
        const tip = MeshBuilder.CreateCylinder(
            'not_clickable_tip',
            {
                height: this.tipLength,
                diameterBottom: 0,
                diameterTop: this.tipDiameter, // adjust as needed, should be wider than the shaft
                tessellation: 32,
            },
            scene
        );
        return tip;
    }

    // Functions that are needed to be overwritten

    protected getSizeOfScene(): { height: number; width: number; biggerSide: number } {
        throw new Error('getSizeOfScene is not implemented');
    }

    protected calculateTotalHeight(): number {
        throw new Error('calculateTotalHeight is not implemented');
    }

    protected calculateCameraPositionForZero(): { yPos: number; zPos: number } {
        throw new Error('calculateCameraPositionForZero is not implemented');
    }

    protected handleSpecialEventOnCameraMovement(intersection: PickingInfo): void {
        throw new Error('handleSpecialEventOnCameraMovement is not implemented');
    }
}
