import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    WritableSignal,
    effect,
    signal,
} from '@angular/core';
import { OrganisationStructure } from 'app/api';
import { BabylonChartConfiguration } from '../../../babylonjs/core/babylon-chart-config.model';
import { MovingType, SensitivityType } from '../../../models/moving-type';

@Component({
    selector: 'organisation-container',
    templateUrl: './organisation-container.component.html',
    styleUrl: './organisation-container.component.scss',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class OrganisationContainerComponent implements OnChanges {
    @Input() config: OrganisationContainerConfig;
    @Input() chartConfig: BabylonChartConfiguration;
    @Input() showId: boolean;
    @Input() type: 'organisation' | 'area' | 'system' = 'organisation';
    @Output() onDoubleClicked: EventEmitter<any> = new EventEmitter();

    static selectTaskCode: string = 'select_task_';
    static taskDoubleClickedCode: string = 'task_doubleClicked_';

    organisation: WritableSignal<OrganisationStructure> = signal(null);

    selectedWidth: string = '100';

    cameraScale: WritableSignal<string> = signal(null);
    movingType: WritableSignal<MovingType> = signal(null);
    sensitivityType: WritableSignal<SensitivityType> = signal(null);

    maxScale: number = 200;

    constructor() {
        this.cameraScale.set(this.selectedWidth);

        const movingType = localStorage.getItem('movingType');
        if (!movingType) {
            localStorage.setItem('movingType', MovingType.Natural);
        }
        this.movingType.set(MovingType[movingType]);

        const sensitivityType = localStorage.getItem('sensitivityType');
        if (!movingType) {
            localStorage.setItem('sensitivityType', SensitivityType.Varied);
        }
        this.sensitivityType.set(SensitivityType[sensitivityType]);

        effect(() => {
            const selectedOrganisation = this.config.service.selectedOrganisation;
            const selectedItem = this.config.service.selectedItem;
            this.organisation = selectedOrganisation ?? selectedItem;
        });

        effect(
            () => {
                const code = this.config.executeActionSignal();

                if (code && code.includes('resize')) {
                    const resizeValue = code.split('_')[1];
                    this.changeWidth(resizeValue);
                } else if (code && code.includes('movingType')) {
                    const movingType = code.split('_')[1];
                    this.changeMovingType(MovingType[movingType]);
                } else if (code && code.includes('sensitivityType')) {
                    const sensitivityType = code.split('_')[1];
                    this.changeSensitivityType(SensitivityType[sensitivityType]);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.maxScale = this.type === 'organisation' ? 200 : 100;
    }

    changeMovingType(movingType: MovingType): void {
        localStorage.setItem('movingType', movingType);
        this.movingType.set(movingType);
    }

    changeSensitivityType(sensitivityType: SensitivityType): void {
        localStorage.setItem('sensitivityType', sensitivityType);
        this.sensitivityType.set(sensitivityType);
    }

    /**
     * Changes the selectedWidth property with the given one
     * @param width
     */
    changeWidth(width: string): void {
        this.selectedWidth = width;
        this.cameraScale.set(this.selectedWidth);
    }

    setCameraScale(newValue: string): void {
        this.cameraScale.set(newValue);
    }

    onRowClicked(item: any | null): void {
        this.config.executeActionSignal.set(
            `${OrganisationContainerComponent.selectTaskCode}${item ? item.id : null}`
        );
    }

    onDoubleClick($event): void {
        this.onDoubleClicked.emit($event);
    }
}

/**
 * The configuration of the OrganisationContainer.
 */
export interface OrganisationContainerConfig {
    executeActionSignal?: WritableSignal<string>;
    service: any;
    isPrinting?: boolean;
}
