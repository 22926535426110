import {
    AfterViewInit,
    Component,
    ElementRef,
    Injector,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    effect,
} from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { BusinessProcessCategory, BusinessProcessTask } from 'app/api';
import { AbstractMesh, Mesh, MeshBuilder, PickingInfo, Scene, Vector3 } from 'babylonjs';
import { BabylonAncestral } from './babylon-ancestral';
import { MeshDetails } from './babylon-mesh-details.model';
import { MeshWithTexts } from './mesh-with-texts';
import { Position } from './position.model';
import { BlenderMesh } from './blender-mesh';
import { FlowchartLoadingSpinnerService } from '../../reusable-components/flowchart/flowchart-container/flowchart-loading-spinner.service';

/**
 * This abstract class, which extends BabylonAncestral, is collecting all the essential
 * functions and workarounds that are needed to create a Flowchart with BabylonJS.
 *
 * The descended class should has a template that contains a canvas like this:
 *
 * <canvas #renderCanvas></canvas>
 */
@Component({
    template: '',
})
export abstract class BabylonFlowchart
    extends BabylonAncestral
    implements OnDestroy, OnChanges, AfterViewInit
{
    /** The BusinessProcessTasks that should be rendered */
    @Input() processes: BusinessProcessTask[];

    /** The type of the flowchart */
    @Input() type: 'flowchart' | 'flowchart2';
    /** The id of the selected BusinessProcessTask */
    @Input() selectedId?: string;

    /** The font loaded from a json file */
    protected fontData: any;

    /** Z position of the Meshes */
    protected boxZ = 0.06;

    // Dimensions for Process
    protected processWidth = 1;
    protected processDepth = 0.01;
    protected processHeight = 0.75;

    // Dimensions for Decision
    protected decisionHeight = 0.72;
    protected decisionWidth = this.decisionHeight * 1.4;
    protected decisionDepth = 0.01;

    // Dimensions for Entries
    protected entryHeight = 0.125;
    protected entryDiameter = 0.3;

    /** The only one checkMark Mesh that is render based on the current selection */
    protected checkMark: Mesh;

    // Hover animation
    protected animationOnZ = -1;
    protected maxRotation = Math.PI / 24;

    // Properties
    protected verticalArrowLengthCorrection = 0.2;
    protected horizontalArrowLengthCorrection = 0.2;

    protected background: Mesh;

    /** All meshes stored in a map */
    protected allMeshes: { [key: string]: MeshDetails } = {};

    protected override topMostPoint = 2;

    constructor(
        el: ElementRef,
        ngZone: NgZone,
        injector: Injector,
        fuseConfigService: FuseConfigService,
        spinnerService: FlowchartLoadingSpinnerService
    ) {
        super(el, ngZone, injector, fuseConfigService, spinnerService);

        effect(() => {
            const isAfterViewInit = this.isAfterViewInit();
            const enableCursorMovement = this.enableCursorMovement();
            if (isAfterViewInit && enableCursorMovement) {
                this.turnCursorMovementOn();
            } else {
                this.turnCursorMovementOff();
            }
        });
    }

    /** Sets up the event listeners for the mouse over and for the wheel */
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.processes?.length && this.processes.length < 50) {
            this.startEngine();
        }
    }

    /**
     * Rerenders the Scene if the list of processes is changed.
     *
     * Moves the checkMark if the selection is changed.
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['processes']?.currentValue) {
            this.processes = changes['processes'].currentValue;

            this.checkAndSetUpScene();
        }
        if (changes['selectedId']?.currentValue && this.scene) {
            this.selectedId = changes['selectedId'].currentValue;

            if (this.type === 'flowchart') {
                this.showSelection();
            }
        }
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    protected checkAndSetUpScene(force?: boolean): void {
        const sameIds = this.checkIdsAreSame();

        const sameData = this.checkDataAreSame();

        const reset = !(this.processes && this.processes.length);

        if (this.isAfterViewInit()) {
            if (sameIds && sameData && !force) {
                this.changeOrderOfMeshes();
            } else if (!sameIds || !sameData) {
                this.allMeshes = {};
                this.startEngine(reset);
            }
        }
    }

    protected checkIdsAreSame(): boolean {
        return this.arraysHaveSameItemsById(
            Object.keys(this.allMeshes)
                .map(key => this.allMeshes[key])
                .filter(meshDetails => meshDetails.id !== 'endpoint'),
            this.processes
        );
    }

    protected checkDataAreSame(): boolean {
        return this.arraysEqual(
            this.processes,
            Object.values(this.allMeshes)
                .filter(meshDetails => meshDetails.id !== 'endpoint')
                .map(meshDetails => meshDetails.bpTask)
        );
    }

    private arraysHaveSameItemsById(array1: any[], array2: any[]): boolean {
        // Check if the lengths are the same
        if (array1.length !== array2.length) {
            return false;
        }

        // Check if every item in array1 has a matching item in array2 by id
        return array1.every(item1 => {
            // Use `some` to check if there's at least one matching item in array2
            return array2.some(item2 => item2.id === item1.id);
        });
    }

    private arraysEqual<T>(array1: T[], array2: T[]): boolean {
        if (array1.length !== array2.length) {
            return false;
        }

        const sortedArray1 = array1.slice().sort((a: any, b: any) => a.no - b.no);
        const sortedArray2 = array2.slice().sort((a: any, b: any) => a.no - b.no);

        return sortedArray1.every(
            (element, index) => JSON.stringify(element) === JSON.stringify(sortedArray2[index])
        );
    }

    private findMeshDetailByIndex(index: number): MeshDetails {
        return Object.values(this.allMeshes).find(meshDetails => meshDetails.index === index);
    }

    protected changeOrderOfMeshes(): void {
        this.processes.forEach((bpTask: BusinessProcessTask, index: number) => {
            const idForNewOrder = bpTask.id;
            const meshDetailsForNewOrder = this.allMeshes[idForNewOrder];

            // Order has changed
            if (index !== meshDetailsForNewOrder.index) {
                const meshDetailsForOriginalOrder = this.findMeshDetailByIndex(index);
                const meshDetailsForTheNext = this.findMeshDetailByIndex(index + 2);

                if (meshDetailsForOriginalOrder) {
                    // Swap indices
                    const tempIndex = meshDetailsForNewOrder.index;
                    meshDetailsForNewOrder.index = meshDetailsForOriginalOrder.index;
                    meshDetailsForOriginalOrder.index = tempIndex;

                    // Calculate position delta
                    const deltaX =
                        meshDetailsForNewOrder.position.x - meshDetailsForOriginalOrder.position.x;
                    let deltaY =
                        meshDetailsForNewOrder.position.y - meshDetailsForOriginalOrder.position.y;
                    const deltaZ =
                        meshDetailsForNewOrder.position.z - meshDetailsForOriginalOrder.position.z;

                    let deltaY2 = deltaY;
                    if (
                        meshDetailsForTheNext &&
                        meshDetailsForTheNext.position.y - meshDetailsForNewOrder.position.y !==
                            deltaY
                    ) {
                        deltaY2 +=
                            meshDetailsForTheNext.position.y -
                            meshDetailsForNewOrder.position.y -
                            deltaY;
                    }

                    // Update positions
                    this.moveMeshInDetails(meshDetailsForNewOrder, deltaX, deltaY, deltaZ);
                    this.moveMeshInDetails(meshDetailsForOriginalOrder, -deltaX, -deltaY2, -deltaZ);
                }
            }
        });
    }

    protected moveMeshInDetails(
        meshDetails: MeshDetails,
        deltaX: number,
        deltaY: number,
        deltaZ: number,
        avoidCentral?: boolean
    ): void {
        meshDetails.position.x -= deltaX;
        meshDetails.position.y -= deltaY;
        meshDetails.position.z -= deltaZ;

        if (!avoidCentral) {
            this.setPositionForMesh(meshDetails.centralMesh, deltaX, deltaY, deltaZ);
            if (meshDetails.meshTexts) {
                this.setPositionForMesh(meshDetails.meshTexts, deltaX, deltaY, deltaZ);
            }
        }
        if (meshDetails.arrowToNext) {
            this.setPositionForMesh(meshDetails.arrowToNext, deltaX, deltaY, deltaZ);
        }

        meshDetails.entries.forEach((entyMesh: MeshWithTexts) => {
            this.setPositionForMesh(entyMesh.mesh, deltaX, deltaY, deltaZ);
            this.setPositionForMesh(entyMesh.texts, deltaX, deltaY, deltaZ);
        });
        meshDetails.arrowsForEntries.forEach((arrowMesh: Mesh) => {
            this.setPositionForMesh(arrowMesh, deltaX, deltaY, deltaZ);
        });
    }

    private setPositionForMesh(
        mesh: Mesh | AbstractMesh | undefined,
        deltaX: number,
        deltaY: number,
        deltaZ: number
    ): void {
        if (!mesh) {
            return;
        }
        mesh.position.x -= deltaX;
        mesh.position.y -= deltaY;
        mesh.position.z -= deltaZ;
    }

    override handleSpecificSceneActions(): void {
        this.observePointer();
        this.setUpAnimation();
        if (this.type === 'flowchart') {
            if (this.selectedId) {
                this.showSelection();
            }
        }
    }

    protected override handleSpecialEventOnCameraMovement(intersection: PickingInfo): void {}

    /**
     * Calculates the total height of the scene
     * @returns height in number
     */
    protected override calculateTotalHeight(): number {
        /**
         * Starting point + distance
         * Processes / Decisions - with entries + distances
         * Ending point + distance
         */
        if (this.processes && this.processes.length) {
            let height = 0;
            // Starting point
            height += this.entryHeight + 1.35;
            this.processes.forEach((bpTask: BusinessProcessTask, index: number) => {
                let entriesLength;
                if (bpTask.data.input?.length || bpTask.data.output?.length) {
                    if (bpTask.data.input?.length && bpTask.data.output?.length) {
                        entriesLength =
                            bpTask.data.input.length > bpTask.data.output.length
                                ? bpTask.data.input.length
                                : bpTask.data.output.length;
                    } else if (bpTask.data.input?.length) {
                        entriesLength = bpTask.data.input.length;
                    } else {
                        entriesLength = bpTask.data.output.length;
                    }
                } else {
                    entriesLength = 0;
                }

                // Last piece
                if (index === this.processes.length - 1) {
                    if (bpTask.category === BusinessProcessCategory.Process) {
                        height += 1.4 + (entriesLength > 1 ? 1 * (entriesLength - 1) : 0);
                    } else {
                        height += 0.6 + (entriesLength > 1 ? 1 * (entriesLength - 1) : 0);
                    }
                }
                // Everything else
                else {
                    if (bpTask.category === BusinessProcessCategory.Process) {
                        height +=
                            (entriesLength > 1 ? 1.2 * (entriesLength - 1) : this.processHeight) +
                            1.25;
                    } else {
                        height += 0 + (entriesLength > 1 ? 1.5 * entriesLength - 1 : 1.5);
                    }
                }
            });

            // Ending point
            height += this.entryHeight + 0.75;

            return height;
        }
        return 10;
    }

    /**
     * Calculates the total wodth of the scene.
     * **Must be overriden!**
     *
     * @returns width in number
     */
    protected calculateTotalWidth(): number {
        return 8;
    }

    /**
     * Returns the size of the scene (width and height and which is bigger).
     *
     * @returns totalHeight, totalWidth, biggerSide
     */
    protected override getSizeOfScene(): { height: number; width: number; biggerSide: number } {
        const height = this.calculateTotalHeight();
        const width = this.calculateTotalWidth();

        const biggerSide = height > width ? height : width;

        return { height, width, biggerSide };
    }

    /**
     * Calculate the distance from the camera to make all elements visible
     *
     * @returns yPos, zPos
     */
    protected override calculateCameraPositionForZero(): { yPos: number; zPos: number } {
        const dimensions = this.getSizeOfScene();
        if (this.zeroCameraPoint_offsetZ !== 0) {
            dimensions.height += 3;
        }
        let zPos;
        if (dimensions.width > 24) {
            zPos = -(dimensions.width / (3 * Math.tan(this.cameraFOV * this.half)));
        } else if (dimensions.height < 10) {
            zPos = this.cameraZ;
        } else {
            zPos =
                -(dimensions.height / (2 * Math.tan(this.cameraFOV * this.half))) +
                this.zeroCameraPoint_offsetZ;
        }

        let yPos = -(dimensions.height / 2) + this.zeroCameraPoint_offsetY;

        return { yPos, zPos };
    }

    protected selectionBox: Mesh;

    /** Finds the selected mesh by the selectedId and (re)creates the check mark */
    protected showSelection(): void {
        const selectedMesh = this.scene.getMeshByName(this.selectedId);
        const meshDetails = this.allMeshes[this.selectedId];

        const height = meshDetails.totalHeight;
        const width = 8;

        if (selectedMesh) {
            if (!this.selectionBox) {
                this.selectionBox = MeshBuilder.CreateBox(
                    'selectionBox',
                    { width, height: 1, depth: 0.1 },
                    this.scene
                );

                // const material = new StandardMaterial('material', this.scene);

                // Load your emissive texture
                // const emissiveTexture = new Texture('assets/pngs/texture.png', this.scene);

                // material.emissiveTexture = emissiveTexture;

                // Adjust emissive color and intensity as needed
                // material.emissiveColor = Color3.FromHexString('#B9D3FF'); // gray color

                // this.selectionMaterial = material;
                this.selectionBox.material = this.selectionMaterial;
            }

            // Calculate the scaling factor
            const scalingFactor = height / 1;

            this.selectionBox.scaling.y = scalingFactor;
            const newPos = meshDetails.centerPosition;
            this.selectionBox.position = new Vector3(newPos.x, newPos.y, newPos.z + 0.2);

            // this.createCheckMark(
            //     { x: selectedMesh.position.x + 4.1, y: selectedMesh.position.y, z: 0 },
            //     this.scene
            // );
        }
    }

    // Essential functions
    protected startPoint: BlenderMesh;
    protected endPoint: BlenderMesh;
    /**
     * Creates the entries of the flowchart (starting and ending point).
     *
     * **Not clickable Mesh**
     *
     * @param pos Position of the entry
     * @param text The text inside
     * @param scene SCENE
     * @returns Mesh
     */
    protected createEndpoint(pos: Position, text: string, scene: Scene): Mesh | null {
        let baseMesh: Mesh;

        // Start
        if (text === 'S') {
            baseMesh = this.startPoint.mesh.clone(text);
        }
        // End
        else if (text === 'E') {
            baseMesh = this.endPoint.mesh.clone(text);
        }
        baseMesh.position = new Vector3(pos.x, pos.y, this.boxZ);
        baseMesh.visibility = 1;

        return baseMesh;
    }

    /**
     *
     * @param scene SCENE
     * @param a The Mesh that where the arrow starts
     * @param b The Mesh that the arrow points
     * @param yOffsetOfA Offset number of the *a* Mesh on the Y axis
     * @param yOffsetOfB Offset number of the *b* Mesh on the Y axis
     */
    protected createArrow(
        scene: Scene,
        a: Mesh,
        b: Mesh,
        yOffsetOfA: number,
        yOffsetOfB: number
    ): Mesh {
        // Create Vector3s with the offset
        const modifiedA = new Vector3(a.position.x, a.position.y - yOffsetOfA, a.position.z);
        const modifiedB = new Vector3(b.position.x, b.position.y + yOffsetOfB, b.position.z);

        // Calculate the direction and length of the arrow
        const direction = modifiedB.subtract(modifiedA);
        const length = direction.length();

        // Sphere
        const sphere = this.createSphere(scene);

        // Shaft
        const shaftLength = length - this.verticalArrowLengthCorrection;
        const shaft = this.createShaft(shaftLength, scene);

        // Tip
        const tip = this.createTip(scene);

        // Set the positions
        sphere.position = modifiedA; //.add(direction.scale(0.5 * sphereRatio));
        shaft.position = sphere.position.add(
            direction.normalize().scale(shaftLength * this.half + this.sphereRadius * this.half)
        );
        tip.position = shaft.position.add(
            direction.normalize().scale(shaftLength * this.half + this.tipLength * this.half)
        );

        // Set the materials
        sphere.material = this.arrowMaterial;
        shaft.material = this.arrowMaterial;
        tip.material = this.arrowMaterial;

        const mergedArrow = this.createMergedMesh(
            [sphere, shaft, tip],
            { x: 0, y: 0, z: 0 },
            'connectionArrow'
        );
        return mergedArrow;
    }

    // Functions that should be overriden

    /**
     * Creates a check mark.
     *
     * @param pos Position of the CheckMark
     * @param scene SCENE
     */
    protected createCheckMark(pos: Position, scene: Scene): void {
        throw new Error('createCheckMark is not implemented');
    }

    /**
     * Set up animations in the scene
     */
    protected setUpAnimation(): void {
        throw new Error('setUpAnimation is not implemented');
    }
}
