import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TranslateService {
    constructor() {}

    /**
     * The keys have to surrounded by {{}}
     * Ex: {{key_name}}
     *
     * @param data The data object in which the keys have to be replaced with actual content
     * @param content Object that contains key values pairs of actual content (ex: from CMS)
     * @returns The replaced keys with actual content
     */
    replaceKeyWithContent(data: any, content: any) {
        if (!data || !content) return;
        else if (Array.isArray(data)) {
            data.forEach(dataEntry => {
                dataEntry = this.replaceKeyWithContent(dataEntry, content);
            });
        } else if (typeof data === 'object') {
            Object.keys(data).forEach(key => {
                data[key] = this.replaceKeyWithContent(data[key], content);
            });
        } else if (typeof data === 'string') {
            let matches = /^[{]{2}.*[}]{2}$/.exec(data);
            if (matches) {
                matches.forEach(match => {
                    let replaceString = content[match.substring(2, match.length - 2)];
                    data = (data as string).replaceAll(match, replaceString);
                });
            }
        }
        return data;
    }
}
