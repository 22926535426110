import {
    DestroyRef,
    Inject,
    Injectable,
    Signal,
    WritableSignal,
    computed,
    signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Translation, TranslocoService } from '@ngneat/transloco';
import {
    AuthenticationService,
    BpmUniversalDataObject,
    MasterDataService,
    Mdo,
    MdoAppDepartmentsInner,
    MdoAppUsersInner,
    MdoServServersInner,
} from 'app/api';
import { AlertService } from 'app/core/alert/alert.service';
import { TranslateService } from 'app/core/transloco/translate.service';
import { languages } from 'app/core/transloco/transloco.data';
import { cloneDeep } from 'lodash';
import { of } from 'rxjs';
import { statuses, objectExplorerItemStatuses } from './mdo.data';
import { ApiIdMdPutRequest } from 'app/api/model/apiIdMdPutRequest';
import { FrontendAppConfiguration } from 'app/services/configuration.services';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';

@Injectable({
    providedIn: 'root',
})
export class MDOService {
    private _mdo: WritableSignal<Mdo> = signal(null);
    private _departments: WritableSignal<Array<MdoAppDepartmentsInner>> = signal([]);
    private _users: WritableSignal<Array<MdoAppUsersInner>> = signal([]);
    private _accounts: WritableSignal<Array<BpmUniversalDataObject>> = signal([]);
    private _areas: WritableSignal<Array<BpmUniversalDataObject>> = signal([]);
    private _systems: WritableSignal<Array<BpmUniversalDataObject>> = signal([]);
    private _servers: WritableSignal<Array<MdoServServersInner>> = signal([]);

    private _owners: WritableSignal<Array<MdoAppUsersInner>> = signal([]);
    private _authGroups: WritableSignal<Array<MdoAppDepartmentsInner>> = signal([]);
    private _languages: Signal<Array<any>>;

    private _translocoContentLanguages: Signal<Translation>;
    private _translocoContentMdo: Signal<Translation>;

    /**
     * Constructor
     */
    constructor(
        private _authenticationService: AuthenticationService,
        private _translocoService: TranslocoService,
        private _masterDataService: MasterDataService,
        private _alertService: AlertService,
        private _destroyRef: DestroyRef,
        private _translateService: TranslateService,
        @Inject(APP_CONFIGURATION_TOKEN) private appConfig: FrontendAppConfiguration
    ) {
        this.getMasterData();

        this._translocoContentLanguages = toSignal(
            this._translocoService.selectTranslateObject('languages')
        );
        this._translocoContentMdo = toSignal(
            this._translocoService.selectTranslation('master-data')
        );

        this._languages = computed(() => {
            return languages.map(language => ({
                ...language,
                label: this._translocoContentLanguages()[language.label],
            }));
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getters
     */
    get mdo() {
        return this._mdo();
    }

    get departments() {
        return this._departments();
    }

    get users() {
        return this._users();
    }

    get accounts() {
        return this._accounts();
    }

    get areas() {
        return this._areas();
    }

    get flattenedAreas() {
        const result: any[] = [];

        function flatten(node: BpmUniversalDataObject): void {
            result.push(node);
            if (node.children && node.children.length > 0) {
                node.children.forEach(flatten);
            }
        }

        this.areas.forEach(flatten);

        return result;
    }

    get areasSignal() {
        return this._areas;
    }

    get systems() {
        return this._systems();
    }

    get servers() {
        return this._servers();
    }

    get owners() {
        return this._owners();
    }

    get authGroups() {
        return this._authGroups();
    }

    get languages() {
        return this._languages();
    }

    get statuses() {
        return this._translateService.replaceKeyWithContent(
            cloneDeep(statuses),
            this._translocoContentMdo()
        );
    }

    get objectExplorerStatuses() {
        return this._translateService.replaceKeyWithContent(
            cloneDeep(objectExplorerItemStatuses),
            this._translocoContentMdo()
        );
    }

    /**
     * Setters
     */
    set departments(data: Array<MdoAppDepartmentsInner>) {
        this._departments.set(data);
    }

    set users(data: Array<MdoAppUsersInner>) {
        this._users.set(data);
    }

    set accounts(data: Array<BpmUniversalDataObject>) {
        this._accounts.set(data);
    }

    set areas(data: Array<BpmUniversalDataObject>) {
        this._areas.set([...data]);
    }

    set systems(data: Array<BpmUniversalDataObject>) {
        this._systems.set(data);
    }

    set servers(data: Array<MdoServServersInner>) {
        this._servers.set(data);
    }

    getAll() {
        return of(this._mdo());
    }

    getMasterData() {
        return this._authenticationService
            .backendApiIdMdoGet(this.appConfig.basicClientIdToBeReplaced)
            .subscribe(data => {
                this._mdo.set(data);
                this._departments.set(data.app_departments);
                this._users.set(data.app_users);
                this._accounts.set(data.bpm_accounts);
                this._areas.set(data.bpm_areas);
                this._systems.set(data.bpm_systems);
                this._servers.set(data.serv_servers);
                this._owners.set(data.app_users);
                this._authGroups.set(data.app_departments);
            });
    }

    saveMasterData(data: ApiIdMdPutRequest) {
        this._masterDataService
            .backendApiIdMdPut(this.appConfig.basicClientIdToBeReplaced, data)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(data => {
                this.handleApiResponse(data);
                //this._alertService.successAlert('changesSuccessMessage', 'master-data');
            });
    }

    private handleApiResponse(responseData: any) {
        if (responseData && responseData.name) {
            const propertyName = `_${responseData.name.toLowerCase()}`;

            if (this.hasOwnProperty(propertyName)) {
                this[propertyName].set(responseData.data);

                this._alertService.successAlert('master-data', 'changesSuccessMessage', 'saved');
            } else {
                console.error(`Property '${propertyName}' not found in the service.`);
            }
        } else {
            console.error("Invalid or missing 'name' property in the API response.");
        }
    }
}
