import { Injectable, WritableSignal, signal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FlowchartLoadingSpinnerService {
    showLoadingSpinner: WritableSignal<boolean> = signal(false);

    constructor() {}
}
