const properties = {
    container: {
        width: 100,
        viewBox: {
            x: 0,
            y: 0,
            width: 405,
            height: 500,
        },
        startViewBox: {
            x: 0,
            y: 0,
            width: 405,
            height: 50,
        },
    },
    area: {
        x: 0,
        y: 0,
        width: 100,
        height: 0,
        content: [
            {
                placeholder: 'Text_01',
                value: 'title',
            },
        ],
    },
    area_process: {
        x: 0,
        y: 0,
        width: 100,
        height: 0,
        content: [
            {
                placeholder: 'Text_01',
                value: 'titel',
            },
        ],
    },
    main: {
        actorIcon: {
            x: 66.5,
            y: -7,
            width: 5,
            height: 5,
            yMultiplier: 100,
        },
        sapIcon: {
            x: 37.5,
            y: -13,
            width: 25,
            height: 10,
            yMultiplier: 100,
            content: [
                {
                    placeholder: 'Text_01',
                    value: 'data.info.tcode',
                    class: 'sap-text',
                },
                {
                    placeholder: 'Text_02',
                    value: 'data.info.name',
                    class: 'sap-text',
                },
            ],
        },
        start: {
            x: 32.25,
            y: 0,
            width: 37.5,
            height: 0,
            content: [],
        },
        process: {
            x: 37.5,
            y: 0,
            width: 25,
            height: 0,
            content: [
                {
                    placeholder: 'Text_01',
                    value: 'data.action.responsible',
                },
                {
                    placeholder: 'Text_02',
                    value: 'data.action.title1',
                },
                {
                    placeholder: 'Text_03',
                    value: 'data.action.title2',
                },
                {
                    placeholder: 'Text_04',
                    value: 'data.action.system',
                },
                {
                    placeholder: 'Text_05',
                    value: 'data.action.no',
                },
            ],
        },
        integrationTask: {
            x: 37.5,
            y: 0,
            width: 25,
            height: 0,
            content: [
                {
                    placeholder: 'Text_01',
                    value: 'data.action.responsible',
                },
                {
                    placeholder: 'Text_02',
                    value: 'data.action.title1',
                },
                {
                    placeholder: 'Text_03',
                    value: 'data.action.title2',
                },
                {
                    placeholder: 'Text_04',
                    value: 'data.action.system',
                },
                {
                    placeholder: 'Text_05',
                    value: 'data.action.no',
                },
            ],
        },
        decission: {
            x: 37.5,
            y: 0,
            width: 27,
            height: 27,
            content: [
                {
                    placeholder: 'Text_01',
                    value: 'data.action.title1',
                },
            ],
        },
    },
    arrow: {
        width: 7.5,
        height: 7.5,
        entryStart: {
            xDelta: 24,
            yDelta: 0,
        },
        entryEnd: {
            xDelta: -7,
            yDelta: 0,
        },
        mainStart: {
            xDelta: 24,
            yDelta: 0,
        },
        mainEnd: {
            xDelta: -7,
            yDelta: 0,
        },
        vertical: {
            width: 0.375,
            height: 20,
            yDelta: 0,
            input: {
                xDelta: 31.15,
            },
            output: {
                xDelta: -7,
            },
        },
    },
    entries: {
        width: 25,
        height: 25,
        yMultiplier: 15.5,
        input: {
            x: 0,
            y: 0,
        },
        output: {
            x: 74.8,
            y: 0,
        },
        content: [
            {
                placeholder: 'Text_01',
                value: 'description',
            },
        ],
    },
    fiDocument: {
        width: 25,
        height: 25,
        yMultiplier: 15.5,
        input: {
            x: 0,
            y: 0,
        },
        output: {
            x: 75,
            y: 0,
        },
        content: [
            {
                placeholder: 'Text_01',
                value: 'metadata.debit',
            },
            {
                placeholder: 'Text_01.i.1',
                value: 'metadata.debits[i].glAccount',
            },
            {
                placeholder: 'Text_01.i.2',
                value: 'metadata.debits[i].glSText',
            },
            {
                placeholder: 'Text_02',
                value: 'metadata.credit',
            },
            {
                placeholder: 'Text_02.i.1',
                value: 'metadata.credits[i].glAccount',
            },
            {
                placeholder: 'Text_02.i.2',
                value: 'metadata.credits[i].glSText',
            },
        ],
    },
};
export default properties;
