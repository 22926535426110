import {
    Component,
    EventEmitter,
    Input,
    InputSignal,
    Output,
    WritableSignal,
    effect,
    input,
} from '@angular/core';
import { ExtendedArea } from './svg-area.model';

@Component({
    selector: 'svg-area',
    templateUrl: './svg-area.component.html',
    styleUrl: './svg-area.component.scss',
})
export class SvgAreaComponent {
    areas: InputSignal<ExtendedArea> = input({});
    type: InputSignal<'area' | 'system'> = input('area');
    @Output() onDoubleClicked: EventEmitter<any> = new EventEmitter();

    /** The scale of the camera */
    cameraScale: InputSignal<string> = input('100%');

    totalChildrenCount: number;
    hasProcess: boolean = false;

    constructor() {
        effect(() => {
            this.totalChildrenCount = this.countTotalChildrenDeep(this.areas());
            if (this.areas().processes?.length) {
                this.hasProcess = true;
            }
        });
    }

    countTotalChildrenDeep(area: any): number {
        let total = 0;
        if (area.children?.length) {
            area.children.forEach(child => {
                total += this.countTotalChildrenDeep(child);
            });
            return total;
        }
        return total + 1;
    }

    getClass(area: any): string {
        if (area.id === 'placeholder') {
            return `basis-${1}/${this.totalChildrenCount}`;
        } else if (area.children?.length || this.totalChildrenCount > 1) {
            const childrenCountOfChild = this.countTotalChildrenDeep(area);

            return `basis-${childrenCountOfChild}/${this.totalChildrenCount}`;
        }
        return 'flex-1';
    }

    onDoubleClick($event: any): void {
        this.onDoubleClicked.emit($event);
    }
}
