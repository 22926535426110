<div *transloco="let t; read: 'flowchart'">
    <spinner></spinner>
    @if (organisation() && type === 'organisation') {
        <bp-organisation
            [organisation]="organisation"
            (meshClicked)="onRowClicked($event)"
            (meshDoubleClicked)="onDoubleClick($event)"
            [showId]="showId"
            [cameraScale]="cameraScale"
            [sensitivityType]="sensitivityType"
            [config]="chartConfig"
            [movingType]="movingType"></bp-organisation>
    } @else if (organisation() && type !== 'organisation') {
        <div class="p-2">
            <svg-area
                (onDoubleClicked)="onDoubleClick($event)"
                [type]="type"
                [cameraScale]="cameraScale()"
                [areas]="organisation()"></svg-area>
        </div>
    }
    @if (!organisation()) {
        <fuse-alert
            [appearance]="'soft'"
            [type]="'basic'"
            [showIcon]="false"
            class="text-center block my-auto p-2 w-full">
            {{ t('noData') }}
        </fuse-alert>
    }
</div>
<div class="resizer">
    <lib-flowchart-resizer
        (updateCameraScale)="setCameraScale($event)"
        [maxScale]="maxScale"></lib-flowchart-resizer>
</div>
