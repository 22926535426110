<div class="h-full w-full max-w-[100vw]" *transloco="let t; read: 'contentLayoutWrapper'">
    <mat-drawer-container>
        <mat-drawer #ultimateSideDrawer [mode]="'over'" [opened]="false">
            <!-- Header-->
            <div class="panel-header px-4">
                <ng-content select="[ultimateSideDrawerHeader]"></ng-content>
            </div>

            <!-- Content-->
            <div class="panel-content p-0">
                <ng-content select="[ultimateSideDrawerContent]"></ng-content>
            </div>
        </mat-drawer>

        <mat-drawer-content>
            <!-- PARENT DRAWER-->
            <mat-drawer-container>
                <!-- PARENT Drawer Side Panel -->
                <mat-drawer
                    #parentMatDrawer
                    mwlResizable
                    class="mat-drawer-resizable dark:bg-gray-900"
                    [mode]="parentDrawerMode()"
                    [opened]="parentDrawerOpened()"
                    [ngStyle]="parentStyle"
                    [validateResize]="validate('parent')"
                    [enableGhostResize]="true"
                    [resizeSnapGrid]="{ left: 1, right: 1 }"
                    (resizeStart)="onResizeStart($event)"
                    (resizeEnd)="onResizeEnd($event, 'parent')">
                    <!-- Header-->
                    <div
                        id="parentMatDrawerSidePanelHeader"
                        class="panel-header px-4"
                        [ngClass]="{
                            'flex items-center justify-between gap-4':
                                config()?.ultimateSideBar?.header?.showToggleButton,
                        }">
                        @if (config()?.ultimateSideBar?.header?.showToggleButton) {
                            <div class="flex gap-2">
                                <button
                                    [matTooltip]="t('toggle')"
                                    mat-icon-button
                                    (click)="ultimateSideDrawer.toggle()">
                                    <mat-icon svgIcon="mat_outline:menu_open"></mat-icon>
                                </button>
                            </div>
                        }
                        <ng-content select="[leftContentDrawerHeader]"></ng-content>
                    </div>

                    <!-- Content-->
                    <div class="panel-content p-0">
                        <ng-content select="[leftContentDrawer]"></ng-content>
                    </div>

                    <!-- Pin handle -->
                    @if (parentDrawerMode() === 'over') {
                        <div class="pin-handle">
                            <button
                                [matTooltip]="t('pin')"
                                mat-icon-button
                                (click)="toggleParentDrawerMode()">
                                <mat-icon svgIcon="mat_outline:push_pin"></mat-icon>
                            </button>
                        </div>
                    }

                    <!-- Resize handle -->
                    <div
                        class="resize-handle-right"
                        mwlResizeHandle
                        [resizeEdges]="{ right: true }"></div>
                </mat-drawer>

                <!-- PARENT Drawer Main Content -->
                <mat-drawer-content #parentMatDrawerContent>
                    <!-- CHILD DRAWER -->
                    <mat-drawer-container>
                        <!-- CHILD Drawer Side Panel -->
                        <mat-drawer
                            #childMatDrawer
                            mwlResizable
                            class="mat-drawer-resizable dark:bg-gray-900"
                            [ngClass]="{ 'total-width': isChildDrawerFullscreen() }"
                            [mode]="childDrawerMode()"
                            [opened]="childDrawerOpened()"
                            [ngStyle]="childStyle"
                            [validateResize]="validate('child')"
                            [enableGhostResize]="true"
                            [resizeSnapGrid]="{ left: 1, right: 1 }"
                            (resizeStart)="onResizeStart($event)"
                            (resizeEnd)="onResizeEnd($event, 'child')">
                            <!-- Header -->
                            <ng-container [ngTemplateOutlet]="leftContentHeader"></ng-container>
                            <!-- Content-->
                            <div
                                class="panel-content p-0"
                                [ngClass]="{
                                    'container-y--xs': config().leftSection.content.spacing.y,
                                    'container-x--xs': config().leftSection.content.spacing.x,
                                }">
                                <ng-content select="[leftContent]"></ng-content>
                            </div>

                            <!-- Resize handle -->
                            <div
                                class="resize-handle-right"
                                mwlResizeHandle
                                [resizeEdges]="{ right: true }"></div>
                        </mat-drawer>

                        <!-- CHILD Drawer Main Content -->
                        <mat-drawer-content
                            #childMatDrawerContent
                            fuseScrollReset
                            [ngClass]="{ 'ml-auto': isChildDrawerFullscreen() }">
                            <!-- Header -->
                            @if (config().rightSection.useLeftContentHeader) {
                                <ng-container [ngTemplateOutlet]="leftContentHeader"></ng-container>
                            } @else {
                                <ng-container [ngTemplateOutlet]="defaultHeader"></ng-container>
                            }

                            <!-- Content -->
                            <div class="p-0">
                                <ng-content select="[rightContent]"></ng-content>
                            </div>
                        </mat-drawer-content>
                    </mat-drawer-container>
                </mat-drawer-content>
            </mat-drawer-container>
        </mat-drawer-content>
    </mat-drawer-container>

    <!-- Template for left content header -->
    <ng-template #leftContentHeader>
        <div
            class="panel-header px-4"
            [ngClass]="{
                'flex items-center justify-between gap-4':
                    config()?.leftSection?.header?.showToggleButton,
            }">
            @if (config()?.leftSection?.header?.showToggleButton) {
                <div class="flex gap-2">
                    <button
                        [matTooltip]="t('toggle')"
                        mat-icon-button
                        (click)="parentMatDrawer.toggle()">
                        <mat-icon svgIcon="mat_solid:menu_open"></mat-icon>
                    </button>
                    @if (parentDrawerMode() === 'side' && parentMatDrawer.opened) {
                        <button
                            [matTooltip]="t('unpin')"
                            mat-icon-button
                            (click)="toggleParentDrawerMode()">
                            <mat-icon svgIcon="mat_solid:push_pin"></mat-icon>
                        </button>
                    }
                </div>
            }
            <ng-content select="[leftContentHeader]"></ng-content>
        </div>
    </ng-template>

    <!-- Template for default header (for main content i.e., right section) -->
    <ng-template #defaultHeader>
        @if (config().rightSection.header; as header) {
            <content-layout-header class="header-container-sticky" [config]="header">
                <div headerExtraContent>
                    <ng-content select="[headerExtraContent]"></ng-content>
                </div>
            </content-layout-header>
        }
    </ng-template>
</div>
