<div *ngIf="businessProcess && process" class="print-section">
    <div class="printContainer">
        <div class="headerRow border-b padding-b">
            <div class="emLogo"> EM </div>
            <h1 class="bpTitle"> Business Process </h1>
        </div>
        <div class="headerRow">
            <h1 class="h1">
                <b> {{ process.wbs }} </b>{{ ' - ' + process.titel }}
            </h1>
            <h3 class="h3 my-auto">
                <b> Version: {{ process.version }} </b></h3
            >
        </div>
        <!-- <h1 class="h1"> WBS: {{ process.wbs }} </h1> -->

        <h2 class="h2"> <b>Details</b> </h2>
        <table>
            <tr>
                <td class="headerCol">Version:</td>
                <td> {{ process.version }} </td>
            </tr>
            <tr>
                <td class="headerCol">Description:</td>
                <td> {{ process.description }} </td>
            </tr>
            <tr>
                <td class="headerCol">Owner:</td>
                <td> {{ process.owner }} </td>
            </tr>
            <tr>
                <td class="headerCol"> Area: </td>
                <td> {{ process.area }} </td>
            </tr>
            <tr>
                <td class="headerCol"> Status: </td>
                <td> {{ process.status ?? 'N/A' }} </td>
            </tr>
        </table>

        <h2 class="h2"> <b>Instructions</b> </h2>
        <div class="commonDescription" [innerHTML]="process.text1"></div>
        <h2 class="h2"> <b>Description</b> </h2>
        <div class="commonDescription" [innerHTML]="process.text2"></div>

        <h2 class="h2 border-t padding-t"> <b>Flowchart</b> </h2>
        <img *ngIf="image" class="image border-b padding-b" [src]="image.src" />

        <div *ngIf="images?.length" class="flex flex-col gap-0 image border-b padding-b">
            <img *ngFor="let img of images" class="" [src]="img.src" />
        </div>

        <h2 class="h2"> <b>Tasks</b> </h2>
        <div class="tasks">
            <div class="border-b padding-b" *ngFor="let task of businessProcess.tasks">
                <div class="task">
                    <h4 class="h4">
                        <b>{{ task.data.action.no }}</b> - {{ task.data.action.title1 }}
                    </h4>
                    <div class="commonDescription">
                        <p
                            [innerHTML]="
                                taskDescription === 'instructions'
                                    ? task.data.text1
                                    : task.data.text2
                            "></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
