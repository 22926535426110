import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * A service for BabylonFlowchart to make the printing easy to use.
 */
@Injectable({
    providedIn: 'root',
})
export class PrintService {
    printScene: Subject<void> = new Subject();
    printDetailedScene: Subject<void> = new Subject();
    reSubscribe: Subject<void> = new Subject();

    image: any;
    images: any[];

    constructor() {}

    async print(): Promise<any> {
        this.printScene.next();
        await this.printScene.toPromise();

        this.printDetailedScene.complete();

        this.printScene = new Subject();
        this.printDetailedScene = new Subject();

        this.reSubscribe.next();

        return this.image;
    }

    async printSegments(): Promise<any[]> {
        if (!this.printDetailedScene.observed) {
            return await this.print();
        }
        this.images = [];

        this.printDetailedScene.next();
        await this.printDetailedScene.toPromise();

        this.printScene.complete();

        this.printScene = new Subject();
        this.printDetailedScene = new Subject();

        this.reSubscribe.next();

        return this.images;
    }
}
