import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, InputSignal, ViewChild, effect, input } from '@angular/core';
import { SVG, Svg } from '@svgdotjs/svg.js';

@Component({
    selector: 'svg-observer',
    templateUrl: './svg-observer.component.html',
    styleUrl: './svg-observer.component.css',
})
export class SvgObserverComponent {
    @ViewChild('svgContainer') svgContainer!: ElementRef;

    fileName: InputSignal<string> = input();

    private svg: Svg;

    constructor(private http: HttpClient) {
        effect(() => {
            const name = this.fileName();
            if (name) {
                this.fetchAndLoadSVG(name);
            }
        });
    }

    fetchAndLoadSVG(svgName: string) {
        this.http.get(`/assets/svgs/${svgName}.svg`, { responseType: 'text' }).subscribe(result => {
            this.modifySVG(result);
        });
    }

    modifySVG(svgContent: string) {
        if (this.svg) {
            this.svg.remove();
        }
        this.svg = SVG().addTo(this.svgContainer.nativeElement).size('100%', '100%');
        // Load SVG file
        this.svg.svg(svgContent);

        this.setPositionAndSizeToSvg(0, 0, 100, 100);
    }

    private setPositionAndSizeToSvg(x: number, y: number, width: number, height: number): void {
        const length = this.svg.children().length;
        this.svg.children()[length - 1].size(`${width}%`, `${height}%`);
        this.svg.children()[length - 1].move(`${x}%`, `${y}%`);
    }
}
