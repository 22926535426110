export const statuses = [
    { value: 'A', label: '{{active}}' },
    { value: 'I', label: '{{notActive}}' },
    { value: 'D', label: '{{development}}' },
];

export const objectExplorerItemStatuses = [
    { value: 'Active', label: '{{active}}' },
    { value: 'Development', label: '{{development}}' },
];
